import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import {fetchCustomers, updateCustomer} from "../actions/accounts";
import {Cancel, Delete, Done, Edit} from '@material-ui/icons';
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { validateEmail} from "../actions/common";

import EnhancedTableHead from './util/EnhancedTableHead';

import { PER_PAGE, PER_PAGE_ARR } from "../constants/data";
import { tableHeadSort } from '../services/sort';

const desc = tableHeadSort('');


function createUsersData(arr, isActive) {
  let data = [];
  let newArr = [];
  if(arr.length){
    if(isActive) {
      newArr = arr.filter(ch => ch.is_active === true);
    }else {
      newArr = arr.filter(ch => ch.is_active === false);
    }
    newArr.forEach(function(item, i, arr) {
      data.push({id: item.id, status: item.is_active, username: item.username, email: item.email, type: 'Admin'})
    });
  }
  return data;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'is_active', numeric: false, disablePadding: false, label: 'Статус', sort: true },
  { id: 'username', numeric: false, disablePadding: false, label: 'Імʼя', sort: true },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email', sort: false },
  { id: 'type', numeric: false, disablePadding: false, label: 'Тип акаунта', sort: false },
];

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class AccountsTableHead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'is_active',
      data: [],
      page: 0,
      rowsPerPage: PER_PAGE,
      openUpdate: false,
      openDelete: false,
      curId: '',
      curObj: {},
      username: '',
      email: '',
      full_name: '',
      fields: {
        username: true, email: true, full_name: true
      },
    };
    this.onChange = this.onChange.bind(this);
    this.onUpdateSubmit = this.onUpdateSubmit.bind(this);
    this.onDiactivate = this.onDiactivate.bind(this);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
  }

  componentDidMount() {
    this.props.getCustomers();
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  deleteCustomer = (id, accounts) => {
    let curObj = {};
    if(accounts.length){
      curObj = accounts.filter(item => item.id === id);
    }
    this.setState({ openDelete: true, curObj: curObj[0], curId: id});
  };

  editCustomer = (id, accounts) => {
    let curObj = {};
    if(accounts.length){
      curObj = accounts.filter(item => item.id === id);
      this.props.toEdit(curObj);
    }
    this.setState({curObj: curObj[0], curId: id});
  };

  onChange(e) {
    if (e.target.value.trim() !== '') {
      if (e.target.name === 'email') {
        if (validateEmail(e.target.value)) {
          this.setState({
            curObj: { ...this.state.curObj, [e.target.name]: e.target.value },
            errors: { ...this.state.errors, [e.target.name]: false },
          });
        } else {
          this.setState({ curObj: { ...this.state.curObj, [e.target.name]: this.state.curObj.email} });
        }
      } else {
        this.setState({
          curObj: { ...this.state.curObj, [e.target.name]: e.target.value },
          errors: { ...this.state.errors, [e.target.name]: false },
        });
      }
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  onChangeCheckbox() {
    this.setState(prevState => ({
      curObj: {
        ...prevState.curObj,
        is_active: true
      }
    }));
  }

  onCancel =() => {
    this.setState({ openDelete: false, openUpdate: false, curId: '' });
  };

  handleClose = () => {
    this.setState({ openDelete: false, openUpdate: false, curId: '' });
  };

  onUpdateSubmit(ev) {
    ev.preventDefault();
    this.props.updateCustomerData(this.state.curObj, this.state.curId);
    this.setState({ openUpdate: false, curId: '' });
  }

  onDiactivate(e) {
    e.preventDefault();
    const userData = {
      username: this.state.curObj.username,
      email: this.state.curObj.email,
      full_name: this.state.curObj.full_name,
      is_active: false
    };
    this.props.updateCustomerData(userData, this.state.curId);
    this.setState({ openDelete: false, curId: '' });
  }

  render() {
    const { classes, accounts, isActive } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;

    return (
      <Paper className={classes.root}>

      {/**Delete modal**/}
        <div>
          <Dialog
            open={this.state.openDelete}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogContent>
              <DialogContentText>
                Ви впевнені, що хочете видалити цей запис?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.onDiactivate} color="primary">
                Видалити
              </Button>
              <Button onClick={this.onCancel} color="primary">
                Відмінити
              </Button>
            </DialogActions>
          </Dialog>
        </div>


        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              rows={rows}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
            />
            <TableBody>
              {stableSort(accounts, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .filter(n => isActive ? n.is_active : n)
                .map(n => {
                  return (
                    <TableRow key={n.id}>
                      <TableCell component="th" scope="row">
                        {n.is_active ? (<Done/>) : (<Cancel/>)}
                      </TableCell>
                      <TableCell align="left">{n.username}</TableCell>
                      <TableCell align="left">{n.email}</TableCell>
                      <TableCell align="left">{n.type}</TableCell>
                      <TableCell align="left">
                        {n.is_active ? (
                          <Button onClick={() => this.deleteCustomer(n.id, accounts)} color="primary">
                            <Delete style={{ color: '#afafaf' }}/>
                          </Button>
                        ) : (
                          <Button disabled>
                            <Delete style={{ color: '#f0f0f0' }}/>
                          </Button>
                        )}
                        <Button onClick={() => this.editCustomer(n.id, accounts)} color="primary">
                          <Edit style={{ color: '#afafaf' }}/>
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={PER_PAGE_ARR}
          component="div"
          count={accounts.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          labelRowsPerPage='Рядків на сторінку:'
          labelDisplayedRows= {({ from, to, count }) => `${from}-${to} з ${count}`}
        />
      </Paper>
    );
  }
}

AccountsTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  accounts: state.accounts
});

const mapDispatchToProps = dispatch => ({
  getCustomers: () => {
    dispatch(fetchCustomers());
  },
  updateCustomerData: (data, id) => {
    dispatch(updateCustomer(data, id))
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccountsTableHead));