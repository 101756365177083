import {
  BOOK_REVIEW_STATUS_NEW,
  BOOK_REVIEW_STATUS_IN_PROCESS,
  BOOK_REVIEW_STATUS_PUBLISHED,
  BOOK_REVIEW_STATUS_CANCELED,
  BOOK_ORDER_STATUS_NEW,
  BOOK_ORDER_STATUS_IN_PROCESS,
  BOOK_ORDER_STATUS_DONE,
  BOOK_ORDER_STATUS_CANCELED,
} from '../constants/data';

export const colors = [{color: '#f44336'}, {color: '#009688'}, {color: '#9c27b0'}, {color: '#2196f3'}];

export const nodesRanges = [
  {
    value: "employee",
    label: "Робітник",
  },
  {
    value: "department",
    label: "Департамент",
  },
];

export const ranges = [
  {
    value: 1,
    label: 'художня',
  },
  {
    value: 2,
    label: 'наукова',
  },
];

export const bookReviewStatus = [
  {
    value: BOOK_REVIEW_STATUS_NEW,
    label: 'Новий',
  },
  {
    value: BOOK_REVIEW_STATUS_IN_PROCESS,
    label: 'В обробці',
  },
  {
    value: BOOK_REVIEW_STATUS_PUBLISHED,
    label: 'Опублікований',
  },
  {
    value: BOOK_REVIEW_STATUS_CANCELED,
    label: 'Скасований',
  },
];

export const bookOrderStatus = [
  {
    value: BOOK_ORDER_STATUS_NEW,
    label: 'Новий',
  },
  {
    value: BOOK_ORDER_STATUS_IN_PROCESS,
    label: 'В обробці',
  },
  {
    value: BOOK_ORDER_STATUS_DONE,
    label: 'Виконаний',
  },
  {
    value: BOOK_ORDER_STATUS_CANCELED,
    label: 'Скасований',
  },
];

export const contactTypes = [
  {
    value: 'email',
    label: 'Email'
  },
  {
    value: 'fax',
    label: 'Fax'
  },
  {
    value: 'phone',
    label: 'Телефон'
  }
];