import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const Title = ({ text }) => (
  <div>
    <div style={{
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#fff',
      border: '4px solid #df6d17',
      height: '17px',
      width: '17px',
      borderRadius: '50%',
    }}>
    </div>
    <h1 style={{color: '#fff', marginLeft: '10px'}}>{text}</h1>
  </div>
);

class ContactMap extends Component {
  static defaultProps = {
    center: {
      lat: 50.4,
      lng: 30.5,
    },
    zoom: 11,
    getData: ({x, y, lat, lng, event}) => console.log(x, y, lat, lng, event),
  };

  render() {
    const { center: { lat ,lng }, title } = this.props;

    return (
      <div style={{ position: 'absolute', height: '30%', width: '100%', marginTop: '25px' }}>
        <GoogleMapReact
          // bootstrapURLKeys={{ key: 'AIzaSyB6UFhPwZg6Q-48ot25Jygd6CQFAgTNJ5o' }}
          bootstrapURLKeys={{
            key: 'AIzaSyB6UFhPwZg6Q-48ot25Jygd6CQFAgTNJ5o',
            language: 'ua',
            region: 'ua',
          }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          onClick ={this.props.getData}
        >
          {(lat && lng && title) &&
          <Title
            lat={lat}
            lng={lng}
            text={title}
          />
          }
        </GoogleMapReact>
      </div>
    );
  }
}

export default ContactMap;