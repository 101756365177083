import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import '../../assets/styles/categories.scss';

import fetchOne from "../../services/fetch";
import {CORE_URL, DEPARTMENTS_PATH} from "../../constants/urls";
import {EDIT_ITEM} from '../../constants/types';

import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import {Save, Delete} from "@material-ui/icons";
import Button from "@material-ui/core/Button/Button";
import TextField from "@material-ui/core/TextField/TextField";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Switch from "@material-ui/core/Switch/Switch";
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import RadioGroup from "@material-ui/core/es/RadioGroup/RadioGroup";
import Radio from "@material-ui/core/es/Radio/Radio";
import Loader from "../util/Loader";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import store from "../../store";
import {REQUEST_MESSAGE} from "../../constants/redux";
import ModalWindow from '../../views/layout/ModalWindow';


const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
  },
  dense: {
    marginTop: 20,
  },
  menu: {
    width: '100%',
  },
  formControl: {
    margin: theme.spacing.unit,
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit,
  },
  titleWrapper: {
    width: '100%',
    margin: '5px 0',
    padding: '5px 0',
    border: '1px solid',
  },
  menuWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    listStyleType: 'none',
    margin: '15px auto',
    width: '50%',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 10,
  },
});

const FeedbackModel = {
  contacts: [],
  localization: {},
  email: '',
  idLocal: '', // id локализации
};

const locale = ({department_localizations}) => {
  const localLanguage = localStorage.getItem('language');
  const lang_system_contacts = department_localizations.filter(localization => localization.localization.id === localLanguage);
  return !!department_localizations.length ? lang_system_contacts[0] : {};
};

class CreateFeedback extends React.Component {
  state = {
      open: false,

      department: '',
      priority: 1,
      is_active: true,

      languages: [],
      activeTab: 0,
      content: [],

      departments: [],
      openDepartment: false,

      errors: [],

      updated: [],
      submited: false,
      loading: true,
  };

  componentDidMount() {
    this.getLanguages();
    this.getDepartments();
  }

  getDepartments = async () => {
    const {id, type} = this.props;
    const departments = await fetchOne({url: `${DEPARTMENTS_PATH}?isActive=true&email=true`});

    if (type === EDIT_ITEM) {
      const {
        department, priority, is_active,
      } = await fetchOne({url: `${CORE_URL}/feedbacks/${id}`});

      this.setState({
        department: department.id,
        priority,
        is_active,
        departments: departments,
        loading: false
      });
    } else {
      this.setState({
        departments: departments,
        loading: false
      });
    }
  };

  setContacts = (departments, id) => {
    departments.forEach(dep => {
      if (dep.id === id) {

        this.state.content.map(item => {
          let contacts = [];

          const Node = dep.department_localizations
            .find(el => item.localization === el.localization.id);
          if (Node) {
            contacts = Node.contact ? Node.contact.contact_entries.filter(item => item.type === 'email') : [];
          }

          this.setState(state => ({
            content: [
              ...state.content
                .map((i) => i.localization === item.localization ?
                  ({...i, contacts: contacts, email: contacts[0] ? contacts[0].id : ''})
                  : i
                ),
            ],
          }));
        });

      }
    });

  };

  getLanguages = async () => {
    const {id, type} = this.props;
    const languages = await fetchOne({url: `${CORE_URL}/languages`});

    if (type === EDIT_ITEM) {
      const {
        feedback_localizations,
        department
      } = await fetchOne({url: `${CORE_URL}/feedbacks/${id}`});

      let content = new Array(languages.length)
        .fill(FeedbackModel)
        .map((item, i) => ({...item, localization: languages[i].id}));

      if (feedback_localizations.length) {

        content = content.map(item => {
          const Node = feedback_localizations
            .find(el => item.localization === el.localization.id);
          if (Node) {
            const {id, email} = Node;
            const idLocal = Node.id; // добавляем id локализации

            return {
              ...item,
              id,
              email: email.id,
              idLocal, // добавляем id локализации
            };
          }

          return item;
        });
      }

      if (department.department_localizations) {

        content = content.map(item => {
          const Node = department.department_localizations
            .find(el => item.localization === el.localization.id);

          if (Node && Node.contact) {
            const contacts = Node.contact.contact_entries.filter(item => item.type === 'email');
            return {
              ...item,
              contacts,
            };
          }

          return item;
        });

      }

      this.setState({
        updated: feedback_localizations,

        languages,
        content,
      });

    } else {
      const content = new Array(languages.length)
        .fill(FeedbackModel)
        .map((item, i) => ({...item, localization: languages[i].id}));
      this.setState({languages, content});
    }
  };

  validateLocalization = () => {
    const {content} = this.state;
    const required = ['email'];

    const filtered = content.map(item => {
      const entries = Object.entries(item);
      return entries.reduce((prev, [key, val]) =>
        typeof val === 'string' ? {...prev, [key]: val} : prev, {});
    });

    return filtered.map(item => {
      const entries = Object.entries(item);
      return entries.map(([key, val]) => (required.indexOf(key) > -1) && !val ? key : '');
    });
  };

  submitFeedback = () => {
    const errors = this.validateLocalization();
    const isValid = errors.some(arr =>
      arr.length ? arr.every(el => !el) : !arr.length);

    if (isValid) this.createFeedback();
    this.setState({errors, submited: true});
  };

  createFeedback = async () => {
    try {
      const {
        priority,
        department,
        is_active,
        content,
      } = this.state;

      const isEdit = this.props.type === EDIT_ITEM;
      let method = isEdit ? 'PUT' : 'POST';
      let url = isEdit
        ? `${CORE_URL}/feedbacks/${this.props.id}`
        : `${CORE_URL}/feedbacks`;

      const pageBody = JSON.stringify({
        department: department || null,
        priority: +priority || null,
        is_active: is_active
      });

      const createFeedback = await fetchOne({url, method, body: pageBody});

      if (createFeedback.status === 201 || createFeedback.status === 204) {
        const valid = content.filter(item => item.email);
        const pageData = await createFeedback.json();
        try {
          const toCompare = ['email'];
          const isUpdated = this.state.updated.map(item => {
            const destructed = Object.entries(item).reduce(
              (prev, [key, val]) => {
                const changed = content.find(
                  el => el.localization === item.localization.id,
                )[key];
                const condition =
                  toCompare.indexOf(key) > -1 && changed !== val;
                return condition ? {...prev, [key]: changed} : prev;
              },
              {},
            );
            return {
              ...destructed,
              local_id: item.id,
              id: item.localization.id,
            };
          });

          Promise.all(
            valid.map(async obj => {
              let localizationBody;
              const {
                email, localization
              } = obj;
              const item = isUpdated.find(el => el.id === localization);

              if (item) {
                const {id, local_id, ...updatedValues} = item;
                url = `${CORE_URL}/feedbacks/${id}/localizations/${local_id}`;
                localizationBody = JSON.stringify({
                  ...updatedValues,
                });
                method = 'PUT';
              } else {
                url = `${CORE_URL}/feedbacks/${pageData.id}/localizations`;
                localizationBody = JSON.stringify({
                  localization,
                  email
                });
                method = 'POST';
              }

              const response = await fetchOne({
                url,
                method,
                body: localizationBody,
              });

              const data = await response.json();
              return data;
            }),
          ).then(() => this.props.update())
            .catch(error => console.log(error));
        } catch (error) {
          console.log(error);
          this.setState({ submited: true });
        }
      }
    } catch (err) {
      this.setState({submited: true});
    }
  };

  // открытие модального окна
  openModal = () => this.setState({ open: true });
  // закрытие (отмена) модального окна по подтверждению удалиения локализации
  handleCancel = () => this.setState({ open: false });
  // Удалить существующую локализацию новости
  deleteLocalizationFeedback = async (id, localizationId) => {
    const { content } = this.state;
    if(content.filter(l => !!l.idLocal).length === 1){
      store.dispatch({
        type: REQUEST_MESSAGE,
        data: {
          message: 'Хоча б одна мова має бути заповнена!',
          type: 'error',
        }
      });
      return false;
    }
    const deleteLocalization = await fetchOne({
      url: `${CORE_URL}/feedbacks/${id}/localizations/${localizationId}`,
      method: 'DELETE',
    });

    if (deleteLocalization && deleteLocalization.ok) {
      this.getLanguages();
      this.getDepartments();
      this.handleCancel();
    } else {
      console.log(deleteLocalization.statusText);
      this.handleCancel();
    }
  };

  handleChange = name => event => {
    const {target} = event;
    this.setState({
      [name]: target.value,
    });

    if (name === 'department') {
      this.setContacts(this.state.departments, target.value);
    }
  };

  handleOpen = name => this.setState({[name]: true});

  handleClose = () => {
    this.setState({
      openDepartment: false,
    });
  };

  handleCheck = name => event => this.setState({[name]: event.target.checked});

  changeLocalization = name => event => {
    const {target} = event;
    const {activeTab} = this.state;
    this.setState(state => ({
      content: [
        ...state.content
          .map((item, index) => index === activeTab
            ? ({...item, [name]: target.value})
            : item)
      ],
      submited: false,
    }));
  };

  changeTab = (event, value) => this.setState({activeTab: value});

  render() {
    const {classes} = this.props;
    const {
      open,
      languages,
      activeTab,
      errors,
      department, priority,
      is_active,
      openDepartment,
      departments,
      // contacts,
      content,
      submited,
      loading
    } = this.state;

    const error = errors[activeTab];

    if (loading) return (<Loader/>);

    const dis = content[activeTab] && content[activeTab].idLocal === '';

    return (
      <div className="category-create_wrapper">

        {/* Всплывающее диалоговое окно по удалению локализации */}
        <ModalWindow
          open={open}
          title="Дійсно бажаєте видалити локалізацію?"
          onClose={this.handleCancel}
          onConfirm={() => this.deleteLocalizationFeedback(this.props.id, this.state.content[activeTab].idLocal)}
        />

        <div className="submit-button_wrapper">
          {/* кнопка удалить локализацию */}
          {this.props.type === "EDIT_ITEM" &&
          <Tooltip title="Видалити локализацію" placement="left"
                   onClick={this.openModal}>
            <Button
              disabled={dis}
              style={{ marginRight: '10px' }} variant="contained"
              color="secondary">
              <Delete style={{ color: '#fff' }}/>
            </Button>
          </Tooltip>
          }
          {/* конец --- кнопка удалить локализацию */}

          <Button
            onClick={() => this.submitFeedback()}
            variant="contained"
            color="primary"
          >
            <Save style={{color: '#fff'}}/>
          </Button>
        </div>

        <div className="form-wrapper">
          <Paper style={{padding: 10,
            marginBottom: 15
          }}>
            <Typography
              variant="subheading"
              align="left"
            >
              Властивості контакту для зворотнього зв'язку:
            </Typography>
          </Paper>
          <form
            className={classes.container}
            noValidate
            autoComplete="off"
          >
            <FormControlLabel style={{paddingTop: 10}}
                              control={
                                <Switch
                                  checked={is_active}
                                  onChange={this.handleCheck('is_active')}
                                  color="primary"
                                />
                              }
                              label={is_active ? 'Активний' : 'Неактивний'}
            />
            <FormControl className={classes.formControl}>
              <InputLabel
                htmlFor="department"
                error={error && !department}
              >
                Системний контакт
              </InputLabel>
              <Select
                open={openDepartment}
                onClose={this.handleClose}
                onOpen={() => this.handleOpen('openDepartment')}
                value={this.state.department}
                onChange={this.handleChange('department')}
                inputProps={{
                  name: 'department',
                  id: 'department',
                }}
              >
                {departments.map((item, index) => (
                  <MenuItem
                    key={`department-${index}`}
                    value={item.id}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      height: 'auto'
                    }}
                  >
                    <p style={{paddingLeft: 10}}>{locale(item).title}</p>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              id="standard-priority"
              label="Пріоритет"
              error={error && !priority}
              className={classes.textField}
              value={this.state.priority}
              onChange={this.handleChange('priority')}
              margin="normal"
            />
          </form>
        </div>

        <div  className="form-wrapper form-big">
          <AppBar position="static" color="default">
            <Tabs
              value={activeTab}
              onChange={this.changeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              {languages.map(language => (
                <Tab
                  key={`language-tab-${language.id}`}
                  label={language.title}
                />
              ))}
            </Tabs>
          </AppBar>
          {content && content
            .filter((el, index) => index === activeTab)
            .map((item, index) => (
              <form
                style={{overflow: 'hidden'}}
                key={`form-item-${index}`}
                className={classes.container}
                noValidate
                autoComplete="off"
              >
          {!!(department && item.contacts && item.contacts.length) &&
          <FormControl style={{paddingTop: 10}} className={classes.formControl}>
            <FormLabel
              style={{
                transform: 'translate(-63px, 0) scale(0.75)',
                textAlign: 'left'
              }}
              error={submited && error && error.some(el => el === 'email')}>
              Email</FormLabel>
            <RadioGroup
              value={item.email}
              onChange={this.changeLocalization('email')}
            >
              {item.contacts.map((contact, index) => (
                <FormControlLabel
                  key={`contact-${index}`}
                  value={contact.id}
                  control={<Radio color="primary"/>}
                  label={contact.value}
                />
              ))}

            </RadioGroup>
          </FormControl>
          }
              </form>
                ))}

        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CreateFeedback);
