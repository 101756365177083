import React from 'react';
import './UploadFileComponent.scss';
import fetchOne from '../../../services/fetch';
import { CORE_URL } from '../../../constants/urls';

class UploadFileComponent extends React.Component {
  state = {
    loading: false,
    error: false,
    message: 'Натисність або перетягніть файл для завантаження',
    link: '',
  };

  onChangeHandler = async e => {
    const file = e.target.files[0];
    if (!file) {
      return false;
    }
    const error = this.validateFile(file);
    if (error !== '') {
      this.setState({ error: true, message: error });
    } else {
      this.setState({ loading: true });
      const body = new FormData();
      body.append('file', file);
      const url = `${CORE_URL}/files`;
      try {
        const res = await fetchOne({ url, body, method: 'POST' });
        if (res.ok) {
          const data = await res.json();
          this.setState({
            message: 'Готово! Клікнить щоб скопіювати посилання.',
            link: data.href,
            error: false,
            loading: false,
          });
        } else {
          this.setState({
            message: 'Упс! Щось пішло не так...',
            error: true,
            loading: false,
            link: '',
          });
        }
      } catch (e) {
        this.setState({
          loading: false,
          error: true,
          message: 'Упс! Щось пішло не так...',
          link: '',
        });
        console.log(e);
      }
    }
  };

  onLinkClick = e => {
    e.target.select();
    document.execCommand('copy');
    const timeout = setTimeout(() => {
      this.setState({
        message: 'Натисність або перетягніть файл для завантаження',
        link: '',
      });
      clearTimeout(timeout);
    }, 2000);
    this.setState({ error: false, message: 'Скопійовано!' });
  };

  validateFile = file => {
    let error = '';
    const types = [
      'text/plain',
      'application/pdf',
      'application/msword',
      'application/vnd.ms-excel',
      'image/png',
      'image/jpeg',
    ];
    const size = 10000000;
    // if (types.every(type => type !== file.type)) {
    //   error = `Не можу завантажити файл типу ${file.type}`;
    // }
    if (file.size > size) {
      error = 'Ваш файл має завеликий розмір, оберіть інший < 10 Мб';
    }
    return error;
  };

  render() {
    const { loading, message, link } = this.state;
    return (
      <div className="UploadFileComponent">
        <label htmlFor="file-upload" className="UploadFileComponent__label">
          {loading && 'Loading ...'}
          {!loading && !link && message}
          {!loading && link && (
            <div>
              <p>{message}</p>
              <input
                className="copy-link"
                type="text"
                value={link}
                onClick={this.onLinkClick}
                onChange={() => {}}
              />
            </div>
          )}
        </label>
        <input
          id="file-upload"
          type="file"
          className={
            link
              ? 'UploadFileComponent__input remove'
              : 'UploadFileComponent__input'
          }
          onChange={this.onChangeHandler}
        />
      </div>
    );
  }
}

export default UploadFileComponent;
