import React, { Component } from 'react';
import {connect} from "react-redux";
import '../../assets/styles/nav_components/main.scss';
import '../../assets/styles/autocomplete.scss';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {Close} from "@material-ui/icons";
import Autocomplete from "../util/Autocomplete";
import {fetchTags} from "../../actions/tags";
import {fillValue} from "../../actions/common";
import CKComponent from "../CKEditor/CKComponent";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import {withStyles} from "@material-ui/core";


const styles = ({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
});

class BookLocalization extends Component {
  state = {};


  componentDidMount() {
    this.props.getTags();
  }


  fillTags = (localizationsTagData, localizationId) => {
    let response = [];
    if(localizationsTagData[localizationId]) {
      response = localizationsTagData[localizationId] ? localizationsTagData[localizationId] : []
    }
    return response;
  };

  inputChangeHandler = (e, localizationId) => {
    this.props.changeLocalizationData(e, localizationId);
  };

  setDescription = (data) => {
    this.props.setDescription(data, this.props.localizationId);
  };

  render() {
    const { classes } = this.props;
    const coverRanges = [{ label: 'м\'яка', value: 'softcover' },
      { label: 'тверда', value: 'hardcover' },
      { label: 'матова', value: 'matte' },
      { label: 'глянець', value: 'glossiness' },
    ];

    const { localizationId, localizationsData, localizationsTagData, newTag, errors, tags } = this.props;
    const tagsArr = this.fillTags(localizationsTagData, localizationId);
    const cover = fillValue(localizationId, localizationsData, "cover");

    return (
        <div className="">

          <TextField
            autoFocus
            name="title"
            margin="dense"
            id="title"
            label="Заголовок"
            type="input"
            onChange={(e) => {this.props.changeLocalizationData(e, localizationId)}}
            value={fillValue(localizationId, localizationsData, 'title')}
            error={errors.title && !fillValue(localizationId, localizationsData, 'title')}
            fullWidth
          />

          <TextField
            name="page_amount"
            margin="dense"
            id="page_amount"
            label=" Кількість сторінок"
            type="input"
            onChange={(e) => this.inputChangeHandler(e, localizationId)}
            value={fillValue(localizationId, localizationsData, 'page_amount')}
            error={false}
            fullWidth
            />

          <TextField
            name="isbn"
            margin="dense"
            id="isbn"
            label="isbn"
            type="input"
            onChange={(e) => this.props.changeLocalizationData(e, localizationId)}
            value={fillValue(localizationId, localizationsData, 'isbn')}
            error={false}
            fullWidth
          />

          <TextField
            name="year"
            margin="dense"
            id="year"
            label="Рік"
            type="input"
            onChange={(e) => this.props.changeLocalizationData(e, localizationId)}
            value={fillValue(localizationId, localizationsData, 'year')}
            error={false}
            fullWidth
          />

          <TextField
            name="language"
            margin="dense"
            id="language"
            label="Мова"
            type="input"
            onChange={(e) => this.props.changeLocalizationData(e, localizationId)}
            value={fillValue(localizationId, localizationsData, 'language')}
            error={false}
            fullWidth
          />

          <Select
            multiple
            fullWidth
            displayEmpty
            name="cover"
            margin="dense"
            id="cover"
            value={cover.length ? cover : []}
            onChange={(e) => this.props.changeLocalizationData(e, localizationId)}
            input={<Input id="select-multiple-checkbox" />}
            renderValue={selected => {
                if (!selected.length) {
                    return <em>Палітурка</em>;
                } else {
                    return <div className={classes.chips}>
                        {selected.map(value => (
                            <Chip key={value} label={coverRanges.find(c => c.value === value).label} className={classes.chip} />
                        ))}
                    </div>;
                }
            }}
          >
          <MenuItem value="" disabled>
            Палітурка
          </MenuItem>
          {coverRanges.map(option => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={cover.indexOf(option.value) > -1} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
          </Select>

          <div className="description-block">
            <CKComponent
              setDescription={this.setDescription}
              description={fillValue(localizationId, localizationsData, 'description')}
            />
          </div>

          <div className="description-block">
            <span>Додаткова інформація</span>
            <TextField
              name="additional_information"
              id="additional_information"
              multiline={true}
              rows={4}
              rowsMax={5}
              variant="outlined"
              margin="normal"
              onChange={(e) => this.props.changeLocalizationData(e, localizationId)}
              value={fillValue(localizationId, localizationsData, 'additional_information')}
              fullWidth
            />
          </div>

          <div className="tags-container">
            <div className="add-tag-row">
              <div className="tag-input">
                <Autocomplete
                  suggestions={this.props.tags}
                  onStartTypeTag={this.props.onStartTypeTag}
                  changeNewTag={(str) => this.props.handleAddTag(localizationId, tags, str)}
                  newTag={newTag}
                />
              </div>
            </div>

            <div className="tags-buttons-container">
               {tagsArr.map((item, index) =>
                <div className="tag-item" key={`tag-item-${index}`}>
                  <span>{item.title}</span>
                  <div className="tag-remove">
                    <Close
                      style={{ color: '#686868', height: "16px" }}
                      onClick={() => this.props.handleRemoveTag(localizationId, localizationsData, index)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

        </div>
    );
  }
}

const mapStateToProps = state => ({
  tags: state.tags
});

const mapDispatchToProps = dispatch => ({
  getTags: () => {
    dispatch(fetchTags());
  }
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(BookLocalization))
