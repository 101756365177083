import React, { Component } from 'react';
import { CREATE_ITEM, EDIT_ITEM } from '../constants/types';
import fetchOne from "../services/fetch";
import { CORE_URL } from "../constants/urls";
import AllInfoBlocks from "../components/informationBlocks/AllInfoBlocks";
import CreateInfoBlock from "../components/informationBlocks/CreateInfoBlock";
import ModalWindow from './layout/ModalWindow';

const params = [
  { name: "Всі інфо. блоки" },
  { name: "Створити інфо. блок" },
];

const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
};

class Pages extends Component {
  state = {
     type: CREATE_ITEM,
     open: false,
     section: 0,
     id: '',
     data: [],
     selected: [],
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const data = await fetchOne({ url: `${CORE_URL}/information-blocks?limit=100` });
    this.setState({
      type: CREATE_ITEM,
      selected: [],
      data,
      id: '',
    });
  };

  updateData = () => {
    this.setSection(0);
    this.getData();
  };

  setSection = section => this.setState({ type: CREATE_ITEM, section });

  setEdit = (id, type) => this.setState({ id, type, section: 1 });

  deleteItem = async () => {
    const { id } = this.state;
    try {
      const deleted = await fetchOne({
        url: `${CORE_URL}/information-blocks/${id}`,
        method: 'DELETE',
        headers,
      });

      this.setState({
        type: CREATE_ITEM,
        open: false,
        selected: [],
        id: '',
      });
      if (deleted.status === 204) this.getData();
    } catch (err) {
      this.setState({
        type: CREATE_ITEM,
        open: false,
        selected: [],
        id: '',
      });
    }
  };

  deleteMultiple = async () => {
    const { selected } = this.state;

    try {
      const deleteMultiple = await Promise.all(selected
        .map(itemID => {
          return fetchOne({
            url: `${CORE_URL}/information-blocks/${itemID}`,
            method: 'DELETE',
            headers,
          });
        }));

      if (deleteMultiple.every(deleted => deleted.status === 204)) {
        this.getData();
      }

      this.setState({
        type: CREATE_ITEM,
        open: false,
        selected: [],
        id: '',
      });
    } catch (err) {
      console.log(err);
    }
  };

  openModal = (id, arr) => {
    this.setState({
      selected: arr || [],
      id: id || '',
      open: true,
    });
  };

  handleClose = () => this.setState({ open: false });

  handleCancel = () => this.setState({ open: false });

  render() {
    const {
      section, data, type, open, id,
    } = this.state;

    return (
      <div className="user-content">
        <div className="user-tabs-wrapper">
          <div className="tab-line">
            {params.map((item, index) => (
              <div
                className={section === index ? 'tab-item tab-active' : 'tab-item'}
                key={`nav-item-${item.name}`}
                onClick={() => this.setSection(index)}
              >
                <div className="tab-item-block">
                  {(index && type === EDIT_ITEM) ? 'Редагування інфо. блоку' : item.name}
                </div>
              </div>
            ))}
          </div>
        </div>

        {section === 0 && (
          <AllInfoBlocks
            data={data}
            openModal={this.openModal}
            onEdit={this.setEdit}
          />
        )}
        {section === 1 && (
          <CreateInfoBlock
            id={id}
            type={type}
            update={this.updateData}
          />
        )}

        <ModalWindow
          open={open}
          title="Видалити інформаційний блок?"
          onClose={this.handleClose}
          onConfirm={() => {
            if (id) {
              this.deleteItem();
            } else {
              this.deleteMultiple();
            }
          }}
        />
      </div>
    );
  }
}

export default Pages;
