import React, { Component } from 'react';
import '../../assets/styles/nav_components/users.scss';
import AllEditions from "../SubNavComponents/AllEditions"
import CreateEdition from "../SubNavComponents/CreateEdition";
import EditEdition from "../SubNavComponents/EditEdition";
import BookReviews from "../SubNavComponents/BookReviews";
import BookOrders from "../SubNavComponents/BookOrders";
import BookSeries from "../SubNavComponents/BookSeries";

class Editions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      curTabId: 0,
      editMode: false,
      editObject: {},
    };
  }

  backToMain = () => {
    this.setState({
      curTabId: 0,
      editMode: false,
      editObject: {},
    })
  }

  toggleCurNav(curTabId) {
    this.setState({ curTabId, editMode: false });
  }

  toEdit = (obj) => {
    this.setState({curTabId: 1, editMode: true, editObject: obj[0]});
  };

  render() {
    const { curTabId, editObject } = this.state;

    const params = [
      {component: <AllEditions toEdit={this.toEdit}/>, name: "Всі книги"},
      {component: this.state.editMode ? <EditEdition object={editObject} backToMain={this.backToMain}/> : <CreateEdition />,
        name: this.state.editMode ? "Редагувати книгу" : "Створити книгу" },
      {component: <BookSeries />, name: "Серії книг"},
      {component: <BookReviews />, name: "Відгуки"},
      {component: <BookOrders />, name: "Замовлення"},
    ];

    return (
      <React.Fragment>
        <div className="user-content">
          <div className="user-tabs-wrapper">
            <div className="tab-line">
              {
                params.map((item, index) => (
                  <div
                    className={curTabId === index ? 'tab-item tab-active' : 'tab-item'}
                    key={`nav-item-${item.name}`}
                    onClick={() => this.toggleCurNav(index)}
                  >
                    <div className="tab-item-block">
                      {item.name}
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

          {params[curTabId].component}

        </div>
      </React.Fragment>
    );
  }
}

export default Editions;
