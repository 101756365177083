import React from 'react';

import {withStyles} from '@material-ui/core/styles';

import '../../assets/styles/categories.scss';

import fetchOne from '../../services/fetch';
import {CORE_URL, DEPARTMENTS_PATH} from '../../constants/urls';
import {EDIT_ITEM} from '../../constants/types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import {Delete, Save} from '@material-ui/icons';
import Button from '@material-ui/core/Button/Button';
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core/es/index";
import Loader from "../util/Loader";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import store from "../../store";
import {REQUEST_MESSAGE} from "../../constants/redux";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ModalWindow from '../../views/layout/ModalWindow';


const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '100%',
  },
  dense: {
    marginTop: 0,
  },
  menu: {
    width: '100%',
  },
  formControl: {
    margin: theme.spacing.unit,
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit,
  },
});

const colorsArr = [{color: '#f44336'}, {color: '#009688'}, {color: '#9c27b0'}, {color: '#2196f3'}];

const NodeModel = {
  id: '',
  localization: {},
  description: '',
  title: '',
  position: '',
  idLocal: '', // id локализации
};

const locale = (localizations) => {
  const localLanguage = localStorage.getItem('language');
  const lang_system_contacts = localizations.filter(localization => localization.localization.id === localLanguage);
  return !!localizations.length ? lang_system_contacts[0] : {};
};

class CreateNode extends React.Component {

  state = {
      open: false,

      parent_id: '',
      color: '',
      employee: '',
      department: '',
      priority: 1,

      languages: [],
      activeTab: 0,
      content: [],

      employees: [],
      departments: [],
      parents: [],
      pages: [],

      openDepartment: false,
      openEmployee: false,
      openParent: false,
      openPages: false,

      errors: [],

      updated: [],
      submited: false,
      loading: true,

      government: false,
  };


  componentDidMount() {
    this.getLanguages();
    this.getSystemContacts();
  }

  getSystemContacts = async () => {
    const {id, type, parent_id} = this.props;
    const employees = await fetchOne({url: `${CORE_URL}/employees?isActive=true&limit=1000`});
    const systemContacts = await fetchOne({url: `${DEPARTMENTS_PATH}?isActive=true`});
    const pages = await fetchOne({url: `${CORE_URL}/pages`});
    const parentsObj = await fetchOne({url: `${CORE_URL}/nodes?isActive=true`});
    let parents = await this.createParentArr(parentsObj);

    if (parent_id) {
      const parent = await fetchOne({
        url: `${CORE_URL}/nodes/${parent_id}`,
      });

      this.setState({
        parent_id: parent ? parent.id : '',
      });
    }

    this.setState({
      departments: systemContacts,
      parents: parents,
      pages: pages,
      employees: employees,
      loading: false
    });

  };

  getLanguages = async () => {
    const {id, type} = this.props;
    const languages = await fetchOne({url: `${CORE_URL}/languages`});

    if (type === EDIT_ITEM) {
      const {
        node_localizations,
        color,
        government,
        department,
        employee,
        priority
      } = await fetchOne({url: `${CORE_URL}/nodes/${id}`});

      const parent = await fetchOne({url: `${CORE_URL}/nodes/${id}/parent`});

      let content = new Array(languages.length)
        .fill(NodeModel)
        .map((item, i) => ({...item, localization: languages[i].id}));

      if (node_localizations.length) {

        content = content.map(item => {
          const Node = node_localizations
            .find(el => item.localization === el.localization.id);
          if (Node) {
            const {id, title, description, position, page} = Node;

            const idLocal = Node.id; // добавляем id локализации
            return {
              ...item,
              id, title, description, position, page: page ? page.id : '',
              idLocal, // добавляем id локализации
            };
          }

          return item;
        });
      }

      this.setState({
        updated: node_localizations,
        color,
        government,
        employee: employee ? employee.id : '',
        department: department ? department.id : '',
        priority: priority || 1,
        parent_id: parent ? parent.id : '',
        languages,
        content,
      });
    } else {
      const content = new Array(languages.length)
        .fill(NodeModel)
        .map((item, i) => ({...item, localization: languages[i].id}));
      this.setState({languages, content});
    }
  };

  createParentArr = (obj) => {
    let parentArr = [];

    let findObjectByLabel = (obj) => {

      if (!obj) {
        return;
      }
      const label = 'children';

      if (obj.id) (
        parentArr.push({
          id: obj.id,
          node_localizations: obj.node_localizations
        })
      );

      if (obj.hasOwnProperty(label)) {
        findObjectByLabel(obj[label], label);
      } else if (obj.length) {
        obj.map(item => {
          findObjectByLabel(item, label);
        })

      }

      return null;
    };

    findObjectByLabel(obj);

    return parentArr;
  };

  validateLocalization = () => {
    const {content} = this.state;
    const required = ['title'];

    const filtered = content.map(item => {
      const entries = Object.entries(item);
      return entries.reduce((prev, [key, val]) =>
        typeof val === 'string' ? {...prev, [key]: val} : prev, {});
    });

    return filtered.map(item => {
      const entries = Object.entries(item);
      return entries.map(([key, val]) => (required.indexOf(key) > -1) && !val ? key : '');
    });
  };

  submitPage = () => {
    const errors = this.validateLocalization();
    const isValid = errors.some(arr =>
      arr.length ? arr.every(el => !el) : !arr.length);

    if (isValid) this.createNode();
    this.setState({errors, submited: true});
  };

  createNode = async () => {
    try {
      const {
        parent_id,
        color,
        government,
        employee,
        priority,
        department,
        content,
      } = this.state;

      const isEdit = this.props.type === EDIT_ITEM;
      let method = isEdit ? 'PUT' : 'POST';
      let url = isEdit
        ? `${CORE_URL}/nodes/${this.props.id}`
        : `${CORE_URL}/nodes`;

      // костыль №1
      let pageBody = {};
      if (method === 'PUT') {
        pageBody = JSON.stringify({
          parent_id: parent_id || null,
          color: color || null,
          government: government,
          employee: employee || '',
          department: department || '',
          priority: +priority || null,
        });
      } else {
        pageBody = JSON.stringify({
          parent_id: parent_id || null,
          color: color || null,
          government: government,
          employee: employee || null,
          department: department || null,
          priority: +priority || null,
        });
      }
      // конец --- костыль №1

      const createNode = await fetchOne({url, method, body: pageBody});

      if (createNode.status === 201 || createNode.status === 204) {
        const valid = content.filter(item => item.title);
        const pageData = await createNode.json();
        try {
          const toCompare = ['description', 'title', 'position', 'page'];
          const isUpdated = this.state.updated.map(item => {
            const destructed = Object.entries(item).reduce(
              (prev, [key, val]) => {
                const changed = content.find(
                  el => el.localization === item.localization.id,
                )[key];
                const condition =
                  toCompare.indexOf(key) > -1 && changed !== val;
                return condition ? {...prev, [key]: changed} : prev;
              },
              {},
            );
            return {
              ...destructed,
              local_id: item.id,
              id: item.localization.id,
            };
          });

          Promise.all(
            valid.map(async obj => {
              let localizationBody;
              const {
                description, title, position, page, localization
              } = obj;
              const item = isUpdated.find(el => el.id === localization);

              if (item) {
                const {id, local_id, ...updatedValues} = item;
                url = `${CORE_URL}/nodes/${id}/localizations/${local_id}`;

                // костыль №2, если у нас значение page === '' отправляем null
                const updValues = {...updatedValues};
                if (!updValues.page) {
                  updValues.page = null;
                }
                // конец --- костыль №2

                localizationBody = JSON.stringify({
                  ...updValues,
                });
                method = 'PUT';
              } else {
                url = `${CORE_URL}/nodes/${pageData.id}/localizations`;
                localizationBody = JSON.stringify({
                  localization,
                  description,
                  position,
                  page,
                  title
                });
                method = 'POST';
              }

              const response = await fetchOne({
                url,
                method,
                body: localizationBody,
              });

              const data = await response.json();
              return data;
            }),
          ).then(() => this.props.update())
            .catch(error => console.log(error));
        } catch (error) {
          console.log(error);
          this.setState({submited: true});
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({submited: true});
    }
  };

  changeLocalization = name => event => {
    const {activeTab} = this.state;
    const value = event.target.value.length ? event.target.value : null;
    this.setState(state => ({
      content: [
        ...state.content
          .map((item, index) => index === activeTab
            ? ({...item, [name]: value})
            : item)
      ],
      submited: false,
    }));
  };

  // открытие модального окна
  openModal = () => this.setState({ open: true });
  // закрытие (отмена) модального окна по подтверждению удалиения локализации
  handleCancel = () => this.setState({ open: false });
  // Удалить существующую локализацию
  deleteLocalizationNode = async (id, localizationId) => {
    const { content } = this.state;
    if(content.filter(l => !!l.idLocal).length === 1){
      store.dispatch({
        type: REQUEST_MESSAGE,
        data: {
          message: 'Хоча б одна мова має бути заповнена!',
          type: 'error',
        }
      });
      return false;
    }
    const deleteLocalization = await fetchOne({
      url: `${CORE_URL}/nodes/${id}/localizations/${localizationId}`,
      method: 'DELETE',
    });

    if (deleteLocalization && deleteLocalization.ok) {
      this.componentDidMount();
      this.handleCancel();
    } else {
      console.log(deleteLocalization.statusText);
      this.handleCancel();
    }
  };

  chooseColor(e, color) {
    e.preventDefault();
    this.setState(prevState => ({
      curObj: {
        ...prevState.curObj,
        color: color
      }, color: color,
      fields: {...this.state.fields, color: false}
    }));
    // при вводе в поле убираем ошибки из стэйта
    if (this.state.errors.color) {
      const {color, ...rest} = this.state.errors;
      this.setState({errors: rest});
    }
  }

  handleClose = () => {
    this.setState({
      openDepartment: false,
      openEmployee: false,
      openPages: false,
      openParent: false,
    });
  };

  handleOpen = name => this.setState({[name]: true});

  handleCheck = name => event =>
    this.setState({[name]: event.target.checked});

  handleChange = name => ({target}) => this.setState({[name]: target.value, submited: false});

  changeTab = (event, value) => this.setState({activeTab: value});

  pickColor = (e) => this.setState({ color: e.target.value });

  render() {
    const {classes} = this.props;
    const {
      languages,
      activeTab,
      errors,
      color,
      department,
      openDepartment,
      departments,
      employee,
      openEmployee,
      parent_id,
      parents,
      openPages,
      pages,
      openParent,
      employees,
      priority,
      content,
      submited,
      loading,
      government,
      open,
    } = this.state;
    const error = errors[activeTab];

    if (loading) return (<Loader/>);

    const dis = content[activeTab] && content[activeTab].idLocal === '';

    return (
      <div className="category-create_wrapper">

        {/* Всплывающее диалоговое окно по удалению локализации */}
        <ModalWindow
          open={open}
          title="Дійсно бажаєте видалити локалізацію?"
          onClose={this.handleCancel}
          onConfirm={() => this.deleteLocalizationNode(this.props.id, this.state.content[activeTab].idLocal)}
        />

        <div className="submit-button_wrapper">
          {/* кнопка удалить локализацию */}
          {this.props.type === "EDIT_ITEM" &&
          <Tooltip title="Видалити локализацію" placement="left"
                   onClick={this.openModal}>
            <Button
              disabled={dis}
              style={{marginRight: '10px'}} variant="contained"
              color="secondary">
              <Delete style={{color: '#fff'}}/>
            </Button>
          </Tooltip>
          }
          {/* конец --- кнопка удалить локализацию */}

          <Button
            onClick={() => this.submitPage()}
            variant="contained"
            color="primary"
          >
            <Save style={{color: '#fff'}}/>
          </Button>
        </div>

        <div className="form-wrapper">
          <Paper style={{
            padding: 10,
            marginBottom: 15
          }}>
            <Typography
              variant="subheading"
              align="left"
            >
              Властивості організаційного вузла:
            </Typography>
          </Paper>

          <div style={{
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            paddingBottom: '10px',
            width: '100%',
            marginBottom: '5px',
          }}
               className={errors.color ? "tag-color-block-error" : "tag-color-block"}>

            <div className="tag-colors-row"
                 style={{
                   justifyContent: 'left',
                 }}
            >

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label style={{ marginRight: '25px' }} htmlFor="head">
                  <Typography
                    variant="subheading"
                    align="left"
                    style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                  >
                    Обрати колір
                  </Typography>
                </label>
                <input
                  onChange={this.pickColor}
                  type="color"
                  id="head"
                  name="head"
                  value={this.state.color || '#ffffff'}
                />
              </div>

              {/*{color &&*/}
              {/*<div className="tag-colors-item"*/}
              {/*     style={{backgroundColor: color || '# fff'}}>*/}
              {/*</div>*/}
              {/*}*/}
            </div>
          </div>

          {/*<div className="tag-colors-row">*/}
          {/*  {*/}
          {/*    colorsArr.map((item, i) =>*/}
          {/*      <div className="tag-colors-item"*/}
          {/*           key={`tag-colors-item-${i}`}*/}
          {/*           style={{backgroundColor: item.color}}*/}
          {/*           onClick={(e) => this.chooseColor(e, item.color)}*/}
          {/*      >*/}
          {/*      </div>*/}
          {/*    )*/}
          {/*  }*/}
          {/*</div>*/}

          <FormControl
            className={classes.formControl}
            style={{
              textAlign: 'left',
              margin: '8px 0'
            }}>
            <InputLabel htmlFor="employee">
              Керівник
            </InputLabel>
            <Select
              open={openEmployee}
              onClose={this.handleClose}
              onOpen={() => this.handleOpen('openEmployee')}
              value={this.state.employee}
              onChange={this.handleChange('employee')}
              inputProps={{
                name: 'employee',
                id: 'employee',
              }}
            >
              <MenuItem value="">
                <p>-</p>
              </MenuItem>
              {employees.map((item, index) => (
                <MenuItem
                  key={`employee-${index}`}
                  value={item.id}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: 'auto',
                  }}
                >
                  <p style={{paddingLeft: 10}}>{locale(item.employee_localizations) ?
                    `${locale(item.employee_localizations).surname}
                                    ${locale(item.employee_localizations).name}
                                    ${locale(item.employee_localizations).middle_name}`
                    : ''}
                  </p>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControlLabel
            control={
              <Switch
                checked={government}
                onChange={this.handleCheck('government')}
                color="primary"
              />
            }
            label={government ? 'Відображати в "Керівництво"' : 'Не відображати в "Керівництво"'}
          />

          <FormControl
            className={classes.formControl}
            style={{
              textAlign: 'left',
              margin: '8px 0'
            }}>
            <InputLabel htmlFor="department">
              Системний контакт
            </InputLabel>
            <Select
              open={openDepartment}
              onClose={this.handleClose}
              onOpen={() => this.handleOpen('openDepartment')}
              value={this.state.department}
              onChange={this.handleChange('department')}
              inputProps={{
                name: 'department',
                id: 'department',
              }}
            >
              <MenuItem value="">
                <p>-</p>
              </MenuItem>
              {departments.map((item, index) => (
                <MenuItem
                  key={`department-${index}`}
                  value={item.id}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: 'auto',
                  }}
                >
                  <p style={{paddingLeft: 10}}>
                    {locale(item.department_localizations) ? locale(item.department_localizations).title : ''}
                  </p>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className={classes.formControl}
                       style={{
                         textAlign: 'left',
                         margin: '8px 0'
                       }}>
            <InputLabel htmlFor="parent_id" error={submited && error && !parent_id}>
              Головний орг. вузол
            </InputLabel>
            <Select
              disabled={!parents.length}
              open={openParent}
              onClose={this.handleClose}
              onOpen={() => this.handleOpen('openParent')}
              value={this.state.parent_id}
              onChange={this.handleChange('parent_id')}
              inputProps={{
                name: 'parent_id',
                id: 'parent',
              }}
            >
              {parents.map((item, index) => (
                <MenuItem
                  key={`parent-${index}`}
                  value={item.id}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: 'auto',
                  }}
                >
                  <p style={{paddingLeft: 10}}>
                    {locale(item.node_localizations) ? locale(item.node_localizations).title : ''}
                  </p>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            id="standard-priority"
            label="Пріоритет"
            error={submited && error && !priority}
            className={classes.textField}
            value={priority}
            onChange={this.handleChange('priority')}
            margin="normal"
          />
        </div>

        <div className="form-wrapper form-big">
          <AppBar position="static" color="default">
            <Tabs
              value={activeTab}
              onChange={this.changeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              {languages.map(language => (
                <Tab
                  key={`language-tab-${language.id}`}
                  label={language.title}
                />
              ))}
            </Tabs>
          </AppBar>
          {content
            .filter((el, index) => index === activeTab)
            .map((item, index) => (
              <form
                style={{overflow: 'hidden'}}
                key={`form-item-${index}`}
                className={classes.container}
                noValidate
                autoComplete="off"
              >
                {/*<FormControlLabel*/}
                {/*style={{paddingTop: 10}}*/}
                {/*control={*/}
                {/*<Switch*/}
                {/*checked={item.is_active}*/}
                {/*onChange={this.changeLocalization('is_active')}*/}
                {/*color="primary"*/}
                {/*/>*/}
                {/*}*/}
                {/*label={item.is_active ? 'Активний' : 'Неактивний'}*/}
                {/*/>*/}

                <TextField
                  id="standard-title"
                  label="Назва"
                  className={classes.textField}
                  value={item.title}
                  error={submited && error && error.some(el => el === 'title')}
                  onChange={this.changeLocalization('title')}
                  margin="normal"
                />

                <FormControl
                  className={classes.formControl}
                  style={{
                    textAlign: 'left',
                    margin: '8px 0'
                  }}>
                  <InputLabel htmlFor="page">
                    Сторінка
                  </InputLabel>
                  <Select
                    open={openPages}
                    onClose={this.handleClose}
                    onOpen={() => this.handleOpen('openPages')}
                    value={item.page || ''}
                    onChange={this.changeLocalization('page')}
                    inputProps={{
                      name: 'page',
                      id: 'page',
                    }}
                  >
                    <MenuItem value="">
                      <p>-</p>
                    </MenuItem>
                    {pages.collection.map((item, index) => (
                      <MenuItem
                        key={`page-${index}`}
                        value={item.id}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          height: 'auto',
                        }}
                      >
                        <p style={{paddingLeft: 10}}>
                          {locale(item.page_localizations) ?
                            `${locale(item.page_localizations).title}`
                            : ''}
                        </p>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div style={{
                  width: '100%',
                  textAlign: 'left',
                  padding: '15px 18px 0',
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
                }}>
                  Опис:
                </div>
                <TextField
                  name="description"
                  id="description"
                  multiline={true}
                  rows={4}
                  rowsMax={5}
                  variant="outlined"
                  margin="normal"
                  onChange={this.changeLocalization('description')}
                  value={item.description}
                  fullWidth
                />

                <TextField
                  id="standard-title"
                  label="Посада"
                  className={classes.textField}
                  value={item.position}
                  error={submited && error && error.some(el => el === 'position')}
                  onChange={this.changeLocalization('position')}
                  margin="normal"
                />
              </form>
            ))}
        </div>

      </div>
    );
  }
}

export default withStyles(styles)(CreateNode);
