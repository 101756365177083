import {
  ADD_TAG_FAIL,
  ADD_TAG_INIT,
  ADD_TAG_SUCCESS,
  ALL_TAGS_DOWNLOADED,
  UPDATE_TAG_FAIL,
  UPDATE_TAG_INIT,
  UPDATE_TAG_SUCCESS,
  DELETE_TAG_FAIL,
  DELETE_TAG_SUCCESS
} from '../constants/redux';
import {TAGS_PATH} from '../constants/urls';
import { getErrors } from './errors';
import fetchOne from "../services/fetch";


export function tagsDownloaded(tags) {
  return { type: ALL_TAGS_DOWNLOADED, tags };
}

export const addTagInit = () => ({
  type: ADD_TAG_INIT,
});

export const tagAddFail = () => ({
  type: ADD_TAG_FAIL
});

export const tagAddSuccess = () => ({
  type: ADD_TAG_SUCCESS
});

export const updateTagInit = () => ({
  type: UPDATE_TAG_INIT
});

export const tagUpdateFail = () => ({
  type: UPDATE_TAG_FAIL
});

export const tagUpdateSuccess = () => ({
  type: UPDATE_TAG_SUCCESS
});

export const tagDeleteFail = () => ({
  type: DELETE_TAG_FAIL
});

export const tagDeleteSuccess = () => ({
  type: DELETE_TAG_SUCCESS
});

const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
};

export function fetchTags() {
  return async function (dispatch) {
    try {
      const tags = await fetchOne({
        url: `${TAGS_PATH}?limit=100`,
      });
      dispatch(tagsDownloaded(tags));
    } catch (err) {
      console.log(err.message);
      dispatch(getErrors('Some error: ' + err.message));
    }
  };
}

export function addTag(data) {
  return async function (dispatch) {
    try {
      const tags = await fetchOne({
        url: TAGS_PATH,
        method: 'POST',
        body: JSON.stringify(data),
        headers: headers
      });
      dispatch(tagAddSuccess());
      dispatch(fetchTags());
      console.log(tags);
    } catch (err) {
      console.log(err.message);
      dispatch(getErrors('Some error: ' + err.message));
      dispatch(tagAddFail());
    }
  };
}

export function updateTag(data, id) {
  return async function (dispatch) {
    try {
      const tags = await fetchOne({
        url: `${TAGS_PATH}/${id}`,
        method: 'PUT',
        body: JSON.stringify(data),
        headers: headers
      });
      dispatch(tagUpdateSuccess());
      dispatch(fetchTags());
      console.log(tags);
    } catch (err) {
      console.log(err.message);
      dispatch(getErrors('Some error: ' + err.message));
      dispatch(tagUpdateFail());
    }
  };
}

export function deleteTag(id) {
  return async function (dispatch) {
    try {
      const tags = await fetchOne({
        url: `${TAGS_PATH}/${id}`,
        method: 'DELETE'
      });
      dispatch(tagDeleteSuccess());
      dispatch(fetchTags());
      console.log(tags);
    } catch (err) {
      console.log(err.message);
      dispatch(getErrors('Some error: ' + err.message));
      dispatch(tagDeleteFail());
    }
  };
}
