export const tableHeadSort = current => (a, b, orderBy) => {
  if (!orderBy) return;

  try {
    let left;
    let right;
    if (['published_at', 'created_at', 'published_end_at'].indexOf(orderBy) > -1) {
      left = new Date(a[orderBy] || a['created_at']).getTime();
      right = new Date(b[orderBy] || b['created_at']).getTime();
      return numSort(right, left);
    } else if (['searchable', 'is_active'].indexOf(orderBy) > -1) {
      left = a[orderBy];
      right = b[orderBy];
      return boolSort(right, left);
    } else if (orderBy === 'languages') {
      left = current && a[`${current}_localizations`].length;
      right = current && b[`${current}_localizations`].length;
      return numSort(right, left);
    } else {
      left = a[orderBy] ? a[orderBy] : (current && a[`${current}_localizations`][0][orderBy]);
      right = b[orderBy] ? b[orderBy] : (current && b[`${current}_localizations`][0][orderBy]);
      if (right && (typeof right.length === 'number')) {
        return numSort(right.length, left.length);
      }
      return numSort(right, left);
    }
  } catch (err) {
    return null;
  }
};

export const sortLocalizationByPriority = object => {
  object.sort((a, b) => a.localization.priority < b.localization.priority ? -1 : (a.localization.priority > b.localization.priority ? 1 : 0));
  return object;
};

function boolSort(right, left) {
  if (left && !right) return -1;
  if (right && !left) return 1;
  return 0;
}

function numSort(right, left) {
  if (right < left) return -1;
  if (right > left) return 1;
  return 0;
}
