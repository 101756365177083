import React, {Component} from 'react';
import '../../assets/styles/nav_components/main.scss';
import BookSeriesTableHead from "../BookSeriesTable";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import fetchOne from "../../services/fetch";
import {GET_BOOK_SERIES, GET_LANGUAGES} from "../../constants/urls";
import SeriesLocalization from "../Localization/SeriesLocalization";
import Loader from "../util/Loader";
import {Add} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {allFalse} from "../../actions/common";
import {ranges} from "../../constants/main";


function TabContainer(props) {
  return (
    <Typography component="div" style={{padding: 8 * 3}}>
      {props.children}
    </Typography>
  );
}

class BookSeries extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      languages: [],
      errors: {},
      selectedLanguage: 0,
      localizationsData: {},
      localizationId: '',
      series: [],
      loading: true,
      priority: '',
      fields: {priority: true}
    };
    this.onChange = this.onChange.bind(this);
  }

  async componentDidMount() {
    try {
      const languages = await fetchOne({
        url: GET_LANGUAGES,
      });
      this.setState({languages, loading: false});
    } catch (err) {
      console.log(err.message);
    }

    try {
      const series = await fetchOne({url: GET_BOOK_SERIES});
      this.setState({series, loading: false});
    } catch (e) {
      console.log(e);
    }
  }

  handleClickOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  handleChangeLanguage = (event, value) => {
    this.setState({selectedLanguage: value});
  };

  changeLocalizationData = (event, id) => {
    const {target: {name, value }} = event;
    this.setState({
      localizationsData: {
        ...this.state.localizationsData,
        [id]: {...this.state.localizationsData[id], [name]: value}
      }
    });
  };

  onChange(e) {
    const {target: {name, value}} = e;
      this.setState(prevState => ({
        fields: {...prevState.fields, [name]: false},
        errors: {...prevState.errors, [name]: false},
        [name]: value
      }));
  }

  async handleSubmit(event) {
    event.preventDefault();
    const {localizationsData, priority, fields} = this.state;


    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };

    if (allFalse(fields)) {
      const formData = {
        priority: parseInt(priority),
      };

      try {
        let serieLoc = '';

        // create serie
        const serie = await fetchOne({
          url: `${GET_BOOK_SERIES}`,
          method: "POST",
          body: JSON.stringify(formData),
          headers: headers
        });
        const rserie = await serie.json();
        console.log(rserie);

        // create localizations
        for (let [key, value] of Object.entries(localizationsData)) {
          let serieLocalizationData = {
            localization: key,
            title: value.title ? value.title : '',
          };

          if (value.id) {
            serieLoc = await fetchOne({
              url: `${GET_BOOK_SERIES}/${rserie.id}/localizations/${value.id}`,
              method: "PUT",
              body: JSON.stringify(serieLocalizationData),
              headers: headers
            });

          } else {
            serieLoc = await fetchOne({
              url: `${GET_BOOK_SERIES}/${rserie.id}/localizations`,
              method: "POST",
              body: JSON.stringify(serieLocalizationData),
              headers: headers
            });
          }
        }
        console.log(serie);
        console.log(serieLoc);

        if (serieLoc.ok) {
          const newSeries = await fetchOne({url: GET_BOOK_SERIES});
          this.setState({series: newSeries, loading: false, open: false});
        }
      } catch (err) {
        console.log(err.message);
      }
    } else {
      this.setState({errors: fields});
      console.log(this.state);
    }
  }

  render() {

    const {
      languages,
      selectedLanguage,
      localizationsData,
      series,
      loading,
      priority,
      errors
    } = this.state;
    console.log(this.state);

    if (loading) return (<Loader/>);
    if (!loading && !series.length) return (<h3>Серій не знайдено.</h3>);

    return (
      <React.Fragment>
        <div className="tab-block-content no-shadow">
          <div className="customers-header-block">
            <span>Список серій видань</span>

            <div>
              <Button
                onClick={this.handleClickOpen}
                variant="contained"
                color="primary"
              >
                <Add style={{color: '#fff'}}/>
              </Button>
              <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth={"xl"}
              >
                <DialogContent>
                  <DialogContentText>
                    Створити нову серію
                  </DialogContentText>

                  <div className="show-block">
                    <div className="show-block-form">
                      <div className="bottom-submit-form">
                        <TextField
                          autoFocus
                          name="priority"
                          margin="dense"
                          id="priority"
                          label="Пріоритет"
                          type="input"
                          onChange={this.onChange}
                          value={priority}
                          error={errors.priority}
                          fullWidth
                        />
                      </div>
                    </div>

                    <div className="show-block-additions">
                      {
                        languages.length ?
                          <div className="">
                            <AppBar position="static" color="default">
                              <Tabs
                                value={selectedLanguage}
                                onChange={this.handleChangeLanguage}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                              >
                                {
                                  languages.map((item, i) =>
                                    <Tab label={item.title} key={`teb-lang-${i}`}/>
                                  )
                                }
                              </Tabs>
                            </AppBar>
                            {<TabContainer>
                              <SeriesLocalization
                                localizationId={languages[selectedLanguage].id}
                                changeLocalizationData={this.changeLocalizationData}
                                localizationsData={localizationsData}
                              />
                            </TabContainer>}
                          </div> : ''
                      }

                    </div>
                  </div>

                </DialogContent>
                <DialogActions>
                  {Object.keys(localizationsData).length && undefined !== Object.values(localizationsData).find(l => l.title.length > 0) ?
                      <Button onClick={(e) => this.handleSubmit(e)} color="primary">
                        Створити
                      </Button> :
                      <DialogContentText>
                        Має бути заповнена назва хоча б в 1 мові
                      </DialogContentText>
                  }
                  <Button onClick={this.handleClose} color="primary">
                    Відмінити
                  </Button>
                </DialogActions>
              </Dialog>
            </div>

          </div>
          <BookSeriesTableHead series={series} languages={languages}/>
        </div>
      </React.Fragment>
    );
  }
}

export default BookSeries;
