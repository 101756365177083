import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import '../../assets/styles/header.scss';

import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

import Typography from "@material-ui/core/Typography";
import Logo from "../../components/util/Logo";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: localStorage.getItem('username'),
    }
  }

  render() {
    const { username } = this.state;

    return (
      <React.Fragment>
        <div className="header-wrapper">
          <div className="header-logo">
            <Logo />
          </div>
          <div className="header-main-block">
            <Grid container justify="center" alignItems="center">
              <Link
                to="/users"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    marginRight: 10,
                    paddingRight: 10,
                    fontWeight: 'bold',
                    borderRight: '2px solid',
                  }}
                >
                  Всі користувачі
                </Typography>
              </Link>

              <Link
                to="/user"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    marginRight: 10,
                    fontWeight: 'bold',
                  }}
                >
                  {username}
                </Typography>
                <Avatar alt="" src="" className=""/>
              </Link>
            </Grid>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Header;
