import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import '../assets/styles/categories.scss';

import fetchOne from '../services/fetch';
import { CORE_URL, DEPARTMENTS_PATH } from '../constants/urls';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';


import { Save } from '@material-ui/icons';
import Button from '@material-ui/core/Button/Button';
import TextField from '@material-ui/core/TextField/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '95%',
  },
  dense: {
    marginTop: 20,
  },
  menu: {
    width: '95%',
  },
  formControl: {
    margin: theme.spacing.unit,
    width: '95%',
  },
  tagsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
  },
  tagsList: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    listStyleType: 'none',
    padding: '10px 0',
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit,
  },
  tagsItem: {
    border: '1px solid',
    borderRadius: 5,
    marginRight: 5,
    padding: 5,
  },
  titleWrapper: {
    width: '100%',
    margin: '5px 0',
    padding: '5px 0',
    border: '1px solid',
  },
  chip: {
    margin: '5px',
    width: 'auto'
  },
});

class FooterSettings extends React.Component {

  state = {
      google_plus_link: '',
      instagram_link: '',
      linked_in_link: '',
      facebook_link: '',
      twitter_link: '',
      youtube_link: '',

      errors: [],

      departments: [],
      department: ' ',

      allTelegramBots: [],
      telegram_bots: [],
      currentBot: '',
  };

  componentDidMount() {
    this.getDepartments();
    this.getTelegramBots();
    this.getFooterData();
  }

  getFooterData = async () => {
    const footer = await fetchOne({ url: `${CORE_URL}/footer-settings` });
    this.setState({ ...footer,  department: footer.department.id});
  };

  getDepartments = async () => {
    const departments = await fetchOne({ url: `${DEPARTMENTS_PATH}?isActive=true` });
    this.setState({
        departments,
    });
  };

  getTelegramBots = async () => {
    let allTelegramBots = await fetchOne({ url: `${CORE_URL}/telegram-bots` });
    this.setState({
      allTelegramBots,
    });
  };

  submitMenu = async () => {
    const {
      google_plus_link,
      instagram_link,
      linked_in_link,
      facebook_link,
      twitter_link,
      youtube_link,
      telegram_bots,
      department
    } = this.state;

    const body = JSON.stringify({
      google_plus_link,
      instagram_link,
      linked_in_link,
      facebook_link,
      twitter_link,
      youtube_link,
      telegram_bots,
      department
    });

    const update = await fetchOne({
      url: `${CORE_URL}/footer-settings`,
      method: 'PUT',
      body,
    });

    if (update.status === 201) this.getFooterData();
  };

  handleChange = name => ({ target }) =>
    this.setState({ [name]: target.value });

  handleChangeSelect = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeSelectBot = async event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    if (!this.state.telegram_bots.some(bot => bot.id === event.target.value.id)) {
      const addBot = await fetchOne({
        url: `${CORE_URL}/footer-settings/telegram-bots`,
        method: 'POST',
        body: JSON.stringify({ id: event.target.value.id}),
      });

      if (addBot && (addBot.status === 201 || addBot.status === 204)) {
        this.setState({telegram_bots: [...this.state.telegram_bots, event.target.value]})
      }
    }
  };

  handleDelete = async (id, event) => {
    // console.log(id);
    const deleteBot = await fetchOne({
      url: `${CORE_URL}/footer-settings/telegram-bots`,
      method: 'DELETE',
      body: JSON.stringify({ id }),
    });

    if (deleteBot && (deleteBot.status === 201 || deleteBot.status === 204)) {
      this.setState({telegram_bots: this.state.telegram_bots.filter(bot => bot.id !== id)})
    }
  };

  handleClose = () => {
    this.setState({
      openDepartment: false,
      openParentDepartment: false,
    });
  };

  render() {
    const { classes } = this.props;
    const {
      google_plus_link,
      instagram_link,
      linked_in_link,
      facebook_link,
      twitter_link,
      youtube_link,
      telegram_bots,
      allTelegramBots,
      currentBot
    } = this.state;

    return (
      <div className="menu-create_wrapper">
        <div className="submit-button_wrapper">
          <Button
            onClick={this.submitMenu}
            variant="contained"
            color="primary"
          >
            <Save style={{ color: '#fff' }} />
          </Button>
        </div>
        <div className="form-wrapper">
          <Paper style={{ padding: 10 }}>
            <Typography variant="subheading" align="left">
              Властивості футера:
            </Typography>
          </Paper>
          <form className={classes.container}
                noValidate autoComplete="off">
            <TextField
              id="standard-google_plus_link"
              label="Google+"
              className={classes.textField}
              value={google_plus_link}
              onChange={this.handleChange('google_plus_link')}
              margin="normal"
            />

            <TextField
              id="standard-instagram_link"
              label="Instagram"
              className={classes.textField}
              value={instagram_link}
              onChange={this.handleChange('instagram_link')}
              margin="normal"
            />

            <TextField
              id="standard-linked_in_link"
              label="LinkedIn"
              className={classes.textField}
              value={linked_in_link}
              onChange={this.handleChange('linked_in_link')}
              margin="normal"
            />

            <TextField
              id="standard-facebook_link"
              label="Facebook"
              className={classes.textField}
              value={facebook_link}
              onChange={this.handleChange('facebook_link')}
              margin="normal"
            />

            <TextField
              id="standard-twitter_link"
              label="Twitter"
              className={classes.textField}
              value={twitter_link}
              onChange={this.handleChange('twitter_link')}
              margin="normal"
            />

            <TextField
              id="standard-youtube_link"
              label="Youtube"
              className={classes.textField}
              value={youtube_link}
              onChange={this.handleChange('youtube_link')}
              margin="normal"
            />

            <FormControl className={classes.formControl}
            style={{marginTop: '35px'}}>
              <InputLabel htmlFor="dep">Системний контакт</InputLabel>
              <Select
                value={this.state.department}
                onChange={this.handleChangeSelect}
                input={<Input name="department" id="dep" />}
              >
                {
                  this.state.departments.map(dep => (
                    <MenuItem key={`dep-${dep.id}`} value={dep.id}>{dep.department_localizations[0].title}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>

            <Typography variant="h5" style={{margin: '25px 20px 0'}}>
              Телеграм боти
            </Typography>

            {/* Телеграм Боты!!! */}

            <FormControl className={classes.formControl} >
              <div style={{marginTop: '35px'}}>
                {
                  telegram_bots.map(bot => {
                    return (
                      <Chip
                        label={bot.telegram_bot_localizations[0].name}
                        onDelete={() => this.handleDelete(bot.id)}
                        className={classes.chip}
                        color="primary"
                        key={`bot-${bot.id}`}
                      />
                    )
                  })
                }
              </div>
            </FormControl>
            {allTelegramBots.length !== telegram_bots.length &&
            <FormControl className={classes.formControl}
                         style={{margin: '25px 0' }}>

              <InputLabel htmlFor="t-bot">Додати телеграм-бота</InputLabel>
              <Select
                value={currentBot}
                onChange={this.handleChangeSelectBot}
                input={<Input name="currentBot" id="t-bot" />}
              >
                {
                  allTelegramBots
                    .map(bot => (
                      <MenuItem key={`bot-${bot.id}`} value={bot}>
                        {bot.telegram_bot_localizations[0].name}
                      </MenuItem>
                  ))
                }
              </Select>
            </FormControl>}

          </form>
        </div>
      </div>
    );
  }
}

export default FooterSettings = withStyles(styles)(FooterSettings);
