import React, {Component} from 'react';
import '../../assets/styles/nav_components/main.scss';
import '../../assets/styles/show.scss';
import {findPartnerTypesList} from "../../actions/common";
import Button from "@material-ui/core/Button";
import fetchOne from "../../services/fetch";
import {GET_LANGUAGES, PARTNERS_PATH} from "../../constants/urls";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {TabContainer} from "../util/TabContainer";
import MenuItem from "@material-ui/core/MenuItem";
import {Delete, Save} from "@material-ui/icons";
import Switch from "@material-ui/core/Switch";
import PartnerLocalization from "../Localization/PartnerLocalization";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import store from "../../store";
import {REQUEST_MESSAGE} from "../../constants/redux";
import ModalWindow from '../../views/layout/ModalWindow';


function createLocalizationsData(arr) {
  const localizationData = {};

  for (let i = 0; i < arr.length; i++) {
    let locId = arr[i].localization.id;

    let locData = {
      id: arr[i].id,
      description: arr[i].description ? arr[i].description : '',
      url: arr[i].url ? arr[i].url : '',
      title: arr[i].title ? arr[i].title : '',
      logo: arr[i].logo ? arr[i].logo : '',
    };
    localizationData[locId] = locData;
  }
  return localizationData;
}

class EditPartner extends Component {
  state = {
      open: false,
      file: '',
      imagePreviewUrl: '',
      downloadedFileId: '',
      fields: {priority: true},
      errors: {},
      languages: [],
      selectedLanguage: 0,
      localizationsData: createLocalizationsData(this.props.object.partner_localizations),
      localizationId: '',
      partner_types: [],
      partner_type: this.props.object.partner_type ? this.props.object.partner_type.id : '',
      priority: this.props.object.priority,
      is_active: this.props.object.is_active,
  };

  async componentDidMount() {
    try {
      const languages = await fetchOne({
        url: GET_LANGUAGES,
      });
      const partner_types = await findPartnerTypesList();
      this.setState({languages, partner_types, loading: false});
    } catch (err) {
      console.log(err.message);
    }
  }

  handleImageChange = (res) => {
    const {languages, selectedLanguage} = this.state;
    this.setState({
      localizationsData: {
        ...this.state.localizationsData,
        [languages[selectedLanguage].id]: {...this.state.localizationsData[languages[selectedLanguage].id], logo: res.id, resize: res.resize }
      }
    });
  };

  handleChangeLanguage = (event, value) => {
    this.setState({selectedLanguage: value});
  };

  changeLocalizationData = (event, id) => {
    const {target: {name, value}} = event;
    this.setState({
      localizationsData: {
        ...this.state.localizationsData,
        [id]: {...this.state.localizationsData[id], [name]: value}
      }
    });

  };

  async handleSubmit(event) {
    event.preventDefault();
    const {localizationsData,
      partner_type,
      is_active,
      priority} = this.state;

    if(!Object.entries(localizationsData).length){
      store.dispatch({
        type: REQUEST_MESSAGE,
        data: {
          message: 'Хоча б одна мова має бути заповнена!',
          type: 'error',
        }
      });
      return false;
    }

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };

      const formData = {
        is_active: is_active,
        priority: parseInt(priority),
        partner_type: partner_type ? partner_type : null,
      };

      try {
        let partnerLoc = '';
        const part = await fetchOne({
          url: `${PARTNERS_PATH}/${this.props.object.id}`,
          method: "PUT",
          body: JSON.stringify(formData),
          headers: headers
        });

        const partner = await part.json();

        for (let [key, value] of Object.entries(localizationsData)) {
          console.log(value);
          let partnerLocalizationData = {
            localization: key,
            description: value.description ? value.description : '',
            url: value.url ? value.url : '',
            logo: value.logo ? (value.logo.id || value.logo) : null,
            title: value.title ? value.title : '',
          };

          if(value.id){
            partnerLoc = await fetchOne({
              url: `${PARTNERS_PATH}/${this.props.object.id}/localizations/${value.id}`,
              method: "PUT",
              body: JSON.stringify(partnerLocalizationData),
              headers: headers
            });

          } else {
            partnerLoc = await fetchOne({
              url: `${PARTNERS_PATH}/${this.props.object.id}/localizations`,
              method: "POST",
              body: JSON.stringify(partnerLocalizationData),
              headers: headers
            });
          }
        }
        const { ...rest } = localizationsData;
        this.setState({localizationsData: { ...rest }});
      } catch (err) {
        console.log(err.message);
      }
  }

  toggleActive = () => this.setState(state => ({is_active: !state.is_active}));

  onChange(e) {
    const {target: {name, value}} = e;
    this.setState(prevState => ({
      fields: {...prevState.fields, [name]: false},
      errors: {...prevState.errors, [name]: false},
      [name]: value
    }));
  }

  // открытие модального окна
  openModal = () => this.setState({ open: true });
  // закрытие (отмена) модального окна по подтверждению удалиения локализации
  handleCancel = () => this.setState({ open: false });

  deleteLocalizationPartner = async (id, localizationId) => {
    const { languages, localizationsData, selectedLanguage } = this.state;

    if(Object.entries(localizationsData).length === 1){
      store.dispatch({
        type: REQUEST_MESSAGE,
        data: {
          message: 'Хоча б одна мова має бути заповнена!',
          type: 'error',
        }
      });
      return false;
    }

    const deleteLocalization = await fetchOne({
      url: `${PARTNERS_PATH}/${id}/localizations/${localizationId}`,
      method: 'DELETE',
    });

    if (deleteLocalization && deleteLocalization.ok) {
      const { [languages[selectedLanguage].id]: deleted, ...rest } = localizationsData;
      this.setState({localizationsData: { ...rest }});
      this.handleCancel();
    } else {
      console.log(deleteLocalization.statusText);
      this.handleCancel();
    }
  };

  render() {
    const {errors, open,
      localizationsData,
      languages,
      selectedLanguage,
      priority,
      partner_type,
      partner_types,
      is_active} = this.state;

    const check = (!!languages[selectedLanguage]
        && !!localizationsData[languages[selectedLanguage].id]
        && !!localizationsData[languages[selectedLanguage].id].id);
    return (
      <React.Fragment>
        <div className="tab-block-content">

          {/* Всплывающее диалоговое окно по удалению локализации */}
          <ModalWindow
            open={open}
            title="Дійсно бажаєте видалити локалізацію?"
            onClose={this.handleCancel}
            onConfirm={() => this.deleteLocalizationPartner(this.props.object.id, localizationsData[languages[selectedLanguage].id].id)}
          />

          <div className="submit-button_block">

            {/* кнопка удалить локализацию */}
            { check &&
            <Tooltip title="Видалити локализацію" placement="left"
                onClick={this.openModal}
            >
              <Button
                      style={{ marginRight: '10px' }} variant="contained"
                      color="secondary">
                <Delete style={{ color: '#fff' }}/>
              </Button>
            </Tooltip>
            }
            {/* конец --- кнопка удалить локализацию */}

            <Button
              onClick={(e) => this.handleSubmit(e)}
              variant="contained"
              color="primary"
            >
              <Save style={{color: '#fff'}}/>
            </Button>
          </div>

          <div className="show-block">
            <div className="partners-show-block-form">
              <Paper style={{ padding: '11px 10px' }}>
                  <Typography variant="subheading" align="left">
                      Властивості партнера:
                  </Typography>
              </Paper>

              <TextField
                autoFocus
                name="priority"
                margin="dense"
                id="priority"
                label="Пріоритет"
                type="input"
                onChange={(e) => this.onChange(e)}
                value={priority}
                error={errors.priority}
                fullWidth
              />

              <TextField
                select
                label="Типи партнерів"
                name="partner_type"
                id="partner_type"
                value={partner_type ? partner_type : ''}
                onChange={(e) => this.onChange(e)}
                fullWidth
              >
                <MenuItem value={null}>
                  Оберіть тип
                </MenuItem>
                {partner_types.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <div className="bottom-submit-form">
                <div>
                  <Switch
                    checked={is_active}
                    onChange={this.toggleActive}
                    color="primary"
                  />
                  <span>{this.state.is_active ? "Активний" : "Heактивний"}</span>
                </div>
              </div>
            </div>

            <div className="show-block-additions">
              {
                languages.length ?
                  <div className="">
                    <AppBar position="static" color="default">
                      <Tabs
                        value={selectedLanguage}
                        onChange={this.handleChangeLanguage}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                      >
                        {
                          languages.map((item, i) =>
                            <Tab label={item.title} key={`teb-lang-${i}`}/>
                          )
                        }
                      </Tabs>
                    </AppBar>
                    {<TabContainer>
                      <PartnerLocalization
                        localizationId={languages[selectedLanguage].id}
                        changeLocalizationData={this.changeLocalizationData}
                        localizationsData={localizationsData}
                        handleImageChange={this.handleImageChange}
                      />
                    </TabContainer>}
                  </div> : ''
              }

            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EditPartner;
