import React, { PureComponent } from 'react';
import Exit from "@material-ui/icons/ExitToApp";
import Button from "@material-ui/core/Button/Button";

import { removeToken } from "../services/auth";
import Typography from "@material-ui/core/Typography/Typography";

class User extends PureComponent {
  logOut = () => {
    removeToken();
    localStorage.removeItem('username');
    this.props.history.push("/login");
  };

  render() {
    const username = localStorage.getItem('username');
    return (
      <React.Fragment>
        <Typography
          variant="subtitle1"
          style={{
            margin: 10,
            fontWeight: 'bold',
          }}
        >
          {username}
        </Typography>
        <Button
          onClick={this.logOut}
          color="secondary"
          variant="contained"
        >
          <Exit style={{ color: '#fff' }} />
          <Typography
            variant="subtitle1"
            style={{
              margin: "0 10px",
              color: '#fff',
            }}
          >
            Log Out
          </Typography>
        </Button>
      </React.Fragment>
    );
  }
}

export default User;
