import { ALL_ACCOUNTS_DOWNLOADED } from '../constants/redux';

const accounts = (state = [], action) => {
  switch (action.type) {
    case ALL_ACCOUNTS_DOWNLOADED:
      return action.customers;
    default:
      return state;
  }
};

export default accounts;
