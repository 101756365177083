import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import '../../assets/styles/categories.scss';

import fetchOne from "../../services/fetch";
import {CORE_URL} from "../../constants/urls";
import {EDIT_ITEM} from '../../constants/types';

import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {Save} from "@material-ui/icons";
import Button from "@material-ui/core/Button/Button";
import Loader from "../util/Loader";
import Paper from "@material-ui/core/Paper/Paper";
import Typography from "@material-ui/core/Typography/Typography";
import CKComponent from "../CKEditor/CKComponent";
import Tooltip from '@material-ui/core/Tooltip';
import Delete from '@material-ui/icons/Delete';
import store from "../../store";
import {REQUEST_MESSAGE} from "../../constants/redux";
import UploadFileComponent from '../util/UploadFileComponent/UploadFileComponent';
import ModalWindow from '../../views/layout/ModalWindow';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '95%',
    },
    dense: {
        marginTop: 20,
    },
    menu: {
        width: '95%',
    },
    formControl: {
        margin: theme.spacing.unit,
        alignItems: 'center',
        width: '95%',
    },
    button: {
        margin: theme.spacing.unit,
    },
});

const PageModel = {
    localization: '',
    translation: '',
};

class CreateSystemTranslation extends React.Component {
    state = {
        prevSysName: '',
        system_name: '',

        content: [],

        languages: [],

        activeTab: 0,

        errors: [],
        updated: [],

        loading: true,

        open: false,
    };

    componentDidMount() {
        this.getLanguages();
    }

    getLanguages = async () => {
        const { id, data, type } = this.props;
        const languages = await fetchOne({ url: `${CORE_URL}/languages` });

        if (type === EDIT_ITEM) {
            const item = data.find(item => id === item.id);
            const { system_name, translations } = item;

            let content = new Array(languages.length)
                .fill(PageModel)
                .map((item, i) => ({
                  ...item,
                  localization: languages[i].id,
                }));

            content = content.map(item => {
                const system = translations
                  .find(el => item.localization === el.localization.id);
                if (system) return {
                  ...item,
                  translation: system.translation,
                  id: system.id,
                };
                return item;
            });

            this.setState({
                prevSysName: system_name,
                system_name,

                updated: content,
                content,

                languages,

                loading: false,
            });
        } else {
            const content = new Array(languages.length)
                .fill(PageModel)
              .map((item, i) => ({
                ...item,
                localization: languages[i].id,
              }));
            this.setState({
              languages,
              content,
              loading: false,
            });
        }
    };

    createPage = async () => {
        try {
            const { prevSysName, system_name, content } = this.state;

            const isEdit = this.props.type === EDIT_ITEM;

            let createPage = {};
            let url = this.props.id
                ? `${CORE_URL}/system-translations/${this.props.id}`
                : `${CORE_URL}/system-translations`;
            let method = isEdit ? 'PUT' : 'POST';

            if (prevSysName !== system_name) {
                const sysTrBody = JSON.stringify({ system_name });
                createPage = await fetchOne({ url, method, body: sysTrBody });
            }

            if (createPage.status === 201 || isEdit) {
                const valid = content.filter(el => el.translation);
                const pageData = this.props.id ? this.props : await createPage.json();
                try {
                    const toCompare = ['translation'];
                    const isUpdated = this.state.updated.map(item => {
                        const destructed = Object.entries(item).reduce((prev, [key, val]) => {
                            const changed = content.find(el => el.localization === item.localization)[key];
                            const condition = (toCompare.indexOf(key) > -1) && changed !== val;
                            return condition ? ({ ...prev, [key]: changed }) : prev;
                        }, {});
                        return {
                            ...destructed,
                            translation_id: item.id,
                            id: item.localization,
                        }
                    });

                    const createLocalization = await Promise.all(valid
                        .map(obj => {
                            const { localization, translation } = obj;

                            let localizationBody;
                            const item = isUpdated.find(el => el.id === localization);

                            if (item && item.translation_id) {
                                const { id, translation_id } = item;
                                url = `${CORE_URL}/system-translations/localizations/${translation_id}`;
                                localizationBody = JSON.stringify({
                                    translation: item.translation || translation,
                                    localization: id,
                                });
                                method = 'PUT';
                            } else {
                                url = `${CORE_URL}/system-translations/localizations`;
                                localizationBody = JSON.stringify({
                                    origin: pageData.id,
                                    localization,
                                    translation,
                                });
                                method = 'POST';
                            }

                            return fetchOne({ url, method, body: localizationBody });
                        }));

                    const isSuccess = createLocalization.some(res => res && (res.status === 201 || res.status === 204));
                    if (isSuccess) this.props.update();
                } catch (error) {
                    console.log(error);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    validateLocalization = () => {
      const { content } = this.state;
      const required = ['translation'];

      const filtered = content.map(item => {
        const entries = Object.entries(item);
        return entries.reduce((prev, [key, val]) => typeof val === 'string' ? { ...prev, [key]: val } : prev, {});
      });

      return filtered.map(item => {
        const entries = Object.entries(item);
        return entries.map(([key, val]) => (required.indexOf(key) > -1) && !val ? key : '');
      });
    };

    submitPage = () => {
        const { system_name, content } = this.state;

        if(!content.filter(l => !!l.translation).length){
            store.dispatch({
                type: REQUEST_MESSAGE,
                data: {
                    message: 'Хоча б одна мова має бути заповнена!',
                    type: 'error',
                }
            });
            return false;
        }

      const errors = this.validateLocalization();
      const isValid = errors.some(arr => arr.length ? arr.every(el => !el) : !arr.length);

      if (system_name && isValid) this.createPage();
      this.setState({ errors, submited: true });
    };

    changeLocalization = name => translation => {
        const { activeTab } = this.state;
        this.setState(state => ({
            content: [
                ...state.content
                    .map((item, index) => index === activeTab
                        ? ({ ...item, [name]: translation })
                        : item)
            ],
        }));
    };

  handleChange = name => ({ target }) => this.setState({ [name]: target.value, submited: false });

  changeTab = (event, value) => this.setState({ activeTab: value });

  // открытие модального окна
  openModal = () => this.setState({ open: true });
  // закрытие (отмена) модального окна по подтверждению удалиения локализации
  handleCancel = () => this.setState({ open: false });

  deleteLocalizationTranslation = async (localizationId) => {

        const {activeTab, content} = this.state;

        if (content.filter(l => !!l.translation).length === 1) {
            store.dispatch({
                type: REQUEST_MESSAGE,
                data: {
                    message: 'Хоча б одна мова має бути заповнена!',
                    type   : 'error',
                }
            });
            return false;
        }

        const deleteLocalization = await fetchOne({
            url   : `${CORE_URL}/system-translations/localizations/${localizationId}`,
            method: 'DELETE',
        });

        if (deleteLocalization && deleteLocalization.ok) {
            this.setState(state => ({
                content: [
                    ...state.content
                        .map((item, index) => index === activeTab
                            ? ({localization: item.localization, translation: ''})
                            : item)
                ],
            }));
            this.handleCancel();
        } else {
            console.log(deleteLocalization.statusText);
          this.handleCancel();
        }
  };

  render() {
    const { classes } = this.props;
    const {
      languages, loading, content,
      activeTab, errors, system_name, open,
    } = this.state;
    const error = errors[activeTab];

    if (loading) return (<Loader/>);

    const dis = content[activeTab] && !content[activeTab].id;

    return (
      <div className="category-create_wrapper">
        <div className="submit-button_wrapper">

            {/* кнопка удалить локализацию */}
            {this.props.type === "EDIT_ITEM" &&
            <Tooltip title="Видалити локализацію" placement="left"
                     onClick={this.openModal}>
                <Button disabled={dis}
                        style={{ marginRight: '10px' }} variant="contained"
                        color="secondary">
                    <Delete style={{ color: '#fff' }}/>
                </Button>
            </Tooltip>
            }
            {/* конец --- кнопка удалить локализацию */}

          <Button
            onClick={this.submitPage}
            variant="contained"
            color="primary"
          >
            <Save style={{ color: '#fff' }} />
          </Button>
        </div>
        <div className="form-wrapper">
          <Paper style={{ padding: '11px 10px' }}>
            <Typography
              variant="subheading"
              align="left"
            >
              Властивості системного перекладу:
            </Typography>
          </Paper>
          <form
            className={classes.container}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="standard-alias"
              label="Системна назва"
              className={classes.textField}
              value={system_name}
              disabled={true}
              error={!system_name && this.state.submited}
              onChange={this.handleChange('system_name')}
              margin="normal"
            />
          </form>
          <UploadFileComponent />
        </div>

        <div className="form-wrapper form-big">
          <AppBar position="static" color="default">
            <Tabs
              value={activeTab}
              onChange={this.changeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              {languages.map(language => (
                <Tab
                  key={`language-tab-${language.id}`}
                  label={language.title}
                />
              ))}
            </Tabs>
          </AppBar>
          {content
            .filter((el, index) => index === activeTab)
            .map((item, index) => (
              <form
                key={`form-item-${index}`}
                className={classes.container}
                noValidate
                autoComplete="off"
              >
                <CKComponent
                  setDescription={this.changeLocalization('translation')}
                  error={error && error.some(el => el === 'translation')}
                  description={item.translation}
                />
              </form>
            ))}
        </div>

        {/* Всплывающее диалоговое окно по удалению локализации */}
        <ModalWindow
          open={open}
          title="Дійсно бажаєте видалити локалізацію?"
          onClose={this.handleCancel}
          onConfirm={() => this.deleteLocalizationTranslation(this.state.content[activeTab].id)}
        />

      </div>
    );
  }
}

export default withStyles(styles)(CreateSystemTranslation);
