import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import {Delete, Edit} from '@material-ui/icons';
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import {PER_PAGE, PER_PAGE_ARR} from "../constants/data";
import {GET_BOOK_SERIES} from "../constants/urls";

import fetchOne from "../services/fetch";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {TabContainer} from "../components/util/TabContainer"
import SeriesLocalization from "./Localization/SeriesLocalization";
import TextField from "@material-ui/core/TextField";
import {formatPublishDate} from "../services/main";

const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
};

function createLocalizationsData(arr) {
  const localizationData = {};
  for (let i = 0; i < arr.length; i++) {
    let locId = arr[i].localization.id;

    let locData = {
      id: arr[i].id,
      title: arr[i].title ? arr[i].title : '',
    };
    localizationData[locId] = locData;
  }
  return localizationData;
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'created_at', numeric: false, sort: true, label: 'Дата створення' },
  { id: 'priority', numeric: true, sort: true, label: 'Пріоритет' },
  { id: 'title', numeric: false, sort: false, label: 'Назва' },
  { id: 'books', numeric: false, sort: false, label: 'Книги' },
  { id: 'actions', numeric: false, sort: false, label: '' },
];

class CustomersTable extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
          </TableCell>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align= 'left'
                padding= 'default'
                sortDirection={orderBy === row.id ? order : false}
              >
                {row.sort ?
                    <Tooltip
                        title="Sort"
                        placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                        enterDelay={300}
                    >
                      <TableSortLabel
                          active={orderBy === row.id}
                          direction={order}
                          onClick={this.createSortHandler(row.id)}
                      >
                        {row.label}
                      </TableSortLabel>
                    </Tooltip> : row.label
                }
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

CustomersTable.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class BookSeriesTableHead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'desc',
      orderBy: 'priority',
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: PER_PAGE,
      openDelete: false,
      openUpdate: false,
      curId: '',
      curObj: {},
      loading: true,
      series: props.series,
      languages: props.languages,
      selectedLanguage: 0,
      localizationsData: {},
      localizationId: ''
    };
    this.onChange = this.onChange.bind(this);
    this.deleteSerie = this.deleteSerie.bind(this);
  }

  componentWillReceiveProps(props) {
    if(props.series && props.languages) {
      this.setState({ series: props.series, languages: props.languages })
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  onChange(e) {
    this.setState({
      curObj: {...this.state.curObj, [e.target.name]: e.target.value},
      errors: {...this.state.errors, [e.target.name]: false},
    });
  }

  onCancel = () => {
    this.setState({openDelete: false, openUpdate: false, selected: [], curId: ''});
  };

  handleClose = () => {
    this.setState({ openDelete: false, openUpdate: false, curId: '', selected: [] });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  async deleteSerie(){
    const {curId, series} = this.state;
    try {
      const rev = await fetchOne({
        url: `${GET_BOOK_SERIES}/${curId}`,
        method: "DELETE",
      });
      if(rev.ok) {
        const data = [...series];
        const index = data.findIndex(obj => obj.id === curId);
        data.splice(index, 1);
        this.setState({openUpdate: false, curId: '', curObj: {}, series: data});
      }
    } catch (err) {
      console.log(err.message);
    }
    this.setState({ openDelete: false, curId: '', selected: [] });
  };

  openDeleteDialog = (id) => {
    this.setState({ openDelete: true, curId: id });
  };

  editSerie = (id, series) => {
    let curObj = {};
    if(series.length){
      curObj = series.filter(item => item.id === id);
    }
    this.setState({openUpdate: true, curObj: curObj[0], localizationsData: createLocalizationsData(curObj[0].book_series_localizations), curId: id});
  };

  changeLocalizationData = (event, id) => {
    const { target: { name, value } } = event;
    this.setState({ localizationsData: {...this.state.localizationsData, [id]: { ...this.state.localizationsData[id], [name]: value }}});
  };

  handleChangeLanguage = (event, value) => {
    this.setState({ selectedLanguage: value });
  };

  async handleSubmit(event) {
    event.preventDefault();
    const {localizationsData, curObj, curId } = this.state;

    const formData = {
      priority: parseInt(curObj.priority),
    };

    try {
      let serieLoc = '';

      // change serie
      const serie = await fetchOne({
        url: `${GET_BOOK_SERIES}/${curId}`,
        method: "PUT",
        body: JSON.stringify(formData),
        headers: headers
      });
      const rserie = await serie.json();

      // change localizations
      for (let [key, value] of Object.entries(localizationsData)) {
        let serieLocalizationData = {
          localization: key,
          title: value.title ? value.title : '',
        };

        if(value.id){
          serieLoc = await fetchOne({
            url: `${GET_BOOK_SERIES}/${curId}/localizations/${value.id}`,
            method: "PUT",
            body: JSON.stringify(serieLocalizationData),
            headers: headers
          });

        } else {
          serieLoc = await fetchOne({
            url: `${GET_BOOK_SERIES}/${curId}/localizations`,
            method: "POST",
            body: JSON.stringify(serieLocalizationData),
            headers: headers
          });
        }
      }
      console.log(rserie);
      console.log(serieLoc);

      if(serie.ok && serieLoc.ok) {
        const data = await fetchOne({url: GET_BOOK_SERIES});
        this.setState({openUpdate: false, curId: '', curObj: {}, series: data});
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  render() {
    const { classes } = this.props;
    const { order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      series,
      curObj,
      openUpdate,
      languages,
      selectedLanguage,
      localizationsData,} = this.state;

    console.log(this.state);
    return (
      <Paper className={classes.root}>

        {/**Update modal**/}
        <div>
          <Dialog
            open={openUpdate}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth={"xl"}
          >
            <DialogContent>
              <DialogContentText>
                Редагувати серію
              </DialogContentText>

              <div className="show-block">
                <div className="show-block-form">
                  <div className="bottom-submit-form">
                    <div>
                      <TextField
                          autoFocus
                          name="priority"
                          margin="dense"
                          id="priority"
                          label="Пріоритет"
                          type="input"
                          onChange={this.onChange}
                          value={curObj.priority}
                          error={false}
                          fullWidth
                      />
                    </div>
                  </div>
                </div>

                <div className="show-block-additions">
                  {
                    languages.length ?
                      <div className="">
                        <AppBar position="static" color="default">
                          <Tabs
                            value={selectedLanguage}
                            onChange={this.handleChangeLanguage}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                          >
                            {
                              languages.map((item, i) =>
                                <Tab label={item.title} key={`teb-lang-${i}`}/>
                              )
                            }
                          </Tabs>
                        </AppBar>
                        {<TabContainer>
                          <SeriesLocalization
                            localizationId={languages[selectedLanguage].id}
                            changeLocalizationData={this.changeLocalizationData}
                            localizationsData={localizationsData}
                          />
                        </TabContainer>}
                      </div> : ''
                  }

                </div>
              </div>

            </DialogContent>
            <DialogActions>
              {Object.keys(localizationsData).length && undefined !== Object.values(localizationsData).find(l => l.title.length > 0) ?
                  <Button onClick={(e) => this.handleSubmit(e)} color="primary">
                    Змінити
                  </Button> :
                  <DialogContentText>
                    Має бути заповнена назва хоча б в 1 мові
                  </DialogContentText>
              }
              <Button onClick={this.handleClose} color="primary">
                Відмінити
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        {/**Delete modal**/}
        <div>
          <Dialog
            open={this.state.openDelete}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogContent>
              <DialogContentText>
                Ви впевнені, що хочете видалити цей запис?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.deleteSerie} color="primary">
                Видалити
              </Button>
              <Button onClick={this.onCancel} color="primary">
                Відмінити
              </Button>
            </DialogActions>
          </Dialog>
        </div>


        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <CustomersTable
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={series.length}
            />
            <TableBody>
              {stableSort(series, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, n.id)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell padding="checkbox">
                      </TableCell>
                      <TableCell align="left">{formatPublishDate(n.created_at)}</TableCell>
                      <TableCell align="left">{n.priority}</TableCell>
                      <TableCell align="left">{n.book_series_localizations[0] ? n.book_series_localizations[0].title : ''}</TableCell>
                      <TableCell align="left">
                        {n.books.map((s, i) => <div key={`series-content-${i}`}>
                          <p>{s.book_localizations.length ? s.book_localizations[0].title : ''}</p>
                          <br/></div>)}
                      </TableCell>
                      <TableCell align="left">
                        <Button onClick={() => this.openDeleteDialog(n.id)} color="primary">
                          <Delete style={{ color: '#afafaf' }}/>
                        </Button>
                        <Button onClick={() => this.editSerie(n.id, series)} color="primary">
                          <Edit style={{ color: '#afafaf' }}/>
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={PER_PAGE_ARR}
          component="div"
          count={series.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Попередня',
          }}
          nextIconButtonProps={{
            'aria-label': 'Наступна',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          labelRowsPerPage='Рядків на сторінку:'
          labelDisplayedRows= {({ from, to, count }) => `${from}-${to} з ${count}`}
        />
      </Paper>
    );
  }
}

export default withStyles(styles)(BookSeriesTableHead);