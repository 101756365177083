import React, { Component } from 'react';

import {CREATE_ITEM, EDIT_ITEM} from '../constants/types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import AllSystemContacts from "../components/systemContacts/AllSystemContacts";
import CreateSystemContact from "../components/systemContacts/CreateSystemContact";
import fetchOne from "../services/fetch";
import {CORE_URL, DEPARTMENTS_PATH} from "../constants/urls";
import ModalWindow from './layout/ModalWindow';

const params = [
  { name: "Всі системні контакти" },
  { name: "Створити системний контакт" },
];

const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
};

class SystemContacts extends Component {
  state = {
      type: CREATE_ITEM,
      open: false,
      section: 0,
      id: '',

      data: [],
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const data = await fetchOne({ url: `${CORE_URL}/departments?limit=100` });
    this.setState({ data, id: '' });
  };

  updateData = () => {
    this.setSection(0);
    this.getData();
  };

  setSection = section => this.setState({ type: CREATE_ITEM, section });

  setEdit = (id, type) => this.setState({ id, type, section: 1 });

  deleteItem = async () => {
    const { id } = this.state;
    try {
      const deleted = await fetchOne({
        url: `${DEPARTMENTS_PATH}/${id}`,
        method: 'DELETE',
        headers,
      });

      this.setState({ open: false, id: '' });
      if (deleted.status === 204) this.getData();
    } catch (err) {
      this.setState({ open: false, id: '' });
    }
  };

  openModal = id => this.setState({ id, open: true });

  handleClose = () => this.setState({ open: false });

  handleCancel = () => this.setState({ open: false });

  render() {
    const {
      section, data, type, open, id,
    } = this.state;

    return (
      <div className="user-content">
        <div className="user-tabs-wrapper">
          <div className="tab-line">
            {params.map((item, index) => (
              <div
                className={section === index ? 'tab-item tab-active' : 'tab-item'}
                key={`nav-item-${item.name}`}
                onClick={() => this.setSection(index)}
              >
                <div className="tab-item-block">
                  {(index && type === EDIT_ITEM) ? 'Редагування системного контакту' : item.name}
                </div>
              </div>
            ))}
          </div>
        </div>

        {section === 0 && (
          <AllSystemContacts
            data={data}
            openModal={this.openModal}
            onEdit={this.setEdit}
          />
        )}
        {section === 1 && (
          <CreateSystemContact
            id={id}
            type={type}
            update={this.updateData}
          />
        )}

        <ModalWindow
          open={open}
          title="Видалити системний контакт?"
          onClose={this.handleClose}
          onConfirm={this.deleteItem}
        />

      </div>
    );
  }
}

export default SystemContacts;
