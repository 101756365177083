
import {
  ALL_EMPLOYEES_DOWNLOADED,
} from '../constants/redux';

const initialState = [];

const employees = (state = initialState, action) => {
  switch (action.type) {
    case ALL_EMPLOYEES_DOWNLOADED:
      return action.payload;
    default:
      return state;
  }
};

export default employees;