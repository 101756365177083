import React, { Component } from 'react';

import {CREATE_ITEM, EDIT_ITEM} from '../constants/types';

import fetchOne from "../services/fetch";
import { CORE_URL } from "../constants/urls";

import AllMenus from "../components/menus/AllMenus";
import CreateMenu from "../components/menus/CreateMenu";
import Loader from "../components/util/Loader";
import ModalWindow from './layout/ModalWindow';

const params = [
  { name: "Всі пункти меню" },
  { name: "Створити пункт меню" },
];

const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
};

class Menus extends Component {
  state = {
      type: CREATE_ITEM,
      open: false,
      section: 0,
      id: '',

      data: [],
      selected: [],

      loading: true,
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const data = await fetchOne({ url: `${CORE_URL}/menus?limit=100` });
    this.setState({
      type: CREATE_ITEM,
      selected: [],
      data,
      id: '',
      loading: false,
    });
  };

  updateData = () => {
    this.setSection(0);
    this.getData();
  };

  setSection = section => this.setState({ type: CREATE_ITEM, section });

  setEdit = (id, type) => this.setState({ id, type, section: 1 });

  deleteItem = async () => {
    const { id } = this.state;
    try {
      const deleted = await fetchOne({
        url: `${CORE_URL}/menus/${id}`,
        method: 'DELETE',
        headers,
      });

      this.setState({
        type: CREATE_ITEM,
        open: false,
        selected: [],
        id: '',
      });
      if (deleted.status === 204) this.getData();
    } catch (err) {
      this.setState({
        type: CREATE_ITEM,
        open: false,
        selected: [],
        id: '',
      });
    }
  };

  openModal = (id, arr) => {
    this.setState({
      selected: arr || [],
      id: id || '',
      open: true,
    });
  };

  handleClose = () => this.setState({ open: false });

  handleCancel = () => this.setState({ open: false });

  render() {
    const {
      loading, section,
      data, type, open, id,
    } = this.state;

    if (loading) return (<Loader/>);

    return (
      <div className="user-content">
        <div className="user-tabs-wrapper">
          <div className="tab-line">
            {params.map((item, index) => (
              <div
                className={section === index ? 'tab-item tab-active' : 'tab-item'}
                key={`nav-item-${item.name}`}
                onClick={() => this.setSection(index)}
              >
                <div className="tab-item-block">
                  {(index && type === EDIT_ITEM) ? 'Редагування пункту меню' : item.name}
                </div>
              </div>
            ))}
          </div>
        </div>

        {section === 0 && (
          <AllMenus
            data={data}
            openModal={this.openModal}
            onEdit={this.setEdit}
          />
        )}
        {section === 1 && (
          <CreateMenu
            id={id}
            type={type}
            data={data}
            update={this.updateData}
          />
        )}

        <ModalWindow
          open={open}
          title="Видалити пункт меню?"
          onClose={this.handleClose}
          onConfirm={() => {
            if (id) {
              this.deleteItem();
            } else {
              this.deleteMultiple();
            }
          }}
        />

      </div>
    );
  }
}

export default Menus;
