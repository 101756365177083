import React, { Component } from 'react';
import '../../assets/styles/nav_components/main.scss';
import '../../assets/styles/autocomplete.scss';
import TextField from '@material-ui/core/TextField';
import { fillValue } from '../../actions/common';
import Typography from "@material-ui/core/Typography";
import DropZone from "../util/DropZone";
import FormControl from "@material-ui/core/FormControl";
import {DEPARTMENT_IMAGE_HEIGHT, DEPARTMENT_IMAGE_WIDTH} from "../../constants/imageSizes";

class PartnerLocalization extends Component {

  render() {
    const {
      localizationId,
      localizationsData,
    } = this.props;

    const logo = fillValue(localizationId, localizationsData, 'logo');
    const resize = fillValue(localizationId, localizationsData, 'resize');

    const djsConfig = { params: {width: DEPARTMENT_IMAGE_WIDTH, height: DEPARTMENT_IMAGE_HEIGHT}};
    return (
      <div className="">
        <TextField
          name="title"
          margin="dense"
          id="title"
          label="Назва"
          type="input"
          onChange={e => this.props.changeLocalizationData(e, localizationId)}
          value={fillValue(localizationId, localizationsData, 'title')}
          error={false}
          fullWidth
        />

        <FormControl>
            <Typography variant="subheading" align="left">
                Превʼю
            </Typography>
            {logo && (
                <img
                    style={{ marginBottom: 5 }}
                    src={logo.resize || logo.href}
                    alt=""
                />
            )}
            {resize && (
              <img
                style={{ marginBottom: 5 }}
                src={resize}
                alt=""
              />
            )}
            <DropZone removeFile handler={this.props.handleImageChange} djsConfig={djsConfig} />
        </FormControl>

        <TextField
          autoFocus
          name="url"
          margin="dense"
          id="url"
          label="Посилання"
          type="input"
          onChange={e => this.props.changeLocalizationData(e, localizationId)}
          value={fillValue(localizationId, localizationsData, 'url')}
          error={false}
          fullWidth
        />

        <div className="description-block">
          <span>Опис</span>
          <TextField
            name="description"
            id="description"
            multiline={true}
            rows={8}
            rowsMax={8}
            variant="outlined"
            margin="normal"
            onChange={e => this.props.changeLocalizationData(e, localizationId)}
            value={fillValue(localizationId, localizationsData, 'description')}
            fullWidth
          />
        </div>

      </div>
    );
  }
}

export default PartnerLocalization;
