import React, { Component } from 'react';

import { CREATE_ITEM, EDIT_ITEM } from '../constants/types';
import { CORE_URL } from "../constants/urls";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import fetchOne from '../services/fetch';

import AllSystemTranslations from '../components/systemTranslations/AllSystemTranslations';
import CreateSystemTranslation from '../components/systemTranslations/CreateSystemTranslation';

const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
};

class SystemTranslations extends Component {
    state = {
        type: CREATE_ITEM,
        open: false,
        section: 0,
        id: '',

        data: [],
    };

    componentDidMount() {
        this.getData();
    }

    // начальная инициализация "Системні переклади", GET, /system-translations
    getData = async () => {
        const data = await fetchOne({ url: `${CORE_URL}/system-translations/localizations?limit=100` });

        const merged = data.reduce((prev, item) => {
          const { origin, localization, translation } = item;
          const { system_name, id } = origin;
          return {
            ...prev,
            [system_name]:  {
              ...(prev[system_name] && prev[system_name]),
              translations: prev[system_name] && prev[system_name].translations
                ? [...prev[system_name].translations, { origin, localization, translation, id: item.id }]
                : [{ origin, localization, translation, id: item.id }],
              system_name,
              id,
            }
          }
        }, {});
        const values = Object.values(merged);

        this.setState({
            type: CREATE_ITEM,
            selected: [],
            data: values,
            id: '',
        });
    };

    updateData = () => {
        this.setSection(0);
        this.getData();
    };

    setSection = section => this.setState({ type: CREATE_ITEM, section });

    setEdit = (id, type) => this.setState({ id, type, section: 1 });

    render() {
        const { section, data, type, id } = this.state;

        // названия секций для подзголовка
        const params = [
            { name: "Всі системні переклади" },
            { name: "Редагування системного перекладу" }
        ];

        return (
            <div className="user-content">
                {/* подзаголовок с названиями секций, всі сторінкі, редагувати.... */}
                <div className="user-tabs-wrapper">
                    <div className="tab-line">
                        {params.map((item, index) => (
                            <div
                                className={section === index ? 'tab-item tab-active' : 'tab-item'}
                                key={`nav-item-${item.name}`}
                                onClick={() => this.setSection(index)}
                            >
                                <div className={index && type === CREATE_ITEM ? 'tab-item-block tab-none' : 'tab-item-block'}>
                                    {(index && type === EDIT_ITEM) ? 'Редагування системного перекладу' : item.name}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* Конец подзаголовка */}

                {/* Основная секция, либо список страниц, либо создание/редактирование */}
                {section === 0 && (
                    <AllSystemTranslations
                        data={data}
                        onEdit={this.setEdit}
                    />
                )}

                {section === 1 && (
                    <CreateSystemTranslation
                        id={id}
                        type={type}
                        data={data}
                        update={this.updateData}
                    />
                )}
                {/* Конец - основной секции */}
            </div>
        );
    }
}

export default SystemTranslations;
