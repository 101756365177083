import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import './index.scss';

import store from './store';
import App from './App';

const render = Component => ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Component />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

render(App);
//
// if (module.hot) {
//   module.hot.accept('./App', () => {
//     const NextApp = require('./App').default;
//     render(NextApp);
//   });
// }
