export const CORE_URL = 'https://backend.sstc.com.ua/api/v1'; // process.env.NODE_ENV !== 'development' ? 'http://sstc-backend.cosmonova.corp/api/v1' : '';
export const AUTH_URL = 'https://backend.sstc.com.ua/oauth/accounts/token';
export const FRONT_URL = 'https://www.sstc.com.ua';
export const BACK_URL = 'https://backend.sstc.com.ua';
export const GET_ALL_ACCOUNTS = `${CORE_URL}/accounts`;
export const GET_ALL_BANNERS = `${CORE_URL}/banners`;
export const GET_ALL_NEWS = `${CORE_URL}/news`;
export const GET_ALL_EDITIONS = `${CORE_URL}/books`;
export const GET_FILESYSTEM = `${CORE_URL}/filesystem`;
export const GET_LANGUAGES = `${CORE_URL}/languages`;
export const FILES_PATH = `${CORE_URL}/files`;
export const TAGS_PATH = `${CORE_URL}/tags`;
export const DEPARTMENTS_PATH = `${CORE_URL}/departments`;
export const EMPLOYEES_PATH = `${CORE_URL}/employees`;
export const GET_ORG_STRUCTURE = `${CORE_URL}/nodes`;
export const GET_SYSTEM_PAGES = `${CORE_URL}/pages`;
export const GET_BOOK_REVIEWS = `${CORE_URL}/book-reviews`;
export const GET_BOOK_ORDERS = `${CORE_URL}/book-orders`;
export const GET_BOOK_SERIES = `${CORE_URL}/book-series`;
export const PARTNERS_PATH = `${CORE_URL}/partners`;
export const PARTNER_TYPES_PATH = `${CORE_URL}/partner-types`;
export const CONTACTS_PATH = `${CORE_URL}/contacts`;
