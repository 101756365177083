import React from 'react';
import LogoDark from '../../assets/svg/sstcnrs-logo-rgb.svg';

const Logo = () => (
  <div className="logo-container">
    <img className="" src={LogoDark} alt="" />
  </div>
);

export default Logo;
