import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import '../../assets/styles/nav_components/main.scss';

import { FRONT_URL } from '../../constants/urls';
import { EDIT_ITEM } from '../../constants/types';
import { PER_PAGE, PER_PAGE_ARR } from "../../constants/data";

import { generateURL } from '../../services/main';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Button from "@material-ui/core/Button/Button";
import Paper from '@material-ui/core/Paper';

import { Cancel, Delete, Done, Edit } from "@material-ui/icons";

import EnhancedTableHead from '../util/EnhancedTableHead';

import {sortLocalizationByPriority, tableHeadSort} from '../../services/sort';

const desc = tableHeadSort('menu');


function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'priority', numeric: false, disablePadding: true, label: 'Пріоритет', sort: true },
  { id: 'searchable', numeric: false, disablePadding: true, label: 'Відображення в пошуку', sort: true },
  { id: 'type', numeric: false, disablePadding: true, label: 'Тип', sort: true },
  { id: 'alias', numeric: false, disablePadding: true, label: 'Посилання', sort: false },
  { id: 'title', numeric: false, disablePadding: false, label: 'Заголовок', sort: true },
  { id: 'languages', numeric: false, disablePadding: false, label: 'Мови', sort: true },
  { id: 'children', numeric: false, disablePadding: false, label: 'Підпункти', sort: true },
];

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  ulWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    listStyleType: 'none',
    padding: '3px 0',
  },
  liItem: {
    border: '1px solid',
    marginBottom: 3,
    borderRadius: 3,
    marginRight: 3,
    padding: 3,
  },
});

const translateType = (type) => {
  switch (type) {
    case 1:
      return 'Посилання';
    default:
      return 'Сторінка';
  }
};

class AllMenus extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'priority',
    selected: [],

    page: 0,
    rowsPerPage: PER_PAGE,

    data: this.props.data,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data !== state.data) {
      return {
        selected: [],
        data: props.data,
      };
    }
    return null;
  }

  goToCreate = type => this.props.onCreate(type);

  goToEdit = (id, type) => this.props.onEdit(id, type);

  deleteItem = id => this.props.openModal(id);

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => this.setState({ page });

  handleChangeRowsPerPage = event => this.setState({ rowsPerPage: event.target.value });

  render() {
    const { classes } = this.props;
    const { data, order, orderBy, rowsPerPage, page } = this.state;

    return (
      <div className="tab-block-content no-shadow">
        {!!data.length && (
          <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
              >
                <EnhancedTableHead
                  rows={rows}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                />
                <TableBody>
                  {stableSort(data, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(n => {

                      const udtUrl = n.url || '';
                      let updHref = null;
                      if (udtUrl.indexOf('http', 0) < 0) {
                        updHref = `${FRONT_URL}${generateURL(n)}`;
                      } else {
                        updHref = `${generateURL(n)}`;
                      }

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          key={n.id}
                        >
                          <TableCell>
                            {n.priority}
                          </TableCell>
                          <TableCell>
                            {n.searchable ? (<Done />) : (<Cancel />)}
                          </TableCell>
                          <TableCell>
                            {translateType(n.type)}
                          </TableCell>
                          <TableCell>
                            <a href={updHref}>
                              {generateURL(n)}
                            </a>
                          </TableCell>
                          <TableCell>
                            {!!n.menu_localizations.length
                              && sortLocalizationByPriority(n.menu_localizations)[0].title}
                          </TableCell>
                          <TableCell>{n.menu_localizations.length}</TableCell>
                          <TableCell>
                            <ul className={classes.ulWrapper}>
                              {!!n.children.length && n.children.map(item => (
                                <li
                                  key={`menu-children-item-${n.id}-${item.id}`}
                                  className={classes.liItem}
                                >
                                  {item.menu_localizations[0] && sortLocalizationByPriority(item.menu_localizations)[0].title}
                                </li>
                              ))}
                            </ul>
                          </TableCell>
                          <TableCell padding="checkbox">
                            <Button onClick={() => this.deleteItem(n.id)} color="primary">
                              <Delete style={{ color: '#afafaf' }} />
                            </Button>
                          </TableCell>
                          <TableCell padding="checkbox">
                            <Button onClick={() => this.goToEdit(n.id, EDIT_ITEM)} color="primary">
                              <Edit style={{ color: '#afafaf' }} />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={PER_PAGE_ARR}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Попередня сторінка',
              }}
              nextIconButtonProps={{
                'aria-label': 'Наступна сторінка',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              labelRowsPerPage='Рядків на сторінку:'
              labelDisplayedRows= {({ from, to, count }) => `${from}-${to} з ${count}`}
            />
          </Paper>
        )}
      </div>
    );
  }
}

export default AllMenus = withStyles(styles)(AllMenus);