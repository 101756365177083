import React, { Component } from 'react';
import '../../assets/styles/nav_components/main.scss';
import '../../assets/styles/show.scss';
import {allFalse, findPartnerTypesList} from "../../actions/common";
import Button from "@material-ui/core/Button";
import fetchOne from "../../services/fetch";
import {GET_LANGUAGES, PARTNERS_PATH} from "../../constants/urls";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {TabContainer} from "../util/TabContainer";
import MenuItem from "@material-ui/core/MenuItem";
import {Save} from "@material-ui/icons";
import Switch from "@material-ui/core/Switch";
import PartnerLocalization from "../Localization/PartnerLocalization";
import store from "../../store";
import {REQUEST_MESSAGE} from "../../constants/redux";

class CreatePartner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      file: '',
      fields: {priority: true},
      errors: {},
      languages: [],
      selectedLanguage: 0,
      localizationsData: {},
      localizationId: '',
      partner_types: [],
      partner_type: '',
      priority: 0,
      is_active: false,
    };
  }

  async componentDidMount() {
    try {
      const languages = await fetchOne({
        url: GET_LANGUAGES,
      });
      const partner_types = await findPartnerTypesList();
      this.setState({languages, partner_types, loading: false});
    } catch (err) {
      console.log(err.message);
    }
  }

  handleImageChange = (res) => {
    const {languages, selectedLanguage} = this.state;
    this.setState({
      localizationsData: {
        ...this.state.localizationsData,
        [languages[selectedLanguage].id]: {...this.state.localizationsData[languages[selectedLanguage].id], logo: res.id, resize: res.resize }
      }
    });
  };

  handleChangeLanguage = (event, value) => {
    this.setState({selectedLanguage: value});
  };

  changeLocalizationData = (event, id) => {
    const {target: {name, value}} = event;
    this.setState({
      localizationsData: {
        ...this.state.localizationsData,
        [id]: {...this.state.localizationsData[id], [name]: value}
      }
    });
  };

  async handleSubmit(event) {
    event.preventDefault();
    const {
      localizationsData,
      fields,
      partner_type,
      is_active,
      priority} = this.state;

    if(!Object.entries(localizationsData).length){
      store.dispatch({
        type: REQUEST_MESSAGE,
        data: {
          message: 'Хоча б одна мова має бути заповнена!',
          type: 'error',
        }
      });
      return false;
    }

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };
    if (allFalse(fields)) {
      const formData = {
        is_active: is_active,
        priority: parseInt(priority),
        partner_type: partner_type ? partner_type : null,
      };

      try {
        let partnerLoc = '';
        const part = await fetchOne({
          url: PARTNERS_PATH,
          method: "POST",
          body: JSON.stringify(formData),
          headers: headers
        });

        const partner = await part.json();

        for (let [key, value] of Object.entries(localizationsData)) {
          let partnerLocalizationData = {
            localization: key,
            description: value.description ? value.description : '',
            url: value.url ? value.url : '',
            logo: value.logo && (value.logo.id || value.logo),
            title: value.title ? value.title : '',
          };

          partnerLoc = await fetchOne({
            url: `${PARTNERS_PATH}/${partner.id}/localizations`,
            method: "POST",
            body: JSON.stringify(partnerLocalizationData),
            headers: headers
          });
        }
        const { ...rest } = localizationsData;
        this.setState({localizationsData: { ...rest }});
      } catch (err) {
        console.log(err.message);
      }
    } else {
      this.setState({errors: fields});
      console.log(fields);
    }
  }

  toggleActive = () => this.setState(state => ({is_active: !state.is_active}));

  onChange(e) {
    const {target: {name, value}} = e;
    this.setState(prevState => ({
      fields: {...prevState.fields, [name]: false},
      errors: {...prevState.errors, [name]: false},
      [name]: value
    }));
  }

  render() {
    const {errors,
      localizationsData,
      languages,
      selectedLanguage,
      priority,
      partner_type,
      partner_types,
      is_active} = this.state;

    return (
      <React.Fragment>
        <div className="tab-block-content">

          <div className="submit-button_block">
            <Button
              onClick={(e) => this.handleSubmit(e)}
              variant="contained"
              color="primary"
            >
              <Save style={{color: '#fff'}}/>
            </Button>
          </div>

          <div className="show-block">
            <div className="partners-show-block-form">
                <TextField
                  autoFocus
                  name="priority"
                  margin="dense"
                  id="priority"
                  label="Пріоритет"
                  type="input"
                  onChange={(e) => this.onChange(e)}
                  value={priority}
                  error={errors.priority}
                  fullWidth
                />

              <TextField
                select
                label="Типи партнерів"
                name="partner_type"
                id="partner_type"
                value={partner_type ? partner_type : ''}
                onChange={(e) => this.onChange(e)}
                fullWidth
              >
                <MenuItem value={null}>
                  Оберіть тип
                </MenuItem>
                {partner_types.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <div className="bottom-submit-form">
                <div>
                  <Switch
                    checked={is_active}
                    onChange={this.toggleActive}
                    color="primary"
                  />
                  <span>{this.state.is_active ? "Активний" : "Heактивний"}</span>
                </div>
              </div>
            </div>

            <div className="show-block-additions">
              {
                languages.length ?
                  <div className="">
                    <AppBar position="static" color="default">
                      <Tabs
                        value={selectedLanguage}
                        onChange={this.handleChangeLanguage}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                      >
                        {
                          languages.map((item, i) =>
                            <Tab label={item.title} key={`teb-lang-${i}`}/>
                          )
                        }
                      </Tabs>
                    </AppBar>
                    {<TabContainer>
                      <PartnerLocalization
                        localizationId={languages[selectedLanguage].id}
                        changeLocalizationData={this.changeLocalizationData}
                        localizationsData={localizationsData}
                        handleImageChange={this.handleImageChange}
                      />
                    </TabContainer>}
                  </div> : ''
              }

            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CreatePartner;
