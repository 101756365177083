import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import {updateCustomer} from "../actions/accounts";
import { fetchEditions, delEdition } from "../actions/editions"
import {Cancel, Delete, Done, Edit} from '@material-ui/icons';
import Button from "@material-ui/core/Button";
import { validateEmail } from "../actions/common";
import Loader from "./util/Loader";

import {ranges} from "../constants/main";
import {PER_PAGE, PER_PAGE_ARR} from "../constants/data";
import {formatPublishDate} from "../services/main";
import { GET_ALL_EDITIONS } from "../constants/urls";
import ModalWindow from '../views/layout/ModalWindow';

function createEditionsData(editions, order = 0) {
  let data = [];

  if(editions.collection && editions.collection.length){

      editions.collection.forEach(function (item, i, arr) {
          let value = item;
          value.image = item.photo ? item.photo.href : '';
          value.title = '';
          if (item.book_localizations.length) {
              value.title = item.book_localizations[order].title;
          }
          data.push(value);
    });
  }
  return data;
}

const rows = [
  { id: 'title', numeric: false, sort: false, label: 'Назва' },
  { id: 'published_at', numeric: false, sort: true, label: 'Початок публікації' },
  { id: 'published_end_at', numeric: false, sort: true, label: 'Кінець публікації' },
  { id: 'is_active', numeric: false, sort: true, label: 'Статус' },
  { id: 'type', numeric: false, sort: true, label: 'Тип' },
  { id: 'image', numeric: false, sort: false, label: 'Фото' },
  { id: 'alias', numeric: false, sort: false, label: 'Alias' },
  { id: 'tags', numeric: false, sort: false, label: 'Теги' },
  { id: 'actions', numeric: false, sort: false, label: '' },
];

class CustomersTable extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {order, orderBy} = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
          </TableCell>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align= 'left'
                padding= 'default'
                sortDirection={orderBy === row.id ? order : false}
              >
                  {
                      row.sort ?
                          <Tooltip
                              title="Sort"
                              placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                              enterDelay={300}
                          >
                              <TableSortLabel
                                  active={orderBy === row.id}
                                  direction={order}
                                  onClick={this.createSortHandler(row.id)}
                              >
                                  {row.label}
                              </TableSortLabel>
                          </Tooltip> : row.label
                  }
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

CustomersTable.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  ulWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    listStyleType: 'none',
    padding: '3px 0',
  },
  liItem: {
    border: '1px solid',
    marginBottom: 3,
    borderRadius: 3,
    marginRight: 3,
    padding: 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class EditionsTableHead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'published_at',
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: PER_PAGE,
      openDelete: false,
      curId: '',
      curObj: {},
      loading: true,
    };
    this.onChange = this.onChange.bind(this);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
  }

  componentDidMount() {
    this.props.getEditions(this.state.page, this.state.rowsPerPage, this.state.order, this.state.orderBy);
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.props.getEditions(this.state.page, this.state.rowsPerPage, order, orderBy);
    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.props.getEditions(page, this.state.rowsPerPage, this.state.order, this.state.orderBy);
    this.setState({ page: page });
  };

  handleChangeRowsPerPage = event => {
    this.props.getEditions(this.state.page, event.target.value, this.state.order, this.state.orderBy);
    this.setState({ rowsPerPage: event.target.value });
  };

  onChange(e) {
    if (e.target.value.trim() !== '') {
      if (e.target.name === 'email') {
        if (validateEmail(e.target.value)) {
          this.setState({
            curObj: { ...this.state.curObj, [e.target.name]: e.target.value },
            errors: { ...this.state.errors, [e.target.name]: false },
          });
        } else {
          this.setState({ curObj: { ...this.state.curObj, [e.target.name]: this.state.curObj.email} });
        }
      } else {
        this.setState({
          curObj: { ...this.state.curObj, [e.target.name]: e.target.value },
          errors: { ...this.state.errors, [e.target.name]: false },
        });
      }
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  onChangeCheckbox() {
    this.setState(prevState => ({
      curObj: {
        ...prevState.curObj,
        is_active: true
      }
    }));
  }

  onCancel =() => {
    this.setState({openDelete: false, selected: [], curId: ''});
  };

  handleClose = () => {
    this.setState({ openDelete: false, curId: '', selected: [] });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  deleteEdition = () => {
    this.props.removeEdition(this.state.curId, this.state.page, this.state.rowsPerPage, this.state.order, this.state.orderBy);
    this.setState({ openDelete: false, curId: '', selected: [] });
  };

  openDeleteDialog = (id) => {
    this.setState({ openDelete: true, curId: id });
  };

  editEdition(id, editions) {
    const obj = editions.filter(ch => ch.id === id);
    this.props.toEdit(obj);
  }

  render() {
    const { classes, editions } = this.props;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const editionsData = createEditionsData(editions);

    if (!editionsData.length) return (<Loader />);

    return (
      <Paper className={classes.root}>

        <ModalWindow
          open={this.state.openDelete}
          title="Ви впевнені, що хочете видалити цей запис?"
          onClose={this.handleClose}
          onConfirm={this.deleteEdition}
        />

        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <CustomersTable
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={editionsData.length}
            />
            <TableBody>
              {editionsData.map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, n.id)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell padding="checkbox">
                      </TableCell>
                      <TableCell align="left">{n.title}</TableCell>
                      <TableCell align="left">{n.published_at ? formatPublishDate(n.published_at) : ''}</TableCell>
                      <TableCell align="left">{n.published_end_at ? formatPublishDate(n.published_end_at) : ''}</TableCell>
                      <TableCell align="left">{n.is_active ? (<Done />) : (<Cancel />)}</TableCell>
                      <TableCell align="left">{n.type ? ranges.find(r => r.value === n.type).label : ''}</TableCell>
                      <TableCell align="left"><img src={n.image} className="editions-table-image" alt=""/></TableCell>
                      <TableCell align="left"><a href={`${GET_ALL_EDITIONS}/${n.alias}`}>{n.alias}</a></TableCell>
                      <TableCell>
                            <ul className={classes.ulWrapper}>
                                {!!n.book_localizations.length
                                && n.book_localizations[0].tags.map(item => (
                                    <li
                                        key={`menu-children-item-${n.book_localizations[0].id}-${item.id}`}
                                        className={classes.liItem}
                                    >
                                        {item.title}
                                    </li>
                                ))}
                            </ul>
                      </TableCell>
                      <TableCell align="left">
                        <div className="table-cell-wrap">
                          <Button onClick={() => this.openDeleteDialog(n.id)} color="primary">
                            <Delete style={{ color: '#afafaf' }}/>
                          </Button>
                          <Button onClick={() => this.editEdition(n.id, editions.collection)} color="primary">
                            <Edit style={{ color: '#afafaf' }}/>
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={PER_PAGE_ARR}
          component="div"
          count={editions.pagination.totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage='Рядків на сторінку:'
          backIconButtonProps={{
            'aria-label': 'Попередня',
          }}
          nextIconButtonProps={{
            'aria-label': 'Наступна',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          labelDisplayedRows= {({ from, to, count }) => `${from}-${to} з ${count}`}
        />
      </Paper>
    );
  }
}

EditionsTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  editions: state.editions
});

const mapDispatchToProps = dispatch => ({
  updateCustomerData: (data, id) => {
    dispatch(updateCustomer(data, id))
  },
  getEditions: (page, limit, order, orderBy) => {
    dispatch(fetchEditions(page + 1, limit, order, orderBy));
  },
  removeEdition: (id, page, limit, order, orderBy) => {
    dispatch(delEdition(id, page + 1, limit, order, orderBy));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditionsTableHead));
