import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import '../../assets/styles/categories.scss';

import fetchOne from "../../services/fetch";
import { CORE_URL } from "../../constants/urls";
import { EDIT_ITEM } from '../../constants/types';

import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { Save } from "@material-ui/icons";
import Button from "@material-ui/core/Button/Button";

import CKComponent from '../CKEditor/CKComponent';
import Switch from "@material-ui/core/Switch/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Loader from "../util/Loader";
import Tooltip from '@material-ui/core/Tooltip';

import Delete from '@material-ui/icons/Delete';
import store from "../../store";
import {REQUEST_MESSAGE} from "../../constants/redux";
import UploadFileComponent from '../util/UploadFileComponent/UploadFileComponent';
import ModalWindow from '../../views/layout/ModalWindow';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '95%',
  },
  dense: {
    marginTop: 20,
  },
  menu: {
    width: '95%',
  },
  formControl: {
    margin: theme.spacing.unit,
    width: '95%',
  },
  button: {
    margin: theme.spacing.unit,
  },
});

const PageModel = {
  localization: '',
  description: '',
  title: '',
  idLocal: '', // id локализации
};

class CreatePages extends React.Component {
  state = {
      alias: '',

      is_active: true,
      is_printable: true,
      is_emailable: true,
      is_social_networks_on: true,

      content: [],

      languages: [],

      activeTab: 0,

      errors: [],
      updated: [],

      loading: true,

      open: false,
  };


  componentDidMount() {
    this.getLanguages();
  }

  getLanguages = async () => {
    const { id, type } = this.props;
    const languages = await fetchOne({ url: `${CORE_URL}/languages` });

    if (type === EDIT_ITEM) {
      const {
        page_localizations, is_active, alias,
        is_printable, is_emailable, is_social_networks_on,
      } = await fetchOne({ url: `${CORE_URL}/pages/${id}` });

      let content = new Array(languages.length)
        .fill(PageModel).map((item, i) => ({ ...item, localization: languages[i].id }));

      if (page_localizations.length) {
        content = content.map(item => {
          const page = page_localizations.find(el => item.localization === el.localization.id);
          if (page) {
            const { title, description, is_active } = page;
            const idLocal = page.id; // добавляем id локализации

            return {
              ...item,
              local_active: is_active,
              title, description,
              idLocal, // добавляем id локализации
            };
          }
          return item;
        });
      }

      this.setState({
        updated: page_localizations,

        is_active,
        is_printable,
        is_emailable,
        is_social_networks_on,
        alias,

        languages,
        content,

        loading: false,
      });
    } else {
      const content = new Array(languages.length)
        .fill(PageModel).map((item, i) => ({ ...item, localization: languages[i].id }));
      this.setState({ languages, content, loading: false });
    }
  };

  validateLocalization = () => {
    const { content } = this.state;
    const required = ['title', 'is_active', 'description'];

    const filtered = content.map(item => {
      const entries = Object.entries(item);
      return entries.reduce((prev, [key, val]) => typeof val === 'string' ? { ...prev, [key]: val } : prev, {});
    });

    return filtered.map(item => {
      const entries = Object.entries(item);
      return entries.map(([key, val]) => (required.indexOf(key) > -1) && !val ? key : '');
    });
  };

  submitPage = () => {
    const { alias } = this.state;
    const errors = this.validateLocalization();
    const isValid = errors.some(arr => arr.length ? arr.every(el => !el) : !arr.length);

    if (alias && isValid) this.createPage();
    this.setState({ errors, submited: true });
  };

  createPage = async () => {
    try {
      const {
        content, alias, is_active,
        is_printable, is_emailable, is_social_networks_on,
      } = this.state;

      const isEdit = this.props.type === EDIT_ITEM;
      let url = this.props.id
        ? `${CORE_URL}/pages/${this.props.id}`
        : `${CORE_URL}/pages`;
      let method = isEdit ? 'PUT' : 'POST';

      const pageBody = JSON.stringify({
        is_active, is_printable, alias,
        is_emailable, is_social_networks_on,
      });

      const createPage = await fetchOne({ url, method, body: pageBody });

      if (createPage.status === 201 || isEdit) {
        const valid = content.filter(el => el.title && el.description);
        const pageData = await createPage.json();
        try {
          const toCompare = ['description', 'title'];
          const isUpdated = this.state.updated.map(item => {
            const destructed = Object.entries(item).reduce((prev, [key, val]) => {
              const changed = content.find(el => el.localization === item.localization.id)[key];
              const condition = (toCompare.indexOf(key) > -1) && changed && changed !== val;
              return condition ? ({ ...prev, [key]: changed }) : prev;
            }, {});
            return {
              ...destructed,
              local_id: item.id,
              id: item.localization.id,
            }
          });

          const createLocalization = await Promise.all(
            valid.map(obj => {
              const { description, title, localization } = obj;

              let localizationBody;
              const item = isUpdated.find(el => el.id === localization);

              if (item) {
                const { id, local_id, ...updatedValues } = item;
                url = `${CORE_URL}/pages/${id}/localizations/${local_id}`;
                localizationBody = JSON.stringify({ ...updatedValues });
                method = 'PUT';
              } else {
                url = `${CORE_URL}/pages/${pageData.id}/localizations`;
                localizationBody = JSON.stringify({
                  localization,
                  description,
                  title,
                });
                method = 'POST';
              }

              return fetchOne({ url, method, body: localizationBody });
            })
          );

          const isSuccess = createLocalization.some(res => res && (res.status === 201 || res.status === 204));
          if (isSuccess) this.props.update();
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  changeLocalization = name => event => {
    const { target } = event;
    const { activeTab, errors } = this.state;
    this.setState(state => ({
      content: [
        ...state.content
          .map((item, index) => index === activeTab
            ? ({ ...item, [name]: target.value })
            : item)
      ],
      submited: false,
    }));

    // при вводе текста в поле убираем
    // из стэйта все ошибки касающиеся этого поля
    if (errors[activeTab]) {
      const updErrorsActiveTab = errors[activeTab].filter(el => el !== name);
      const copyStateErrors = [...errors];
      copyStateErrors[activeTab] = updErrorsActiveTab;
      this.setState({ errors: copyStateErrors });
    }
  };

  setDescription = description => {
    const { activeTab, errors } = this.state;
    this.setState(state => ({
      content: [
        ...state.content
          .map((item, index) => index === activeTab
            ? ({ ...item, description })
            : item)
      ],
      submited: false,
    }));

    // при вводе текста в поле убираем
    // из стэйта все ошибки касающиеся этого поля
    if (errors[activeTab]) {
      const updErrorsActiveTab = errors[activeTab].filter(el => el !== 'description');
      const copyStateErrors = [...errors];
      copyStateErrors[activeTab] = updErrorsActiveTab;
      this.setState({ errors: copyStateErrors });
    }

  };

  handleAliasChange = ({ target }) => {
    this.setState({
      alias: target.value,
      submited: false,
    });
  };

  handleChange = name => ({ target }) => this.setState({ [name]: target.value, submited: false });

  handleCheck = name => event => this.setState({ [name]: event.target.checked });

  changeTab = (event, value) => this.setState({ activeTab: value });


  // закрытие (отмена) модального окна по подтверждению удалиения локализации
  handleCancel = () => this.setState({ open: false });
  // открытие модального окна
  openModal = () => this.setState({ open: true });
  // DELETE, /api/v1/pages/{id}/localizations/{localization}
  // Удалить существующую локализацию страницы
  deleteLocalizationPage = async (id, localizationId) => {
    const { content } = this.state;
    if(content.filter(l => !!l.idLocal).length === 1){
      store.dispatch({
        type: REQUEST_MESSAGE,
        data: {
          message: 'Хоча б одна мова має бути заповнена!',
          type: 'error',
        }
      });
      return false;
    }
    try {
      const deleteLocalization = await fetchOne({
        url: `${CORE_URL}/pages/${id}/localizations/${localizationId}`,
        method: 'DELETE',
      });

      if (deleteLocalization && deleteLocalization.ok) {
        this.getLanguages();
        this.handleCancel();
      } else {
        console.log(deleteLocalization.statusText);
        this.handleCancel();
      }
    } catch (e) {
      console.log(e);
      this.handleCancel();
    }
  };

  render() {
    const { classes } = this.props;
    const {
      languages, loading,
      alias, activeTab, errors, is_active,
      is_printable, is_emailable, is_social_networks_on, content, open
    } = this.state;
    const error = errors[activeTab];

    if (loading) return (<Loader/>);

    const dis = content[activeTab] && content[activeTab].idLocal === '';

    return (
      <div className="category-create_wrapper">
        <div className="submit-button_wrapper">

          {/* кнопка удалить локализацию */}
          {this.props.type === "EDIT_ITEM" &&
          <Tooltip title="Видалити локализацію"
                   placement="left"
                   onClick={this.openModal}>
            <Button disabled={dis}
                    style={{ marginRight: '10px' }} variant="contained"
                    color="secondary">
              <Delete style={{ color: '#fff' }}/>
            </Button>
          </Tooltip>
          }
          {/* конец --- кнопка удалить локализацию */}

          <Button
            onClick={this.submitPage}
            variant="contained"
            color="primary"
          >
            <Save style={{ color: '#fff' }} />
          </Button>
        </div>
        <div className="form-wrapper">
          <Paper style={{ padding: '11px 10px' }}>
            <Typography
              variant="subheading"
              align="left"
            >
              Властивості сторінки:
            </Typography>
          </Paper>
          <form
            className={classes.container}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="standard-alias"
              label="Alias"
              className={classes.textField}
              value={alias}
              error={!alias && this.state.submited}
              onChange={this.handleAliasChange}
              margin="normal"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={is_active}
                  onChange={this.handleCheck('is_active')}
                  color="primary"
                />
              }
              label={is_active ? 'Активна' : 'Неактивна'}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={is_printable}
                  onChange={this.handleCheck('is_printable')}
                  color="primary"
                />
              }
              label={is_printable ? 'Для друку' : 'Не для друку'}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={is_emailable}
                  onChange={this.handleCheck('is_emailable')}
                  color="primary"
                />
              }
              label={is_emailable ? 'Доступна для відправки по E-mail' : 'Не доступна для відтправки по E-mail'}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={is_social_networks_on}
                  onChange={this.handleCheck('is_social_networks_on')}
                  color="primary"
                />
              }
              label={is_social_networks_on ? 'З соціальними мережами' : 'Без соціальних мереж'}
            />
          </form>
          <UploadFileComponent />
        </div>

        <div className="form-wrapper form-big">
          <AppBar position="static" color="default">
            <Tabs
              value={activeTab}
              onChange={this.changeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              {languages.map(language => (
                <Tab
                  key={`language-tab-${language.id}`}
                  label={language.title}
                />
              ))}
            </Tabs>
          </AppBar>
          {this.state.content
            .filter((el, index) => index === activeTab)
            .map((item, index) => (
              <form
                key={`form-item-${index}`}
                className={classes.container}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="standard-title"
                  label="Заголовок"
                  className={classes.textField}
                  value={item.title}
                  error={error && error.some(el => el === 'title')}
                  onChange={this.changeLocalization('title')}
                  margin="normal"
                />

                <CKComponent
                  error={error && error.some(el => el === 'description')}
                  setDescription={this.setDescription}
                  description={item.description}
                />
              </form>
            ))}
        </div>

        {/* Всплывающее диалоговое окно по удалению страниц */}
        <ModalWindow
          open={open}
          title="Дійсно бажаєте видалити локалізацію?"
          onClose={this.handleCancel}
          onConfirm={() => this.deleteLocalizationPage(this.props.id, this.state.content[activeTab].idLocal)}
        />

      </div>
    );
  }
}

export default withStyles(styles)(CreatePages);
