import {
  ALL_EDITIONS_DOWNLOADED,
} from '../constants/redux';

const initialState = [];

const editions = (state = initialState, action) => {
  switch (action.type) {
    case ALL_EDITIONS_DOWNLOADED:
      return action.payload;
    default:
      return state;
  }
};

export default editions;
