import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';

const params = [
  { link: '/', name: 'Головна' },
  { link: '/languages', name: 'Мови' },
  {link: '/system-translations', name: 'Системні переклади'},
  { link: '/header', name: 'Хедер' },
  { link: '/footer', name: 'Футер' },
  { link: '/banners', name: 'Банери' },
  { link: '/menus', name: 'Меню' },
  { link: '/news', name: 'Новини' },
  { link: '/pages', name: 'Сторінки' },
  // {link: '/files', name: "Файли"},
  { link: '/editions', name: 'Книги' },
  { link: '/tags', name: 'Теги' },
  { link: '/system-contacts', name: 'Системні контакти' },
  { link: '/employees', name: 'Працівники' },
  { link: '/advertising', name: 'Реклама' },
  { link: '/information-blocks', name: 'Інфо. блоки' },
  {link: '/contacts', name: "Контакти"},
  {link: '/telegram-bots', name: "Телеграм-боти"},
  {link: '/feedbacks', name: "Зворотній зв'язок"},
  {link: '/nodes', name: "Організаційна структура"},
  { link: '/partners', name: 'Партнери' },
  {link: '/settings', name: "Налаштування"},
];

class SideBar extends PureComponent {
  render() {
    return (
      <div className="App-sidebar">
        {params.map(item => (
          <React.Fragment key={`nav-item-${item.name}`}>
            <NavLink className="side-nav-item" exact to={item.link}>
              <span>{item.name}</span>
            </NavLink>
          </React.Fragment>
        ))}
      </div>
    );
  }
}

export default SideBar;
