import { SYS_PAGE_DOWNLOADED } from '../constants/redux';

const systemPages = (state = {}, action) => {
  switch (action.type) {
    case SYS_PAGE_DOWNLOADED:
      return action.systemPages;
    default:
      return state;
  }
};

export default systemPages;
