import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import '../assets/styles/categories.scss';

import fetchOne from '../services/fetch';
import { CORE_URL } from '../constants/urls';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { Save } from '@material-ui/icons';
import Button from '@material-ui/core/Button/Button';
import TextField from '@material-ui/core/TextField/TextField';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: `calc(50% - ${2*theme.spacing.unit}px)`,
  },
  dense: {
    marginTop: 20,
  },
  menu: {
    width: '95%',
  },
  formControl: {
    margin: theme.spacing.unit,
    width: '95%',
  },
  tagsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
  },
  tagsList: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    listStyleType: 'none',
    padding: '10px 0',
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit,
  },
  tagsItem: {
    border: '1px solid',
    borderRadius: 5,
    marginRight: 5,
    padding: 5,
  },
  titleWrapper: {
    width: '100%',
    margin: '5px 0',
    padding: '5px 0',
    border: '1px solid',
  },
});

class CreatePages extends React.Component {
  state = {
    news_amount_on_main_page: 0,
    banner_rotation_delay: 0,
    google_plus_link: '',
    instagram_link: '',
    linked_in_link: '',
    facebook_link: '',
    twitter_link: '',
    about_us_url: '',
    youtube_link: '',
    contact_url: '',
    book_url: '',
    home_url: '',
    npp_url: '',

    errors: [],
  };

  componentDidMount() {
    this.getSettingsData();
  }

  getSettingsData = async () => {
    const {
      news_amount_on_main_page,
      banner_rotation_delay,
      google_plus_link,
      instagram_link,
      linked_in_link,
      facebook_link,
      twitter_link,
      about_us_url,
      youtube_link,
      contact_url,
      book_url,
      home_url,
      npp_url,
    } = await fetchOne({ url: `${CORE_URL}/settings` });

    this.setState({
      news_amount_on_main_page: news_amount_on_main_page ? news_amount_on_main_page : 0,
      banner_rotation_delay: banner_rotation_delay ? banner_rotation_delay : 0,
      google_plus_link: google_plus_link ? google_plus_link : '',
      instagram_link: instagram_link ? instagram_link : '',
      linked_in_link: linked_in_link ? linked_in_link : '',
      facebook_link: facebook_link ? facebook_link : '',
      twitter_link: twitter_link ? twitter_link : '',
      about_us_url: about_us_url ? about_us_url : '',
      youtube_link: youtube_link ? youtube_link : '',
      contact_url: contact_url ? contact_url : '',
      book_url: book_url ? book_url : '',
      home_url: home_url ? home_url : '',
      npp_url: npp_url ? npp_url : '',
    });
  };

  submitMenu = async () => {
    try {
      const {
        news_amount_on_main_page,
        banner_rotation_delay,
        google_plus_link,
        instagram_link,
        linked_in_link,
        facebook_link,
        twitter_link,
        about_us_url,
        youtube_link,
        contact_url,
        book_url,
        home_url,
        npp_url,
      } = this.state;

      const body = JSON.stringify({
        news_amount_on_main_page: parseInt(news_amount_on_main_page),
        banner_rotation_delay: parseInt(banner_rotation_delay),
        google_plus_link,
        instagram_link,
        linked_in_link,
        facebook_link,
        twitter_link,
        about_us_url,
        youtube_link,
        contact_url,
        book_url,
        home_url,
        npp_url,
      });

      const update = await fetchOne({
        url: `${CORE_URL}/settings`,
        method: 'PUT',
        body,
      });

      if (update.status === 201) this.getSettingsData();
    } catch (err) {
      console.log(err);
    }
  };

  handleChange = name => ({ target }) => this.setState({ [name]: target.value });

  render() {
    const { classes } = this.props;
    const {
      news_amount_on_main_page,
      banner_rotation_delay,
      google_plus_link,
      instagram_link,
      linked_in_link,
      facebook_link,
      twitter_link,
      about_us_url,
      youtube_link,
      contact_url,
      book_url,
      home_url,
      npp_url,
    } = this.state;

    return (
      <div
        className="menu-create_wrapper"
        style={{ width: '100%' }}
      >
        <div className="submit-button_wrapper">
          <Button
            onClick={this.submitMenu}
            variant="contained"
            color="primary"
          >
            <Save style={{ color: '#fff' }} />
          </Button>
        </div>
        <div className="form-wrapper">
          <Paper style={{ padding: 10 }}>
            <Typography variant="subheading" align="left">
              Властивості налаштувань:
            </Typography>
          </Paper>
          <form
            className={classes.container}
            noValidate
            autoComplete="off"
            style={{
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <TextField
              id="standard-banner_rotation_delay"
              label="Час ротації банера"
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
              className={classes.textField}
              value={banner_rotation_delay}
              onChange={this.handleChange('banner_rotation_delay')}
              margin="normal"
            />

            <TextField
              id="standard-news_amount_on_main_page"
              label="Кількість новин на головній сторінці"
              type="number"
              InputProps={{ inputProps: { min: 1, max: 6 } }}
              className={classes.textField}
              value={news_amount_on_main_page}
              onChange={this.handleChange('news_amount_on_main_page')}
              margin="normal"
            />

            <TextField
              id="standard-home_url"
              label="Посилання 'Головної сторінки'"
              className={classes.textField}
              value={home_url}
              onChange={this.handleChange('home_url')}
              margin="normal"
            />

            <TextField
              id="standard-about_us_url"
              label="Поислання сторінки 'Про Нас'"
              className={classes.textField}
              value={about_us_url}
              onChange={this.handleChange('about_us_url')}
              margin="normal"
            />

            <TextField
              id="standard-npp_url"
              label="Посилання сторінки 'НПП'"
              className={classes.textField}
              value={npp_url}
              onChange={this.handleChange('npp_url')}
              margin="normal"
            />

            <TextField
              id="standard-contact_url"
              label="Посилання сторінки 'Контакти'"
              className={classes.textField}
              value={contact_url}
              onChange={this.handleChange('contact_url')}
              margin="normal"
            />

            <TextField
              id="standard-book_url"
              label="Посилання сторінки 'Книги'"
              className={classes.textField}
              value={book_url}
              onChange={this.handleChange('book_url')}
              margin="normal"
            />

            <TextField
              id="standard-google_plus_link"
              label="Google+"
              className={classes.textField}
              value={google_plus_link}
              onChange={this.handleChange('google_plus_link')}
              margin="normal"
            />

            <TextField
              id="standard-instagram_link"
              label="Instagram"
              className={classes.textField}
              value={instagram_link}
              onChange={this.handleChange('instagram_link')}
              margin="normal"
            />

            <TextField
              id="standard-linked_in_link"
              label="LinkedIn"
              className={classes.textField}
              value={linked_in_link}
              onChange={this.handleChange('linked_in_link')}
              margin="normal"
            />

            <TextField
              id="standard-facebook_link"
              label="Facebook"
              className={classes.textField}
              value={facebook_link}
              onChange={this.handleChange('facebook_link')}
              margin="normal"
            />

            <TextField
              id="standard-twitter_link"
              label="Twitter"
              className={classes.textField}
              value={twitter_link}
              onChange={this.handleChange('twitter_link')}
              margin="normal"
            />

            <TextField
              id="standard-youtube_link"
              label="Youtube"
              className={classes.textField}
              value={youtube_link}
              onChange={this.handleChange('youtube_link')}
              margin="normal"
            />

          </form>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CreatePages);
