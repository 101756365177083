import React from 'react';
import PropTypes from 'prop-types';
import '../assets/styles/components/departments.scss';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import {Delete, Edit} from '@material-ui/icons';
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Loader from "./util/Loader";


import { PER_PAGE, PER_PAGE_ARR } from "../constants/data";
import fetchOne from "../services/fetch";
import {PARTNER_TYPES_PATH} from "../constants/urls";
import ModalWindow from '../views/layout/ModalWindow';

function createPartnerTypesData(partner_types) {
  let data = [];
  if(partner_types.length){
    partner_types.forEach(function(item, i, arr) {
      const title = `${item.partner_type_localizations.length > 0 ? item.partner_type_localizations[0].title : '' }`;
      const priority = item.priority;
      data.push({id: item.id, title, priority})
    });
  }
  return data;
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'title', numeric: false, disablePadding: false, label: 'Назва' },
  { id: 'priority', numeric: false, disablePadding: false, label: 'Пріоритет' },
  { id: 'actions', numeric: false, disablePadding: false, label: '' },
];

class PartnerTypesTable extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
          </TableCell>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align= 'left'
                padding= 'default'
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

PartnerTypesTable.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class PartnerTypesTableHead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: PER_PAGE,
      openDelete: false,
      curId: '',
      curObj: {},
      loading: true,
      partner_types: [],
    };
    this.onChange = this.onChange.bind(this);
    this.deletePartner = this.deletePartner.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const data = await fetchOne({ url: `${PARTNER_TYPES_PATH}?limit=1000` });
    this.setState({ partner_types: data, curId: '' });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  onChange(e) {
    if (e.target.value.trim() !== '') {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  onCancel =() => {
    this.setState({openDelete: false, selected: [], curId: ''});
  };

  handleClose = () => {
    this.setState({ openDelete: false, curId: '', selected: [] });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  openDeleteDialog = (id) => {
    this.setState({ openDelete: true, curId: id });
  };

  async deletePartner(){
    const {curId, partner_types} = this.state;
    try {
      const rev = await fetchOne({
        url: `${PARTNER_TYPES_PATH}/${curId}`,
        method: "DELETE",
      });
      if(rev.ok) {
        const data = [...partner_types];
        const index = data.findIndex(obj => obj.id === curId);
        data.splice(index, 1);
        this.setState({openDelete: false, openUpdate: false, curId: '', curObj: {}, partner_types: data});
      }
    } catch (err) {
      console.log(err.message);
    }
    this.setState({ openDelete: false, curId: '', selected: [] });
  };

  editPartnerType(id, partner_types) {
    const obj = partner_types.filter(ch => ch.id === id);
    this.props.toEdit(obj);
  }

  render() {
    const { classes } = this.props;
    const { order, orderBy, selected, rowsPerPage, page, openDelete, partner_types } = this.state;
    let partnerTypesData = [];
    if(partner_types.length){
      partnerTypesData = createPartnerTypesData(partner_types);
    } else {
      return (<Loader />);
    }

    return (
      <Paper className={classes.root}>

        {/**Delete modal**/}
        <ModalWindow
          open={openDelete}
          title="Ви впевнені що хочете видалити цей запис?"
          onClose={this.handleClose}
          onConfirm={this.deletePartner}
        />

        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <PartnerTypesTable
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={partnerTypesData.length}
            />
            <TableBody>
              {stableSort(partnerTypesData, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, n.id)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell padding="checkbox">
                      </TableCell>
                      <TableCell align="left">{n.title}</TableCell>
                      <TableCell align="left">{n.priority}</TableCell>
                      <TableCell align="left">
                        <Button onClick={() => this.openDeleteDialog(n.id)} color="primary">
                          <Delete style={{ color: '#afafaf' }}/>
                        </Button>
                        <Button onClick={() => this.editPartnerType(n.id, partner_types)} color="primary">
                          <Edit style={{ color: '#afafaf' }}/>
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={PER_PAGE_ARR}
          component="div"
          count={partnerTypesData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          labelRowsPerPage='Рядків на сторінку:'
          labelDisplayedRows= {({ from, to, count }) => `${from}-${to} з ${count}`}
        />
      </Paper>
    );
  }
}

export default withStyles(styles)(PartnerTypesTableHead);
