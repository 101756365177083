import axios from "axios";
import { CORE_URL } from "../constants/urls";
import { getTokenInfo } from "./auth";

const auth = getTokenInfo();

export default class UploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file
      .then(uploadedFile => {
        return new Promise((resolve, reject) => {
          const data = new FormData();
          data.append('file', uploadedFile);

          axios({
            url: `${CORE_URL}/files`,
            method: 'POST',
            data,
            headers: {
              'Content-Type': 'application/json',
              ...(auth && {
                'Authorization': `${auth.token_type} ${auth.access_token}`,
              }),
            },
          })
            .then(response => {
              if (response.status === 200) {
                resolve({ default: response.data.href });
              } else {
                reject(response.data.message);
              }
            })
            .catch(() => reject('Upload failed'));
        });
      });
  }

  abort() {}
}