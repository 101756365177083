import { FRONT_URL } from "../constants/urls";

export const toISODate = (date) => {
  const d = date instanceof Date ? date : new Date(date);

  function pad(n) {
    return n < 10 ? "0" + n : n;
  }

  function timezoneOffset(offset) {
    let sign;
    if (offset === 0) {
      return "Z";
    }
    sign = (offset > 0) ? "-" : "+";
    offset = Math.abs(offset);
    return sign + pad(Math.floor(offset / 60)) + ":" + pad(offset % 60);
  }

  return d.getFullYear() + "-" +
      pad(d.getMonth() + 1) + "-" +
      pad(d.getDate()) + "T" +
      pad(d.getHours()) + ":" +
      pad(d.getMinutes()) + ":" +
      pad(d.getSeconds()) +
      timezoneOffset(d.getTimezoneOffset());
};

export const formatPublishDate = (date) => {
  const d = date instanceof Date ? date : new Date(date);
  let hh = d.getHours();
  let dd = d.getDate();
  let mm = d.getMonth() + 1;
  const yy = d.getFullYear();
  let nn = d.getMinutes();
  let ss = d.getSeconds();

  if (dd.toString().length < 2) {
    dd = `0${dd}`;
  }
  if (mm.toString().length < 2) {
    mm = `0${mm}`;
  }
  if (hh.toString().length < 2) {
    hh = `0${hh}`;
  }
  if (nn.toString().length < 2) {
    nn = `0${nn}`;
  }
  if (ss.toString().length < 2) {
    ss = `0${ss}`;
  }

  return `${yy}-${mm}-${dd} ${hh}:${nn}:${ss}`;
};

export const generateURL = (item) => {
  let URL = '';
  if (item.type === 3) {
    const { page } = item;
    URL = page && `page/${page.id}`;
    URL = URL || `page/${item.id}`;
  } else {
    URL = item.url;
  }
  if (URL.indexOf('http', 0) < 0) {
    return `/${URL}`;
  } else {
    return `${URL}`;
  }
};

export const urlStringify = data => Object.entries(data).map(e => e.join('=')).join('&');

export const addLink = link => {
  if (!link) return link;
  const fixed = !link.match('/') ? `/${link}` : link;
  return !link.match('http') ? `${FRONT_URL}${fixed}` : fixed;
};

