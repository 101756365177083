import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import Button from "@material-ui/core/Button/Button";
import TextField from "@material-ui/core/TextField/TextField";

import fetchOne from "../services/fetch";
import { AUTH_URL } from "../constants/urls";
import { AUTH_BASIC } from "../constants/data";
import { urlStringify } from "../services/main";
import { saveToken } from "../services/auth";

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '95%',
  },
  dense: {
    marginTop: 20,
  },
  menu: {
    width: '95%',
  },
  formControl: {
    margin: theme.spacing.unit,
    width: '95%',
  },
});

class LogIn extends React.Component {
  state = {
    username: '',
    password: '',
  };

  logIn = async () => {
    const { username, password } = this.state;
    const headers = {
      "Content-Type" :"application/x-www-form-urlencoded",
      "Authorization" : AUTH_BASIC,
    };

    const authBody = urlStringify({
      grant_type: 'password',
      username,
      password,
    });

    const authState = await fetchOne({
      url: AUTH_URL,
      method: 'POST',
      headers,
      body: authBody,
    });

    const response = await authState.json();
    const { expires_in, ...rest } = response;
    const now = Math.round((Date.now()/1000));

    const authData = JSON.stringify({
      ...rest,
      expires_in: now + expires_in,
    });

    saveToken(authData);
    localStorage.setItem('username', username);
    this.props.history.push("/");
  };

  handleChange = name => event => {
    const { target } = event;
    this.setState({
      [name]: target.value,
    });
  };

  enterLogIn = ({ key }) => key === 'Enter' && this.logIn();

  render() {
    const { classes } = this.props;
    return(
      <div className="login-wrapper">
        <form
          className={classes.container}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="standard-username"
            label="Username"
            className={classes.textField}
            value={this.state.username}
            onChange={this.handleChange('username')}
            margin="normal"
          />

          <TextField
            id="standard-password"
            label="Password"
            type="password"
            className={classes.textField}
            value={this.state.password}
            onChange={this.handleChange('password')}
            onKeyPress={this.enterLogIn}
            margin="normal"
          />
        </form>
        <Button
          onClick={this.logIn}
          variant="contained"
          color="primary"
        >
          Log In
        </Button>
      </div>
    )
  }
}

export default withStyles(styles)(LogIn);