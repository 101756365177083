import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import '../../assets/styles/nav_components/main.scss';

import { EDIT_ITEM } from '../../constants/types';
import { PER_PAGE, PER_PAGE_ARR } from "../../constants/data";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Button from "@material-ui/core/Button/Button";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';

import { Delete, Edit } from "@material-ui/icons";

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Назва телеграм-бота' },
    { id: 'link', numeric: false, disablePadding: true, label: 'Посилання' },
];

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { order, orderBy } = this.props;
        return (
            <TableHead>
                <TableRow>
                    {rows.map(row => (
                        <TableCell
                            key={row.id}
                            align={row.numeric ? 'right' : 'left'}
                            padding={row.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === row.id ? order : false}
                        >
                            <Tooltip
                                title="Sort"
                                placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                enterDelay={300}
                            >
                                <TableSortLabel
                                    active={orderBy === row.id}
                                    direction={order}
                                    onClick={this.createSortHandler(row.id)}
                                >
                                    {row.label}
                                </TableSortLabel>
                            </Tooltip>
                        </TableCell>
                    ), this)}
                    <TableCell padding="checkbox" />
                    <TableCell padding="checkbox" />
                </TableRow>
            </TableHead>
        );
    }
}

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    fullBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
});

const locale = ({ system_translations_localizations }) => {
    return !!system_translations_localizations.length ? system_translations_localizations[0] : {};
};

class AllTelegramBots extends React.Component {
    state = {
        order: 'asc',
        orderBy: 'system_name',
        selected: [],

        page: 0,
        rowsPerPage: PER_PAGE,
        onlyActive: false,

        data: this.props.data,
    };

    static getDerivedStateFromProps(props, state) {
        if (props.data !== state.data) {
            return {
                selected: [],
                data: props.data,
            };
        }
        return null;
    }

    goToCreate = type => this.props.onCreate(type);

    goToEdit = (id, type) => this.props.onEdit(id, type);

    deleteItem = id => this.props.openModal(id);

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => this.setState({ page });

    handleChangeRowsPerPage = event => this.setState({ rowsPerPage: event.target.value });

    render() {
        const { classes } = this.props;
        const { data, order, orderBy, rowsPerPage, page } = this.state;
        const activeData = data;

        return (
            <div className="tab-block-content no-shadow">
                    <Paper className={classes.root}>
                        <div className={classes.tableWrapper}>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                            >
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                />
                                <TableBody>
                                    {stableSort(activeData, getSorting(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map(n => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    key={n.id}
                                                >
                                                    <TableCell>
                                                        {!!n.telegram_bot_localizations.length
                                                            && n.telegram_bot_localizations[0].name}
                                                    </TableCell><TableCell>
                                                        {!!n.telegram_bot_localizations.length
                                                            && n.telegram_bot_localizations[0].link}
                                                    </TableCell>
                                                    <TableCell padding="checkbox">
                                                        <Button onClick={() => this.deleteItem(n.id)} color="primary">
                                                            <Delete style={{ color: '#afafaf' }} />
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell padding="checkbox">
                                                        <Button onClick={() => this.goToEdit(n.id, EDIT_ITEM)} color="primary">
                                                            <Edit style={{ color: '#afafaf' }} />
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={PER_PAGE_ARR}
                            component="div"
                            count={activeData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Попередня сторінка',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Наступна сторінка',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            labelRowsPerPage='Рядків на сторінку:'
                            labelDisplayedRows= {({ from, to, count }) => `${from}-${to} з ${count}`}
                        />
                    </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(AllTelegramBots);
