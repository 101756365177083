import * as React from 'react';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import {withStyles} from "@material-ui/core/styles/index";

const styles = theme => ({
  isOpen: {
    backgroundColor: '#f5f5f5'
  },
});


const locale = (localizations) => {
  const localLanguage = localStorage.getItem('language');
  const lang_system_contacts = localizations.filter(localization => localization.localization.id === localLanguage);
  return !!localizations.length ? lang_system_contacts[0] : {};
};

class ExpandableProperty extends React.Component {

  state = {
    isOpen: !!this.props.expanded,
    data: this.props.property,
  };

  render() {
    const {classes} = this.props;
    const {data, isOpen} = this.state;

    return (
      <React.Fragment>
        <div
          className={this.props.openedInfo === data.id ? classes.isOpen : ''}
          style={{
            display: 'flex',
            borderBottom: '1px solid #eee',
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: '38px',
            cursor: 'pointer'
          }}>
          <span style={{
            width: 'calc(100% - 30px)',
            minHeight: '38px',
            padding: '5px 10px'
          }}
                onClick={() => this.props.showItemInfo(data)}>
             {locale(data.node_localizations) ? locale(data.node_localizations).title : ''}
          </span>
          {React.Children.count(this.props.children) !== 0 &&
          <span
            onClick={() => this.setState({isOpen: !isOpen})}>
            {isOpen ? <ExpandLess/> : <ExpandMore/>}
          </span>
          }

        </div>
        {isOpen ? this.props.children : null}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ExpandableProperty);