import {
  REQUEST_MESSAGE,
  CLOSE_MESSAGE,
} from '../constants/redux';

const initialState = {
  open: false,
  message: '',
  type: '',
};

const information = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_MESSAGE:
      return {
        open: true,
        message: action.data.message,
        type: action.data.type,
      };
    case CLOSE_MESSAGE:
      return initialState;
    default:
      return state;
  }
};

export default information;
