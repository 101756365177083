import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import '../../assets/styles/categories.scss';

import fetchOne from '../../services/fetch';
import { CORE_URL } from '../../constants/urls';
import { EDIT_ITEM } from '../../constants/types';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { Save } from '@material-ui/icons';
import Button from '@material-ui/core/Button/Button';
import TextField from '@material-ui/core/TextField/TextField';
import Tabs from '@material-ui/core/Tabs/Tabs';
import Tab from '@material-ui/core/Tab/Tab';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Switch from '@material-ui/core/Switch/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Delete from '@material-ui/icons/Delete';
import store from "../../store";
import {REQUEST_MESSAGE} from "../../constants/redux";
import ModalWindow from '../../views/layout/ModalWindow';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '95%',
  },
  dense: {
    marginTop: 20,
  },
  menu: {
    width: '95%',
  },
  formControl: {
    margin: theme.spacing.unit,
    width: '95%',
  },
  tagsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
  },
  tagsList: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    listStyleType: 'none',
    padding: '10px 0',
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit,
  },
  tagsItem: {
    border: '1px solid',
    borderRadius: 5,
    marginRight: 5,
    padding: 5,
  },
  titleWrapper: {
    width: '100%',
    margin: '5px 0',
    padding: '5px 0',
    border: '1px solid',
  },
});

const PageModel = {
  localization: '',
  title: '',
  idLocal: '', // id локализации
};

class CreatePages extends React.Component {
 state = {
      searchable: true,
      parent: '',
      page: '',
      url: '',

      priority: 1,
      type: 1,

      content: [],

      languages: [],
      activeTab: 0,

      errors: [],

      updated: [],
      children: [],
      menus: [],
      pages: {},

      open: false,
 };


  componentDidMount() {
    this.getLanguages();
  }

  getLanguages = async () => {
    const { id, type, data } = this.props;
    const languages = await fetchOne({ url: `${CORE_URL}/languages` });
    const menus = await fetchOne({ url: `${CORE_URL}/menus?parent=true` });
    const pages = await fetchOne({ url: `${CORE_URL}/pages?limit=1000` });

    if (type === EDIT_ITEM) {
      const {
        menu_localizations,
        searchable,
        page,
        url,
        priority,
        type,
      } = await fetchOne({ url: `${CORE_URL}/menus/${id}` });
      const { children } = data.find(item => id === item.id);

      const parentData = await fetchOne({ url: `${CORE_URL}/menus/${id}/parent` });

      const parent = parentData ? parentData.id : '';

      let content = new Array(languages.length)
        .fill(PageModel)
        .map((item, i) => ({
          ...item,
          localization: languages[i].id,
        }));

      if (menu_localizations.length) {
        content = content.map(item => {
          const menu = menu_localizations.find(
            el => item.localization === el.localization.id,
          );
          if (menu) {
            const { title } = menu;
            const idLocal = menu.id; // добавляем id локализации
            return {
              ...item,
              title,
              idLocal, // добавляем id локализации
            };
          }
          return item;
        });
      }

      this.setState({
        updated: menu_localizations,
        searchable,
        parent,
        url,
        priority,
        type,
        page: page ? page.id : page,

        languages,
        children,
        content,
        pages,
        menus,
      });
    } else {
      const content = new Array(languages.length)
        .fill(PageModel)
        .map((item, i) => ({
          ...item,
          localization: languages[i].id,
        }));

      this.setState({
        languages,
        content,
        pages,
        menus,
      });
    }
  };

  validateLocalization = () => {
    const { content } = this.state;
    const required = ['title'];

    const filtered = content.map(item => {
      const entries = Object.entries(item);
      return entries.reduce(
        (prev, [key, val]) =>
          typeof val === 'string' ? { ...prev, [key]: val } : prev,
        {},
      );
    });

    return filtered.map(item => {
      const entries = Object.entries(item);
      return entries.map(([key, val]) =>
        required.indexOf(key) > -1 && !val ? key : '',
      );
    });
  };

  submitMenu = () => {
    const { type, url, page } = this.state;
    const errors = this.validateLocalization();
    const isValid = errors.some(arr =>
      arr.length ? arr.every(el => !el) : !arr.length,
    );

    if (type && isValid && (page || url)) this.createMenu();
    this.setState({ errors, showError: true });
  };

  createMenu = async () => {
    try {
      const {
        content,
        searchable,
        parent,
        page,
        url,
        priority,
        type,
      } = this.state;

      const isEdit = this.props.type === EDIT_ITEM;
      let method = isEdit ? 'PUT' : 'POST';
      let URL = isEdit
        ? `${CORE_URL}/menus/${this.props.id}`
        : `${CORE_URL}/menus`;

      const pageBody = JSON.stringify({
        searchable,
        page,
        url,
        type,
        priority,
        ...(parent && { parent }),
      });

      const createMenu = await fetchOne({ url: URL, method, body: pageBody });

      if (createMenu.status === 201 || createMenu.status === 204) {
        const pageData = await createMenu.json();
        const valid = content.filter(({ title }) => title);
        try {
          const toCompare = ['title'];
          const isUpdated = this.state.updated.map(item => {
            const destructed = Object.entries(item).reduce(
              (prev, [key, val]) => {
                const changed = content.find(
                  el => el.localization === item.localization.id,
                )[key];
                const condition =
                  // toCompare.indexOf(key) > -1 && changed && changed !== val;
                  toCompare.indexOf(key) > -1 && changed;
                return condition ? { ...prev, [key]: changed } : prev;
              },
              {},
            );
            return {
              ...destructed,
              local_id: item.id,
              id: item.localization.id,
            };
          });

          const createLocalization = await Promise.all(
            valid.map(obj => {
              const { title, localization } = obj;

              let localizationBody;
              const item = isUpdated.find(el => el.id === localization);

              if (item) {
                const { id, local_id, ...updatedValues } = item;
                URL = `${CORE_URL}/menus/${id}/localizations/${local_id}`;
                localizationBody = JSON.stringify({ ...updatedValues });
                method = 'PUT';
              } else {
                URL = `${CORE_URL}/menus/${pageData.id}/localizations`;
                localizationBody = JSON.stringify({ localization, title });
                method = 'POST';
              }

              return fetchOne({ url: URL, method, body: localizationBody });
            }),
          );

          const isSuccess = createLocalization.some(
            res => res && (res.status === 201 || res.status === 204),
          );
          if (isSuccess) this.props.update();
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  changeLocalization = name => event => {
    const { target } = event;
    const { activeTab } = this.state;
    this.setState(state => ({
      content: [
        ...state.content.map((item, index) =>
          index === activeTab ? { ...item, [name]: target.value } : item,
        ),
      ],
      showError: false,
    }));
  };

  handleChange = name => ({ target }) => {
    const value =
      target.type === 'number' ? parseInt(target.value) : target.value;
    this.setState({
      [name]: value,
      error: false,
    });
  };

  changeTab = (event, value) => this.setState({ activeTab: value });

  handleCheck = name => event =>
    this.setState({ [name]: event.target.checked });

  // открытие модального окна
  openModal = () => this.setState({ open: true });
  // закрытие (отмена) модального окна по подтверждению удалиения локализации
  handleCancel = () => this.setState({ open: false });
  // DELETE, /api/v1/menus/{id}/localizations/{localizationId}
  // Удалить существующую локализацию банера
  deleteLocalizationMenu = async (id, localizationId) => {
    const { content } = this.state;
    if(content.filter(l => !!l.idLocal).length === 1){
      store.dispatch({
        type: REQUEST_MESSAGE,
        data: {
          message: 'Хоча б одна мова має бути заповнена!',
          type: 'error',
        }
      });
      return false;
    }
    const deleteLocalization = await fetchOne({
      url: `${CORE_URL}/menus/${id}/localizations/${localizationId}`,
      method: 'DELETE',
    });

    if (deleteLocalization && deleteLocalization.ok) {
      this.getLanguages();
      this.handleCancel();
    } else {
      console.log(deleteLocalization.statusText);
      this.handleCancel();
    }
  };

  render() {
    const { classes } = this.props;
    const {
      languages,
      activeTab,
      pages,
      errors,
      children,
      menus,
      content,
      showError,
      open,
    } = this.state;
    const error = errors[activeTab];

    const dis = content[activeTab] && content[activeTab].idLocal === '';

    return (
      <div className="menu-create_wrapper">
        <div className="submit-button_wrapper">

          {/* кнопка удалить локализацию */}
          {this.props.type === "EDIT_ITEM" &&
          <Tooltip title="Видалити локализацію" placement="left"
                   onClick={this.openModal}>
            <Button disabled={dis}
                    style={{ marginRight: '10px' }} variant="contained"
                    color="secondary">
              <Delete style={{ color: '#fff' }}/>
            </Button>
          </Tooltip>
          }
          {/* конец --- кнопка удалить локализацию */}

          <Button onClick={this.submitMenu} variant="contained" color="primary">
            <Save style={{ color: '#fff' }} />
          </Button>
        </div>
        <div className="form-wrapper">
          <Paper style={{ padding: 10 }}>
            <Typography variant="subheading" align="left">
              Властивості пункту меню:
            </Typography>
          </Paper>
          <form className={classes.container} noValidate autoComplete="off">
            <div className={classes.titleWrapper}>
              <AppBar position="static" color="default">
                <Tabs
                  value={activeTab}
                  onChange={this.changeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  {languages.map(language => (
                    <Tab
                      key={`language-tab-${language.id}`}
                      label={language.title}
                    />
                  ))}
                </Tabs>
              </AppBar>
              {this.state.content
                .filter((el, index) => index === activeTab)
                .map((item, index) => (
                  <TextField
                    key={`title-item-${index}`}
                    id="standard-title"
                    label="Заголовок"
                    className={classes.textField}
                    value={item.title}
                    error={showError && (error && error.indexOf('title') > -1)}
                    onChange={this.changeLocalization('title')}
                    margin="normal"
                  />
                ))}
            </div>

            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="standard-type">Тип</InputLabel>
              <Select
                value={this.state.type}
                onChange={this.handleChange('type')}
                inputProps={{
                  name: 'type',
                  id: 'standard-type',
                }}
                style={{ textAlign: 'left' }}
              >
                <MenuItem value={1}>Посилання</MenuItem>
                <MenuItem value={3}>Сторінка</MenuItem>
              </Select>
            </FormControl>

            {this.state.type === 1 ? (
              <TextField
                id="standard-url"
                label="Посилання"
                className={classes.textField}
                value={this.state.url}
                error={showError && !this.state.url}
                onChange={this.handleChange('url')}
                margin="normal"
              />
            ) : (
              <FormControl className={classes.formControl}>
                <InputLabel
                  htmlFor="standard-type"
                  error={showError && !this.state.page}
                >
                  Сторінка
                </InputLabel>
                <Select
                  value={this.state.page || ''}
                  onChange={this.handleChange('page')}
                  inputProps={{
                    name: 'page',
                    id: 'standard-page',
                  }}
                  style={{ textAlign: 'left' }}
                >
                  {pages.collection
                    .filter(
                      el =>
                        !!el.page_localizations.length &&
                        el.is_active,
                    )
                    .map(item => (
                      <MenuItem key={`page-item-${item.id}`} value={item.id}>
                        {!!item.page_localizations.length &&
                          item.page_localizations[0].title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}

            <FormControlLabel
              control={
                <Switch
                  checked={this.state.searchable}
                  onChange={this.handleCheck('searchable')}
                  color="primary"
                />
              }
              label={
                this.state.searchable ? 'Наявна в пошуку' : 'Не наявна в пошуку'
              }
            />

            <TextField
              id="standard-priority"
              label="Пріоритет"
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
              className={classes.textField}
              value={this.state.priority}
              onChange={this.handleChange('priority')}
              margin="normal"
            />

            {!children.length && (
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="standard-searchable">
                  Батьківський пункт меню
                </InputLabel>
                <Select
                  value={this.state.parent ? this.state.parent : ''}
                  onChange={this.handleChange('parent')}
                  inputProps={{
                    name: 'parent',
                    id: 'standard-parent',
                  }}
                  style={{ textAlign: 'left' }}
                >
                  {menus
                    .filter(item => this.props.id !== item.id)
                    .map(item => (
                      <MenuItem key={`menu-parent-${item.id}`} value={item.id}>
                        {!!item.menu_localizations.length &&
                          item.menu_localizations[0].title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          </form>
        </div>

        {/* Всплывающее диалоговое окно по удалению локализации */}
        <ModalWindow
          open={open}
          title="Дійсно бажаєте видалити локалізацію?"
          onClose={this.handleCancel}
          onConfirm={() => this.deleteLocalizationMenu(this.props.id, this.state.content[activeTab].idLocal)}
        />

      </div>
    );
  }
}

export default withStyles(styles)(CreatePages);
