
import React from 'react';
import DatePicker from 'react-datepicker';

import uk from 'date-fns/locale/uk';

import { withStyles } from '@material-ui/core/styles';

import '../../assets/styles/categories.scss';
import '../../assets/styles/dropzone.scss';

import fetchOne from '../../services/fetch';
import { toISODate } from '../../services/main';
import { CORE_URL } from '../../constants/urls';
import { EDIT_ITEM } from '../../constants/types';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Delete from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch/Switch';
import Button from '@material-ui/core/Button/Button';

import { Save, Cancel } from '@material-ui/icons';

import CKComponent from '../CKEditor/CKComponent';
import Autocomplete from '../util/Autocomplete';
import DropZone from "../util/DropZone";
import store from "../../store";
import {REQUEST_MESSAGE} from "../../constants/redux";
import UploadFileComponent from '../util/UploadFileComponent/UploadFileComponent';
import ModalWindow from '../../views/layout/ModalWindow';

const styles = theme => ( {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '95%',
  },
  dense: {
    marginTop: 20,
  },
  menu: {
    width: '95%',
  },
  formControl: {
    margin: theme.spacing.unit,
    width: '95%',
    zIndex: 1,
  },
  tagsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
  },
  tagsList: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    listStyleType: 'none',
    padding: '10px 0',
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit,
  },
  tagsItem: {
    border: '1px solid',
    borderRadius: 5,
    marginRight: 5,
    padding: 5,
  },
  datePickerWrapper: {
    marginTop: 20,
    padding: '0 5px',
    width: '100%',
  },
});

const PageModel = {
  idLocal: '',
  localization: '',
  description: '',
  title: '',
};

class CreateNews extends React.Component {
  state = {
    active: true,
    category: '',
    photo: '',

    alias: '',
    publishAt: null,
    publishEndAt: null,

    type: 0,

    content: [],

    languages: [],
    activeTab: 0,

    errors: [],

    updated: [],

    newTags: {},
    tags: [],

    newTag: '',

    open: false,
  };

  componentDidMount() {
    this.getLanguages();
    this.getTags();
  }

  getTags = async () => {
    this.setState({
      tags: await fetchOne({ url: `${CORE_URL}/tags` }),
    });
  };

  getLanguages = async () => {
    const { id, type } = this.props;
    const languages = await fetchOne({ url: `${CORE_URL}/languages` });

    if (type === EDIT_ITEM) {
      const {
        news_localizations, photo,
        published_at, published_end_at, is_active, alias
      } = await fetchOne({ url: `${CORE_URL}/news/${id}` });

      let content = new Array(languages.length)
        .fill(PageModel)
        .map((item, i) => ({ ...item, localization: languages[i].id }));

      if (news_localizations.length) {
        content = content.map(item => {

          const page = news_localizations.find(el => item.localization === el.localization.id);

          if (page) {
            const { newTags } = this.state;
            const { title, description, tags } = page;
            const idLocal = page.id; // добавляем id локализации

            this.setState({
              newTags: {
                ...newTags,
                [item.localization]: tags,
              },
            });

            return { ...item, title, description, idLocal };
          }

          return item;
        });
      }

      this.setState({
        updated: news_localizations,

        publishEndAt: published_end_at ? new Date(published_end_at) : null,
        publishAt: published_at ? new Date(published_at) : null,

        active: is_active,
        alias,
        photo,

        languages,
        content,
      });
    } else {
      const content = new Array(languages.length)
        .fill(PageModel)
        .map((item, i) => ({ ...item, localization: languages[i].id }));
      this.setState({ languages, content });
    }
  };

  validateLocalization = () => {
    const { content } = this.state;
    const required = ['title', 'description'];

    const filtered = content.map(item => {
      const entries = Object.entries(item);
      return entries.reduce(
        (prev, [key, val]) =>
          typeof val === 'string' ? { ...prev, [key]: val } : prev,
        {},
      );
    });

    return filtered.map(item => {
      const entries = Object.entries(item);
      return entries.map(([key, val]) => required.indexOf(key) > -1 && !val ? key : '');
    });
  };

  submitPage = () => {
    const { alias } = this.state;
    const errors = this.validateLocalization();
    const isValid = errors.some(arr => arr.length ? arr.every(el => !el) : !arr.length);

    if (alias && isValid) this.createNews();
    this.setState({ errors, submited: true });
  };

  createNews = async () => {
    try {
      const {
        content, publishAt, alias,
        publishEndAt, active, photo,
      } = this.state;

      const isEdit = this.props.type === EDIT_ITEM;
      let method = isEdit ? 'PUT' : 'POST';
      let url = isEdit
        ? `${CORE_URL}/news/${this.props.id}`
        : `${CORE_URL}/news`;

      const pageBody = JSON.stringify({
        published_end_at: publishEndAt ? toISODate(publishEndAt) : null,
        published_at: publishAt ? toISODate(publishAt) : null,
        photo: photo && (photo.id || photo),
        is_active: active,
        alias: alias,
      });

      const createNews = await fetchOne({ url, method, body: pageBody });

      if (createNews.status === 201 || createNews.status === 204) {
        const valid = content.filter(item => item.title);
        const pageData = await createNews.json();
        this.setLocalizations(valid, pageData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  setLocalizations = (valid, pageData) => {
    let url;
    let method;
    const { content, newTags } = this.state;

    try {
      const toCompare = ['description', 'title'];
      const isUpdated = this.state.updated.map(item => {
        const destructed = Object.entries(item).reduce(
          (prev, [key, val]) => {
            const changed = content.find(el => el.localization === item.localization.id)[key];
            const condition = toCompare.indexOf(key) > -1 && changed !== val;
            return condition ? { ...prev, [key]: changed } : prev;
          },
          {},
        );
        return {
          ...destructed,
          local_id: item.id,
          id: item.localization.id,
        };
      });

      Promise.all(
        valid.map(async obj => {
          let localizationBody;
          const { description, title, localization } = obj;
          const itemToUpdate = isUpdated.find(el => el.id === localization);

          if (itemToUpdate) {
            const { id, local_id, ...updatedValues } = itemToUpdate;
            url = `${CORE_URL}/news/${id}/localizations/${local_id}`;
            localizationBody = JSON.stringify({
              ...updatedValues,
            });
            method = 'PUT';
          } else {
            url = `${CORE_URL}/news/${pageData.id}/localizations`;
            localizationBody = JSON.stringify({
              localization,
              description,
              title,
            });
            method = 'POST';
          }

          const response = await fetchOne({
            url,
            method,
            body: localizationBody,
          });
          const data = await response.json();

          const newsID = itemToUpdate ? itemToUpdate.id : pageData.id;
          const newsLocalID = itemToUpdate ? itemToUpdate.local_id : data.id;

          this.setTagsRequest(newsID, newsLocalID, newTags, localization);

          return data;
        }),
      )
      .then(this.props.onSuccess)
      // .then(this.props.update)
      .catch(error => console.log(error));
    } catch (error) {
      console.log(error);
    }
  };

  setTagsRequest = (id, localID, newTags, localization) => {
    if (Object.keys(newTags).length) {
      const merged = Object.entries(newTags).reduce(
        (prev, [key, val]) => [
          ...prev,
          ...val
            .map(item => ({
              ...item,
              localization: key,
            })),
        ],
        [],
      );
      console.log(newTags);
      console.log(merged);

      Promise.all(
        merged
          .filter(tag => tag.localization === localization)
          .map(tag => {
          const url = `${CORE_URL}/news/${id}/localizations/${localID}/tags`;
          const body = JSON.stringify({ id: tag.id });
          return fetchOne({ url, method: 'POST', body });
        }),
      ).catch(err => console.log(err));
    }
  };

  changeLocalization = name => event => {
    const { target } = event;
    const { activeTab, errors } = this.state;
    this.setState(state => ({
      content: [
        ...state.content
          .map((item, index) =>
            index === activeTab
              ? ({ ...item, [name]: target.value })
              : item,
          ),
      ],
    }));
    // при вводе текста в поле убираем из стэйта все ошибки касающиеся этого поля
    if (errors[activeTab]) {
      const updErrorsActiveTab = errors[activeTab].filter(el => el !== name);
      const copyStateErrors = [...errors];
      copyStateErrors[activeTab] = updErrorsActiveTab;
      this.setState({ errors: copyStateErrors });
    }
  };

  setDescription = description => {
    const { activeTab, errors } = this.state;
    this.setState(state => ({
      content: [
        ...state.content.map((item, index) =>
          index === activeTab ? { ...item, description } : item,
        ),
      ],
    }));

    // при вводе текста в поле убираем
    // из стэйта все ошибки касающиеся этого поля
    if (errors[activeTab]) {
      const updErrorsActiveTab = errors[activeTab].filter(el => el !== 'description');
      const copyStateErrors = [...errors];
      copyStateErrors[activeTab] = updErrorsActiveTab;
      this.setState({ errors: copyStateErrors });
    }
  };

  handleAliasChange = ({ target }) => {
    this.setState({
      alias: target.value,
    });
  };

  onStartTypeTag = event => {
    if (event.target.value.trim()) {
      this.setState({
        emptyTag: false,
        newTag: event.target.value,
      });
    } else {
      this.setState({ emptyTag: true });
    }
  };

  addTag = () => {
    const { newTag, newTags, languages, activeTab, tags } = this.state;

    const locID = languages[activeTab].id;
    const notSimilar = newTags[locID]
      ? newTags[locID].filter(el => el.title === newTag)
      : [];

    if (!newTag) return;
    if (!notSimilar.length) {
      const tagToAdd = tags.find(el => el.title === newTag);

      if (tagToAdd) {
        this.setState({
          newTags: {
            ...newTags,
            [locID]: newTags[locID]
              ? [...newTags[locID], tagToAdd]
              : [tagToAdd],
          },
          newTag: '',
        });
      }
    }
  };

  removeTag = async (locID, tagID) => {
    const { id, type } = this.props;
    const { newTags, updated } = this.state;

    const localLangID = updated[0] ? updated[0].id : null;

    if (type === EDIT_ITEM && localLangID) {
      const url = `${CORE_URL}/news/${id}/localizations/${localLangID}/tags/${tagID}`;
      const deleteTag = await fetchOne({ url, method: 'DELETE' });
      if (deleteTag.status === 204) {
        this.setState({
          newTags: {
            ...newTags,
            [locID]: newTags[locID].filter(item => tagID !== item.id),
          },
        });
      }
    } else {
      this.setState({
        newTags: {
          ...newTags,
          [locID]: newTags[locID].filter(item => tagID !== item.id),
        },
      });
    }
  };

  changeLocalizationImage = ({ id }) => this.setState({ photo: id, submited: false });

  changeNewTag = newTag => this.setState({ newTag }, this.addTag);

  handleCheck = name => event => this.setState({ [name]: event.target.checked });

  handleChange = name => ({ target }) => this.setState({ [name]: target.value, submited: false });

  handleDateChange = name => date => {
    console.log(new Date());
    this.setState({ [name]: date, submited: false })
  };

  changeTab = (event, value) => {
    this.setState({ activeTab: value });
  };

  // открытие модального окна
  openModal = () => this.setState({ open: true });
  // закрытие (отмена) модального окна по подтверждению удалиения локализации
  handleCancel = () => this.setState({ open: false });
  // DELETE, /api/v1/news/{id}/localizations/{localizationId}
  // Удалить существующую локализацию новости
  deleteLocalizationNews = async (id, localizationId) => {
    const { content } = this.state;
    if(content.filter(l => !!l.idLocal).length === 1){
      store.dispatch({
        type: REQUEST_MESSAGE,
        data: {
          message: 'Хоча б одна мова має бути заповнена!',
          type: 'error',
        }
      });
      return false;
    }
    const deleteLocalization = await fetchOne({
      url: `${CORE_URL}/news/${id}/localizations/${localizationId}`,
      method: 'DELETE',
    });

    if (deleteLocalization && deleteLocalization.ok) {
      this.getLanguages();
      this.getTags();
      this.handleCancel();
    } else {
      console.log(deleteLocalization.statusText)
      this.handleCancel();
    }
  };

  render() {
    const { classes } = this.props;
    const {
      publishEndAt, publishAt, photo, languages, alias, open,
      submited, activeTab, errors, newTag, newTags, active, content
    } = this.state;

    const error = errors[activeTab];

    const dis = content[activeTab] && content[activeTab].idLocal === '';

    return (
      <div className="category-create_wrapper">
        <div className="submit-button_wrapper">

          {/* кнопка удалить локализацию */}
          {this.props.type === "EDIT_ITEM" &&
          <Tooltip title="Видалити локализацію" placement="left"
                   onClick={this.openModal}
          >
            <Button
                    disabled={dis}
                    style={{ marginRight: '10px' }} variant="contained"
                    color="secondary">
              <Delete style={{ color: '#fff' }}/>
            </Button>
            </Tooltip>
          }
          {/* конец --- кнопка удалить локализацию */}

          <Button onClick={this.submitPage} variant="contained" color="primary">
            <Save style={{ color: '#fff' }} />
          </Button>
        </div>
        <div className="form-wrapper">
          <Paper style={{ padding: '11px 10px' }}>
            <Typography variant="subheading" align="left">
              Властивості новини:
            </Typography>
          </Paper>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              id="standard-alias"
              label="Alias"
              className={classes.textField}
              value={alias}
              error={!alias && submited}
              onChange={this.handleAliasChange}
              margin="normal"
            />

            <FormControl className={classes.formControl}>
              <Typography variant="subheading" align="left">
                Фото
              </Typography>
              {photo && (
                <img
                  style={{ marginBottom: 5 }}
                  src={photo.resize || photo.href}
                  alt=""
                />
              )}
              <DropZone handler={this.changeLocalizationImage} />
            </FormControl>

            <FormControlLabel
              control={
                <Switch
                  checked={active}
                  onChange={this.handleCheck('active')}
                  color="primary"
                />
              }
              label={active ? 'Активна' : 'Неактивна'}
            />

            <div className={classes.datePickerWrapper}>
              <Typography variant="subheading" align="left">
                Дата і час від:
              </Typography>
              <DatePicker
                selected={publishAt}
                showDisabledMonthNavigation
                onChange={this.handleDateChange('publishAt')}
                dateFormat="d MMMM, yyyy HH:mm"
                timeFormat="HH:mm"
                timeInputLabel="Час:"
                showTimeInput
                isClearable
                locale={uk}
              />
            </div>

            <div className={classes.datePickerWrapper}>
              <Typography variant="subheading" align="left">
                Дата і час до:
              </Typography>
              <DatePicker
                selected={publishEndAt}
                minDate={publishAt ? (new Date(publishAt)) : ''}
                showDisabledMonthNavigation
                onChange={this.handleDateChange('publishEndAt')}
                dateFormat="d MMMM, yyyy HH:mm"
                timeFormat="HH:mm"
                timeInputLabel="Час:"
                showTimeInput
                isClearable
                locale={uk}
              />
            </div>
          </form>

          <div style={{marginTop: '20px'}}><UploadFileComponent/></div>
        </div>

        <div className="form-wrapper form-big">
          <AppBar position="static" color="default">
            <Tabs
              value={activeTab}
              onChange={this.changeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              {languages.map(language => (
                <Tab
                  key={`language-tab-${language.id}`}
                  label={language.title}
                />
              ))}
            </Tabs>
          </AppBar>
          {this.state.content
            .filter((el, index) => index === activeTab)
            .map((item, index) => (
              <form
                key={`form-item-${index}`}
                className={classes.container}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="standard-title"
                  label="Заголовок"
                  className={classes.textField}
                  value={item.title}
                  error={error && error.some(el => el === 'title')}
                  onChange={this.changeLocalization('title')}
                  margin="normal"
                />

                <CKComponent
                  error={error && error.some(el => el === 'description')}
                  setDescription={this.setDescription}
                  description={item.description}
                />

                <div className={classes.tagsWrapper}>
                  <Autocomplete
                    suggestions={this.state.tags}
                    onStartTypeTag={this.onStartTypeTag}
                    changeNewTag={this.changeNewTag}
                    newTag={newTag}
                  />
                </div>
                <ul className={classes.tagsList}>
                  {newTags[item.localization] &&
                    newTags[item.localization].map((tag, i) => (
                      <li
                        key={`tag-item-${tag.id}-${i}`}
                        className={classes.tagsItem}
                      >
                        {tag.title}

                        <IconButton
                          style={{
                            marginLeft: 5,
                            padding: 0,
                          }}
                          onClick={() =>
                            this.removeTag(item.localization, tag.id)
                          }
                        >
                          <Cancel />
                        </IconButton>
                      </li>
                    ))}
                </ul>
              </form>
            ))}
        </div>

        {/* Всплывающее диалоговое окно по удалению локализации */}
        <ModalWindow
          open={open}
          title="Дійсно бажаєте видалити локалізацію?"
          onClose={this.handleCancel}
          onConfirm={() => this.deleteLocalizationNews(this.props.id, this.state.content[activeTab].idLocal)}
        />

      </div>
    );
  }
}

export default withStyles(styles)(CreateNews);
