import React from 'react';
import DropzoneComponent from 'react-dropzone-component';

import {withStyles} from '@material-ui/core/styles';

import '../../assets/styles/categories.scss';

import fetchOne from '../../services/fetch';
import {getTokenInfo} from '../../services/auth';
import {CORE_URL, DEPARTMENTS_PATH} from '../../constants/urls';
import {EDIT_ITEM} from '../../constants/types';

import FormControl from '@material-ui/core/FormControl';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import {Add, Delete, Save} from '@material-ui/icons';
import Button from '@material-ui/core/Button/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Switch from '@material-ui/core/Switch/Switch';
import {List, ListItem, ListItemText, MenuItem} from "@material-ui/core/es/index";
import {validateEmail} from "../../actions/common";
import Loader from "../util/Loader";
import Tooltip from "@material-ui/core/Tooltip";
import store from "../../store";
import {REQUEST_MESSAGE} from "../../constants/redux";
import ModalWindow from '../../views/layout/ModalWindow';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '95%',
  },
  dense: {
    marginTop: 0,
  },
  menu: {
    width: '95%',
  },
  formControl: {
    margin: theme.spacing.unit,
    width: '95%',
  },
  button: {
    margin: theme.spacing.unit,
  },
});

const componentConfig = {postUrl: `${CORE_URL}/files`};

const auth = getTokenInfo();
const djsConfig = {
  autoProcessQueue: false,
  maxFilesize: 3,
  timeout: 60 * 60 * 1000,
  addRemoveLinks: true,
  acceptedFiles: '.png, .jpg, .jpeg, .svg',
  dictDefaultMessage: 'Натисніть чи перетягніть файл для завантаження',
  dictCancelUpload: 'Відмінити',
  dictRemoveFile: 'Очистити',
  dictFileTooBig:
    'Файл має завеликий розмір {{filesize}} мб, максимальний - {{maxFilesize}} мб',
  dictInvalidFileType:
    'Невірний формат, доступні для завантаження: .png, .jpg, .jpeg, .svg',
  headers: {
    ...(auth && {
      Authorization: `${auth.token_type} ${auth.access_token}`,
    }),
  },
};

const EmployeeModel = {
  id: '',
  localization: {},
  description: '',
  link: '',
  name: '',
  surname: '',
  middle_name: '',
  first_address: '',
  second_address: '',
  contact: {
    contact_entries: []
  },
  newContactType: '',
  newContact: '',
  idLocal: '', // id локализации
};


class CreateEmployee extends React.Component {
  state = {
    open: false,

    id: '',
    photo: '',
    file: '',
    photoHeight: '',
    photoWidth: '',
    is_active: true,

    languages: [],
    activeTab: 0,
    content: [],

    errors: [],

    updated: [],

    submited: false,
    loading: true,
  };

  componentDidMount() {
    this.getLanguages();
  }

  getLanguages = async () => {
    const {id, type} = this.props;
    const languages = await fetchOne({url: `${CORE_URL}/languages`});

      if (type === EDIT_ITEM) {
      const {
        employee_localizations,
        photo,
        is_active,
      } = await fetchOne({url: `${CORE_URL}/employees/${id}`});

      let content = new Array(languages.length)
        .fill(EmployeeModel)
        .map((item, i) => ({...item, localization: languages[i].id}));

      if (employee_localizations.length) {

        content = content.map(item => {
          const employee = employee_localizations
            .find(el => item.localization === el.localization.id);
          if (employee) {
            const {id, name, link, surname, middle_name, description, first_address, second_address, contact} = employee;
            const idLocal = employee.id; // добавляем id локализации
            return {
              ...item,
              local_active: is_active,
              id, link, surname, middle_name, name, description, first_address, second_address, idLocal,
              contact: contact || {contact_entries: []},
              newContactType: '',
              newContact: '',
            };
          }

          return item;
        });
      }

      this.setState({
        updated: employee_localizations,
        is_active: is_active,
        photo,
        photoHeight: '',
        photoWidth: '',

        languages,
        content,
        loading: false,
      });
    } else {
      const content = new Array(languages.length)
        .fill(EmployeeModel)
        .map((item, i) => ({...item, localization: languages[i].id}));
      this.setState({languages, content, loading: false});
    }
  };

  validateLocalization = () => {
    const {content} = this.state;
    const required = ['surname', 'name'];

    const filtered = content.map(item => {
      const entries = Object.entries(item);
      return entries.reduce((prev, [key, val]) =>
        typeof val === 'string' ? {...prev, [key]: val} : prev, {});
    });

    return filtered.map(item => {
      const entries = Object.entries(item);
      return entries.map(([key, val]) => (required.indexOf(key) > -1) && !val ? key : '');
    });
  };

  submitPage = async () => {
   (!this.dropzone.files || !this.dropzone.files.length) ? this.saveEmployee() :
     await this.dropzone.processQueue();
  };

  saveEmployee = () => {
    const errors = this.validateLocalization();
    const isValid = errors.some(arr =>
      arr.length ? arr.every(el => !el) : !arr.length);

    if (isValid) this.createEmployee();
    this.setState({errors, submited: true});
  };

  createEmployee = async () => {
    try {
      const {
        content,
        photo,
        is_active,
      } = this.state;

      const isEdit = this.props.type === EDIT_ITEM;
      let method = isEdit ? 'PUT' : 'POST';
      let url = isEdit
        ? `${CORE_URL}/employees/${this.props.id}`
        : `${CORE_URL}/employees`;

      const pageBody = JSON.stringify({
        photo: photo && (photo.id || photo),
        is_active: is_active,
      });

      const createPage = await fetchOne({url, method, body: pageBody});

      if (createPage.status === 201 || createPage.status === 204) {
        const valid = content.filter(item => item.name);
        const pageData = await createPage.json();
        try {
          const toCompare = ['is_active', 'description', 'name', 'middle_name', 'surname', 'link', 'first_address', 'second_address'];
          const isUpdated = this.state.updated.map(item => {
            const destructed = Object.entries(item).reduce(
              (prev, [key, val]) => {
                const changed = content.find(
                  el => el.localization === item.localization.id,
                )[key];
                const condition =
                  toCompare.indexOf(key) > -1 && changed !== val;
                return condition ? {...prev, [key]: changed} : prev;
              },
              {},
            );
            return {
              ...destructed,
              local_id: item.id,
              id: item.localization.id,
            };
          });

          Promise.all(
            valid.map(async obj => {
              let localizationBody;
              const {
                description, name, surname, middle_name, link, first_address, second_address, localization
              } = obj;
              const item = isUpdated.find(el => el.id === localization);

              if (item) {
                const {id, local_id, ...updatedValues} = item;
                url = `${CORE_URL}/employees/${id}/localizations/${local_id}`;
                localizationBody = JSON.stringify({
                  ...updatedValues,
                });
                method = 'PUT';
              } else {
                url = `${CORE_URL}/employees/${pageData.id}/localizations`;
                localizationBody = JSON.stringify({
                  localization,
                  description,
                  first_address,
                  second_address,
                  name, surname, middle_name, link
                });
                method = 'POST';
              }

              const response = await fetchOne({
                url,
                method,
                body: localizationBody,
              });

              const data = await response.json();

              this.setState(state => ({
                content: [
                  ...state.content
                    .map(item => item.localization === data.localization.id
                      ? ({
                        ...item, id: data.id,
                      })
                      : item)
                ],
              }));

              const contact = await this.createContact(data.id);

              await this.createEntries(contact);

              return data;
            }),
          )
            .catch(error => console.log(error));
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  createContact = async (id) => {
    // create contacts for localizations
    try {
      const item = this.state.content.find(item => item.id === id);

      let contact_id;
      let entries;
      if (item.contact &&
        item.contact.contact_entries &&
        item.contact.contact_entries.length) {

        let contact_data = item.contact;

        if (!contact_data.id) {
          const url = `${CORE_URL}/contacts`;
          const contactData = JSON.stringify({type: 'employee', internal: item.id});
          const method = 'POST';

          const response = await fetchOne({
            url,
            method,
            body: contactData,
          });
          contact_data = await response.json();
        }

        contact_id = contact_data.id;
        entries = item.contact.contact_entries;
      }

      return ({contact_id: contact_id, entries: entries});
    }
    catch (error) {
      console.log(error);
    }

  };

  createEntries = async (contact) => {

    try {
      if (!contact.contact_id) {
        this.props.update();
        return;
      }

      await Promise.all(
        contact.entries.map(async obj => {
          if (!obj.id) {
            const url = `${CORE_URL}/contacts/${contact.contact_id}/entries`;
            const method = 'POST';
            const contactDataEntries = JSON.stringify({[obj.type]: obj.value});

            const response = await fetchOne({
              url,
              method,
              body: contactDataEntries,
            });

            const data = await response.json();
            return data;
          }
        })).then(() => this.props.update())
        .catch(error => {
          console.log(error);
          this.setState({ submited: true });
        });
    }
    catch (error) {
      console.log(error);
      this.setState({ submited: true });
    }

  };

  changeLocalization = name => event => {
    const {target} = event;
    const {activeTab} = this.state;
    this.setState(state => ({
      content: [
        ...state.content
          .map((item, index) => index === activeTab
            ? ({...item, [name]: target.value})
            : item)
      ],
      submited: false,
    }));
  };

  handleCheck = name => event =>
    this.setState({[name]: event.target.checked});

  handleChange = name => ({target}) => {
    if (name === 'photoWidth' || name === 'photoHeight') {
      const ratio = this.state.file.width / this.state.file.height;
      const val = target.value;

      if (name === 'photoWidth') {
        this.setState({photoHeight: (val / ratio).toFixed(2)});
      }
      if (name === 'photoHeight') {
          this.setState({photoWidth: (val * ratio).toFixed(2)});
      }

      this.setState({[name]: val, submited: false});
      return;
    }

    this.setState({[name]: target.value, submited: false});
  };

  changeTab = (event, value) => this.setState({activeTab: value});

  handleAddContact = (event, el) => {
    event.preventDefault();

    if (el.newContactType && el.newContact) {

      if (el.newContactType === 'email'&& !validateEmail(el.newContact)) {
        el.error = true;

        this.setState(state => ({
          content: [
            ...state.content
              .map(item => item.localization === el.localization
                ? ({
                  ...item,
                  error: true
                })
                : item)
          ],
        }));

        return;
      }

      if (el.contact.contact_entries.length < 7) {

        this.setState(state => ({
          content: [
            ...state.content
              .map(item => item.localization === el.localization
                ? ({
                  ...item, contact: {
                    ...item.contact,
                    contact_entries: [
                      ...item.contact.contact_entries,
                      {
                        type: item.newContactType,
                        value: item.newContact
                      }
                    ]
                  },
                  error: false,
                  newContactType: '',
                  newContact: ''
                })
                : item)
          ],
        }));
      }
    }
  };

  handleDeleteContact = async (event, item, contact) => {
    event.preventDefault();

    let index;

    if (contact.id) {
      try {
        await fetchOne({
          url: `${CORE_URL}/contacts/${item.id}/entries/${contact.id}`,
          method: 'DELETE',
        });
        index = item.contact.contact_entries.findIndex(obj => obj.id === contact.id);
      } catch (err) {
        console.log(err.message);
      }
    } else {
      index = item.contact.contact_entries.findIndex(obj => obj.value === contact.value && obj.type === contact.type);
    }

    item.contact.contact_entries.splice(index, 1);

    this.setState({content: this.state.content});
  };

  // открытие модального окна
  openModal = () => this.setState({ open: true });
  // закрытие (отмена) модального окна по подтверждению удалиения локализации
  handleCancel = () => this.setState({ open: false });

  deleteLocalizationEmployee = async (id, localizationId) => {
    const { content } = this.state;
    if(content.filter(l => !!l.idLocal).length === 1){
      store.dispatch({
        type: REQUEST_MESSAGE,
        data: {
          message: 'Хоча б одна мова має бути заповнена!',
          type: 'error',
        }
      });
      return false;
    }
    const deleteLocalization = await fetchOne({
      url: `${CORE_URL}/employees/${id}/localizations/${localizationId}`,
      method: 'DELETE',
    });

    if (deleteLocalization && deleteLocalization.ok) {
      this.getLanguages();
      this.handleCancel();
    } else {
      console.log(deleteLocalization.statusText);
      this.handleCancel();
    }
  };

  render() {
    const {classes, type} = this.props;
    const {
      open,
      languages,
      activeTab,
      errors,
      file,
      photo,
      photoHeight,
      photoWidth,
      is_active,
      content,
      submited,
      loading
    } = this.state;
    const error = errors[activeTab];

    const eventHandlers = {
      init: dz => this.dropzone = dz,
      addedfile: (file) => {
        this.setState({file: file});
      },
      removedfile: () => {
        this.setState({file: ''});
      },
      sending: (file, xhr, formData) => {
        if (this.state.photoWidth && this.state.photoWidth > 1) {
          formData.append('width', this.state.photoWidth);
         }
        if (this.state.photoHeight && this.state.photoHeight> 1) {
          formData.append('height', this.state.photoHeight);
         }
      },
      success: ({xhr}) => {
        const {id} = JSON.parse(xhr.response);
        this.setState({photo: id});

        this.saveEmployee();
      },
    };

    if (loading) return (<Loader/>);

    const dis = content[activeTab] && content[activeTab].idLocal === '';

    return (
      <div className="category-create_wrapper">

        {/* Всплывающее диалоговое окно по удалению локализации */}
        <ModalWindow
          open={open}
          title="Дійсно бажаєте видалити локалізацію?"
          onClose={this.handleCancel}
          onConfirm={() => this.deleteLocalizationEmployee(this.props.id, this.state.content[activeTab].idLocal)}
        />

        <div className="submit-button_wrapper">

          {/* кнопка удалить локализацию */}
          {this.props.type === "EDIT_ITEM" &&
          <Tooltip title="Видалити локализацію" placement="left"
                   onClick={this.openModal}>
            <Button disabled={dis}
                    style={{ marginRight: '10px' }} variant="contained"
                    color="secondary">
              <Delete style={{ color: '#fff' }}/>
            </Button>
          </Tooltip>
          }
          {/* конец --- кнопка удалить локализацию */}

          <Button
            onClick={() => this.submitPage()}
            variant="contained"
            color="primary"
          >
            <Save style={{color: '#fff'}}/>
          </Button>
        </div>
        <div className="form-wrapper">
          <Paper style={{padding: '11px 10px'}}>
            <Typography variant="subheading" align="left">
              Властивості працівника:
            </Typography>
          </Paper>
          <form className={classes.container} noValidate autoComplete="off">

            <FormControl className={classes.formControl}>
              <Typography variant="subheading"
                          style={{
                              color: (!photo && submited) ? '#f44336' : '#000',
                              textAlign: 'left',
                          }}>
                Фото
              </Typography>
              {photo && (photo.resize || photo.href) && (
                <img
                  style={{
                    marginBottom: 5,
                    width: '100%',
                    objectFit: 'contain',
                    minWidth: '100px'
                  }}
                  src={photo.resize || photo.href}
                  alt="Зображення"
                />
              )}
              <DropzoneComponent
                config={componentConfig}
                eventHandlers={eventHandlers}
                djsConfig={djsConfig}
              />
            </FormControl>

            {file &&
              <React.Fragment>
                <TextField
                  id="standard-title"
                  label="Ширина (px)"
                  className={classes.textField}
                  type="number"
                  InputProps={{ inputProps: { min: 40, max: 1900 } }}
                  value={photoWidth}
                  onChange={this.handleChange('photoWidth')}
                  margin="normal"
                />

                < TextField
                  id="standard-title"
                  label="Висота (px)"
                  className={classes.textField}
                  type="number"
                  InputProps={{ inputProps: { min: 40, max: 1900 } }}
                  value={photoHeight}
                  onChange={this.handleChange('photoHeight')}
                  margin="normal"
                />
              </React.Fragment>
            }

            <FormControlLabel
              control={
                <Switch
                  checked={is_active}
                  onChange={this.handleCheck('is_active')}
                  color="primary"
                />
              }
              label={is_active ? 'Активний' : 'Неактивний'}
            />
          </form>
        </div>

        <div className="form-wrapper form-big">
          <AppBar position="static" color="default">
            <Tabs
              value={activeTab}
              onChange={this.changeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              {languages.map(language => (
                <Tab
                  key={`language-tab-${language.id}`}
                  label={language.title}
                />
              ))}
            </Tabs>
          </AppBar>
          {content
            .filter((el, index) => index === activeTab)
            .map((item, index) => (
              <form
                style={{overflow: 'hidden'}}
                key={`form-item-${index}`}
                className={classes.container}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="standard-title"
                  label="Посилання"
                  className={classes.textField}
                  value={item.link}
                  error={submited && error && error.some(el => el === 'link')}
                  onChange={this.changeLocalization('link')}
                  margin="normal"
                />
                <TextField
                  id="standard-title"
                  label="Прізвище"
                  className={classes.textField}
                  value={item.surname}
                  error={submited && error && error.some(el => el === 'surname')}
                  onChange={this.changeLocalization('surname')}
                  margin="normal"
                />
                <TextField
                  id="standard-title"
                  label="Імʼя"
                  className={classes.textField}
                  value={item.name}
                  error={submited && error && error.some(el => el === 'name')}
                  onChange={this.changeLocalization('name')}
                  margin="normal"
                />
                <TextField
                  id="standard-title"
                  label="По батькові"
                  className={classes.textField}
                  value={item.middle_name}
                  error={submited && error && error.some(el => el === 'middle_name')}
                  onChange={this.changeLocalization('middle_name')}
                  margin="normal"
                />

                <div style={{
                  width: '100%',
                  textAlign: 'left',
                  padding: '15px 18px 0',
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
                }}>
                  Опис:
                </div>
                <TextField
                  name="description"
                  id="description"
                  multiline={true}
                  rows={4}
                  rowsMax={5}
                  variant="outlined"
                  margin="normal"
                  onChange={this.changeLocalization('description')}
                  value={item.description}
                  fullWidth
                />

                <div style={{
                  width: '100%',
                  textAlign: 'left',
                  padding: '15px 18px',
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
                }}>
                  Адреса:
                </div>
                <div style={{
                  border: '1px solid #bcbcbc',
                  width: '100%'
                }}>
                  <TextField
                    id="standard-first-address"
                    className={classes.textField}
                    value={item.first_address}
                    label="Рядок 1"
                    error={error && error.some(el => el === 'first_address')}
                    onChange={this.changeLocalization('first_address')}
                    margin="normal"
                  />
                  <TextField
                    id="standard-second-address"
                    className={classes.textField}
                    label="Рядок 2"
                    value={item.second_address}
                    error={error && error.some(el => el === 'second_address')}
                    onChange={this.changeLocalization('second_address')}
                    margin="normal"
                  />
                </div>

                <div style={{
                  width: '100%',
                  textAlign: 'left',
                  padding: '15px 18px',
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
                }}>
                  Список контактів (до 7 контактів):
                </div>
                <div style={{
                  border: '1px solid #bcbcbc',
                  width: '100%'
                }}>

                  {!!item.contact && !!item.contact.contact_entries && !!item.contact.contact_entries.length &&
                  <div>
                    <List>
                      {item.contact.contact_entries.map((cnt, i) => (
                        <ListItem style={{
                          padding: '0 16px'
                        }}
                                  key={`contact-row-${i}`}>
                          <ListItemText primary={`${cnt.type}:  ${cnt.value}`}/>
                          <Button onClick={(e) => this.handleDeleteContact(e, item, cnt)} color="primary">
                            <Delete style={{color: '#afafaf'}}/>
                          </Button>
                        </ListItem>
                      ))}
                    </List>
                  </div>
                  }

                  <div style={{
                    marginLeft: '11px',
                    textAlign: 'left',
                  }}>

                    <TextField
                      style={{
                        margin: '5px 8px',
                        width: '40%',
                      }}
                      select
                      label="Тип контакту"
                      name="type"
                      id="type"
                      value={item.newContactType}
                      onChange={this.changeLocalization('newContactType')}
                    >
                      <MenuItem value={'email'}>Email</MenuItem>
                      <MenuItem value={'fax'}>Факс</MenuItem>
                      <MenuItem value={'phone'}>Телефон</MenuItem>
                    </TextField>

                    <TextField
                      name="entry"
                      id="entry"
                      margin="dense"
                      label="Контакт"
                      type="input"
                      error={item.error}
                      value={item.newContact}
                      onChange={this.changeLocalization('newContact')}
                      style={{width: '40%', marginLeft: '3%', marginRight: '3%'}}
                    />

                    <Button
                      variant="contained"
                      color="primary"
                      style={{margin: '18px 0 8px'}}
                      disabled={!(item.newContact && item.newContactType)}
                      onClick={(e) => this.handleAddContact(e, item)}
                    >
                      <Add style={{color: '#fff'}}/>
                    </Button>
                  </div>

                </div>

              </form>
            ))}
        </div>

      </div>
    );
  }
}

export default withStyles(styles)(CreateEmployee);
