import React, { Component } from 'react';
import '../../assets/styles/nav_components/main.scss';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import {validateEmail} from "../../actions/common";
import {updateCustomer} from "../../actions/accounts";
import {connect} from "react-redux";

class EditAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: props.object.username,
      email: props.object.email,
      full_name: props.object.full_name,
      is_active: props.object.is_active,
      password: '',
      curId: props.object.id,
    }
  }

  onChangeCheckbox = () => this.setState(state => ({ is_active: !state.is_active }));

  onChange = (e) => {
    const { target: { name, value } } = e;

    if (value.trim() !== '') {
      if (name === 'email') {
        if (validateEmail(value)) {this.setState({ [name]: value });}
      } else {
        this.setState({ [name]: value });
      }
    }
  };

  onUpdateSubmit = (e) => {
    e.preventDefault();
    const obj = {
      username: this.state.username,
      email: this.state.email,
      full_name: this.state.full_name,
      is_active: this.state.is_active,
    };
    if(this.state.password !== '') {
      obj['password'] = this.state.password
    }
    this.props.updateCustomerData(obj, this.state.curId);
    this.setState({ openUpdate: false, curId: '', selected: []});
  };

  render() {
    const {username, email, full_name, password, is_active} = this.state;

console.log(this.state);
    return (
      <React.Fragment>
        <div className="tab-block-content">

          <div className="show-block">
            <div className="show-block-form">
              <TextField
                autoFocus
                name="username"
                margin="dense"
                id="name"
                label="Іʼмя"
                type="input"
                onChange={this.onChange}
                value={username}
                error={false}
                fullWidth
              />

              <TextField
                autoFocus
                name="full_name"
                margin="dense"
                id="type"
                label="ПІБ"
                type="input"
                onChange={this.onChange}
                value={full_name}
                fullWidth
              />

              <TextField
                autoFocus
                name="email"
                margin="dense"
                id="email"
                label="Email"
                type="email"
                onChange={this.onChange}
                value={email}
                fullWidth
              />

              <TextField
                autoFocus
                name="password"
                margin="dense"
                id="password"
                label="Змінити пароль"
                type="input"
                onChange={this.onChange}
                fullWidth
              />

              <div className="account-submit-block">
                <div>
                  {is_active ? 'Активний' : 'Неактивний'}
                  <Checkbox
                    checked={is_active}
                    disabled={is_active ? true : false}
                    color="primary"
                    onClick={() => this.onChangeCheckbox()}
                  />
                </div>

                <Button variant="contained" size="small" className="" onClick={(e) => this.onUpdateSubmit(e)}>
                  Зберегти
                </Button>
              </div>

            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateCustomerData: (data, id) => {
    dispatch(updateCustomer(data, id))
  }
});

export default connect(null, mapDispatchToProps)(EditAccount);
