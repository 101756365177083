import React from 'react';

import '../../assets/styles/nav_components/main.scss';
import {withStyles} from '@material-ui/core/styles';
import {PER_PAGE, PER_PAGE_ARR} from "../../constants/data";
import {EDIT_ITEM} from "../../constants/types";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button/Button';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';

import {Cancel, Delete, Done, Edit} from '@material-ui/icons';
import {tableHeadSort} from "../../services/sort";
import EnhancedTableHead from "../util/EnhancedTableHead";


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  fullBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
});

const desc = tableHeadSort('');

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const locale = ({employee_localizations}) => {
  // const ukr_system_contacts = employee_localizations.filter(localization => localization.localization.locale === 'uk');
  const ukr_system_contacts = employee_localizations.sort((a, b) => a.localization.priority - b.localization.priority);
  return !!employee_localizations.length ? ukr_system_contacts[0] : {};
};

const rows = [
  {id: 'image', numeric: false, disablePadding: false, label: 'Фото', sort: false},
  {id: 'name', numeric: false, disablePadding: false, label: 'ПІБ', sort: false},
  {id: 'link', numeric: false, disablePadding: false, label: 'Посилання', sort: false},
  {id: 'address', numeric: false, disablePadding: false, label: 'Адреса', sort: false},
  {id: 'contacts', numeric: false, disablePadding: false, label: 'Контакти', sort: false},
  {id: 'is_active', numeric: false, disablePadding: false, label: 'Статус', sort: true},
];

class EnhancedTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'priority',
    selected: [],

    page: 0,
    rowsPerPage: PER_PAGE,

    data: this.props.data,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data !== state.data) {
      return {
        selected: [],
        data: props.data,
      };
    }
    return null;
  }

  goToEdit = (id, type) => this.props.onEdit(id, type);

  toggleOnlyActive = () =>
    this.setState(state => ({onlyActive: !state.onlyActive}));

  deleteItem = id => this.props.openModal(id);

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({order, orderBy});
  };

  handleChangePage = (event, page) => this.setState({page});

  handleChangeRowsPerPage = event =>
    this.setState({rowsPerPage: event.target.value});

  render() {
    const {classes} = this.props;
    const {data, order, orderBy, rowsPerPage, page, onlyActive} = this.state;
    let employeesData = onlyActive ? data.filter(item => item.is_active) : data;

      return (
      <div className="tab-block-content no-shadow">
        <div className={classes.fullBetween}>
          <FormControlLabel
            control={
              <Switch
                checked={onlyActive}
                onChange={this.toggleOnlyActive}
                color="primary"
              />
            }
            label={onlyActive ? 'Тільки активні' : 'Всі'}
          />
        </div>
        {!!employeesData.length && (
          <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
              <Table className={classes.table} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  rows={rows}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                />
                <TableBody>
                  {stableSort(employeesData, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(n => {
                      return (
                        <TableRow
                          key={`system-contact-${locale(n).id}`}
                          hover
                          role="checkbox">
                          <TableCell>
                            <img
                              src={n.photo && (n.photo.resize || n.photo.href)}
                              className="departments-table-image"
                              alt=""
                            />
                          </TableCell>
                          <TableCell>{`${locale(n).surname} ${locale(n).name} ${locale(n).middle_name}`}</TableCell>
                            <TableCell style={{
                                maxWidth: '300px',
                                overflow: 'auto'
                            }}
                            >{locale(n).link}</TableCell>
                          <TableCell>
                            <div>
                              <p>{locale(n).first_address}</p>
                            </div>
                            <div>
                              <p>{locale(n).second_address}</p>
                            </div>
                          </TableCell>
                          <TableCell>
                            {locale(n).contact && locale(n).contact.contact_entries.map((c, i) => (
                              <div key={`department-contact-${i}`}>
                                <p>{`${c.type}: ${c.value}`}</p>
                              </div>
                            ))}
                          </TableCell>
                          <TableCell padding="checkbox">
                            {n.is_active ? <Done/> : <Cancel/>}
                          </TableCell>
                          <TableCell padding="checkbox">
                            <Button
                              onClick={() => this.deleteItem(n.id)}
                              color="primary"
                            >
                              <Delete style={{color: '#afafaf'}}/>
                            </Button>
                          </TableCell>
                          <TableCell padding="checkbox">
                            <Button
                              onClick={() => this.goToEdit(n.id, EDIT_ITEM)}
                              color="primary"
                            >
                              <Edit style={{color: '#afafaf'}}/>
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={PER_PAGE_ARR}
              component="div"
              count={employeesData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Попередня сторінка',
              }}
              nextIconButtonProps={{
                'aria-label': 'Наступна сторінка',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              labelRowsPerPage='Рядків на сторінку:'
              labelDisplayedRows= {({ from, to, count }) => `${from}-${to} з ${count}`}
            />
          </Paper>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(EnhancedTable);

