import {
  ALL_EDITIONS_DOWNLOADED,
  DELETE_EDITION_FAIL,
  DELETE_EDITION_SUCCESS,
  ALL_BOOK_REVIEWS_DOWNLOADED,
  DELETE_BOOK_REVIEW_SUCCESS,
  DELETE_BOOK_REVIEW_FAIL,
  ALL_BOOK_ORDERS_DOWNLOADED,
  DELETE_BOOK_ORDER_SUCCESS,
  DELETE_BOOK_ORDER_FAIL
} from '../constants/redux';
import {GET_ALL_EDITIONS, GET_BOOK_ORDERS, GET_BOOK_REVIEWS} from '../constants/urls';
import { getErrors } from './errors';
import fetchOne from "../services/fetch";

export function editionsDownloaded(editions) {
  return { type: ALL_EDITIONS_DOWNLOADED, payload: editions };
}

export const editionDeleteSuccess = () => ({
  type: DELETE_EDITION_SUCCESS
});

export const editionDeleteFail = () => ({
  type: DELETE_EDITION_FAIL
});

export function reviewsDownloaded(reviews) {
  return { type: ALL_BOOK_REVIEWS_DOWNLOADED, payload: reviews };
}

export const reviewDeleteSuccess = () => ({
  type: DELETE_BOOK_REVIEW_SUCCESS
});

export const reviewDeleteFail = () => ({
  type: DELETE_BOOK_REVIEW_FAIL
});

export function ordersDownloaded(orders) {
  return { type: ALL_BOOK_ORDERS_DOWNLOADED, payload: orders };
}

export const orderDeleteSuccess = () => ({
  type: DELETE_BOOK_ORDER_SUCCESS
});

export const orderDeleteFail = () => ({
  type: DELETE_BOOK_ORDER_FAIL
});

export function fetchEditions(page, limit, order, orderBy) {
  return async function (dispatch) {
    try {
      const editions = await fetchOne({
        url: `${GET_ALL_EDITIONS}?page=${page}&limit=${limit}&orderBy=${order}&sortBy=${orderBy}`,
      });
      dispatch(editionsDownloaded(editions));
    } catch (err) {
      console.log(err.message);
      dispatch(getErrors('Some error: ' + err.message));
    }
  };
}

export function fetchReviews(page, limit, order, orderBy) {
  return async function (dispatch) {
    try {
      const reviews = await fetchOne({
        url: `${GET_BOOK_REVIEWS}?page=${page}&limit=${limit}&orderBy=${order}&sortBy=${orderBy}`,
      });
      dispatch(reviewsDownloaded(reviews));
    } catch (err) {
      console.log(err.message);
      dispatch(getErrors('Some error: ' + err.message));
    }
  };
}

export function fetchOrders(page, limit, order, orderBy) {
  return async function (dispatch) {
    try {
      const orders = await fetchOne({
        url: `${GET_BOOK_ORDERS}?page=${page}&limit=${limit}&orderBy=${order}&sortBy=${orderBy}`,
      });
      dispatch(ordersDownloaded(orders));
    } catch (err) {
      console.log(err.message);
      dispatch(getErrors('Some error: ' + err.message));
    }
  };
}

export function delEdition(id, page, limit, order, orderBy) {
  return async function (dispatch) {
    try {
      const edition = await fetchOne({
        url: `${GET_ALL_EDITIONS}/${id}`,
        method: 'DELETE'
      });
      dispatch(editionDeleteSuccess());
      dispatch(fetchEditions(page, limit, order, orderBy));
      console.log(edition);
    } catch (err) {
      console.log(err.message);
      dispatch(getErrors('Some error: ' + err.message));
      dispatch(editionDeleteFail());
    }
  };
}

export function delReview(id, page, limit, order, orderBy) {
  return async function (dispatch) {
    try {
      const edition = await fetchOne({
        url: `${GET_BOOK_REVIEWS}/${id}`,
        method: 'DELETE'
      });
      dispatch(reviewDeleteSuccess());
      dispatch(fetchReviews(page, limit, order, orderBy));
      console.log(edition);
    } catch (err) {
      console.log(err.message);
      dispatch(getErrors('Some error: ' + err.message));
      dispatch(reviewDeleteFail());
    }
  };
}

export function delOrder(id, page, limit, order, orderBy) {
  return async function (dispatch) {
    try {
      const edition = await fetchOne({
        url: `${GET_BOOK_ORDERS}/${id}`,
        method: 'DELETE'
      });
      dispatch(orderDeleteSuccess());
      dispatch(fetchOrders(page, limit, order, orderBy));
      console.log(edition);
    } catch (err) {
      console.log(err.message);
      dispatch(getErrors('Some error: ' + err.message));
      dispatch(orderDeleteFail());
    }
  };
}