import React, { useState, useEffect } from 'react';
import { DropzoneComponent } from 'react-dropzone-component';
import { getTokenInfo } from '../../services/auth';
import { CORE_URL } from '../../constants/urls';

// // информация по авторизации
// const auth = getTokenInfo();
//
// // Конфиги
// const componentConfig = { postUrl: `${CORE_URL}/files` };
// const djsConfig = {
//   autoProcessQueue: true,
//   maxFilesize: 5, // увеличили размер файла до 5 Мб
//   timeout: 60 * 60 * 1000,
//   addRemoveLinks: true,
//   acceptedFiles: '.png, .jpg, .jpeg, .svg',
//   dictDefaultMessage: 'Натисність чи перетягніть файл для завантаження',
//   dictCancelUpload: 'Відмінити',
//   dictRemoveFile: 'Очистити',
//   dictFileTooBig:
//     'Файл має завеликий розмір {{filesize}} мб, максимальний - {{maxFilesize}} мб',
//   dictInvalidFileType:
//     'Невірний формат, доступні для завантаження лиш: .png, .jpg, .jpeg, .svg',
//   headers: {
//     ...(auth && {
//       Authorization: `${auth.token_type} ${auth.access_token}`,
//     }),
//   },
// };

// оригинальный DropzoneComponent в качестве хэндлера принимает объект с ключем success и колбэком
// который в принимает response в качестве аргумента
// например:
//
// const eventHandlers = {
//   success: ({ xhr }) => {
//     const { id } = JSON.parse(xhr.response);
//     this.setState({ icon: id });
//   },
// };


// принимает один пропс, handler - колбэк с аргументом ОТВЕТ API сервера
const DropZone = (props) => {

  // информация по авторизации
  const auth = getTokenInfo();

  // Конфиги
  const componentConfig = { postUrl: `${CORE_URL}/files` };
  const djsConfig = {
    autoProcessQueue: true,
    createImageThumbnails: true,
    maxFilesize: 5, // увеличили размер файла до 5 Мб
    timeout: 60 * 60 * 1000,
    addRemoveLinks: true,
    acceptedFiles: '.png, .jpg, .jpeg, .svg',
    dictDefaultMessage: 'Натисність чи перетягніть файл для завантаження',
    dictCancelUpload: 'Відмінити',
    dictRemoveFile: 'Очистити',
    dictFileTooBig:
      'Файл має завеликий розмір {{filesize}} мб, максимальний - {{maxFilesize}} мб',
    dictInvalidFileType:
      'Невірний формат, доступні для завантаження лиш: .png, .jpg, .jpeg, .svg',
    headers: {
      ...(auth && {
        Authorization: `${auth.token_type} ${auth.access_token}`,
      }),
    },
  };

  let drop = null;

  return (
  <DropzoneComponent
    config={componentConfig}
    eventHandlers={{
      init: (obj) => {
        drop = obj;
      },
      success: ({ xhr }) => {
        const res = JSON.parse(xhr.response);
        props.handler(res);
      },
      complete: (f) => {
        if (props.removeFile) {
          drop.removeFile(f);
        }
      }
    }}
    djsConfig={{...djsConfig, ...props.djsConfig}}
  />
  )
};

export default DropZone;
