import {
  ALL_BANNERS_DOWNLOADED,
} from '../constants/redux';

const initialState = [];

const banners = (state = initialState, action) => {
  switch (action.type) {
    case ALL_BANNERS_DOWNLOADED:
      return action.payload
    default:
      return state;
  }
};

export default banners;
