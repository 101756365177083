import React, { Component } from 'react';
import '../../assets/styles/sub_nav_components/customers.scss';
import AccountsTableHead from "../AccountsTable";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";


class Accounts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
    };
  }

  toggleActive = () => this.setState(state => ({ isActive: !state.isActive }));

  render() {
    const { isActive } = this.state;

    return (
      <div className="tab-block-content no-shadow">
        <div style={{ textAlign: "left", marginLeft: "20px" }}>
          <FormControlLabel
            control={
              <Switch
                checked={isActive}
                onChange={(e) => this.toggleActive(e)}
                color="primary"
              />
            }
            label={isActive ? 'Тільки активні' : 'Всі'}
          />
        </div>
        <AccountsTableHead isActive={isActive} toEdit={this.props.toEdit}/>
      </div>
    );
  }
}

export default Accounts;
