import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import {
  BOOK_REVIEW_STATUS_IN_PROCESS,
  BOOK_REVIEW_STATUS_NEW,
  PER_PAGE,
  PER_PAGE_ARR
} from "../constants/data";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import {Delete, Edit} from '@material-ui/icons';
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {GET_BOOK_REVIEWS} from "../constants/urls";
import fetchOne from "../services/fetch";
import TextField from "@material-ui/core/TextField";
import {delReview, fetchReviews} from "../actions/editions";
import { connect } from "react-redux";
import {bookReviewStatus} from "../constants/main";
import MenuItem from "@material-ui/core/MenuItem";
import {formatPublishDate} from "../services/main";

const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
};

const rows = [
  { id: 'created_at', numeric: false, sort: true, label: 'Дата створення' },
  { id: 'email', numeric: false, sort: true, label: 'Email' },
  { id: 'rating', numeric: false, sort: true, label: 'Рейтинг' },
  { id: 'review', numeric: false, sort: true, label: 'Текст' },
  { id: 'status', numeric: false, sort: true, label: 'Статус' },
  { id: 'book', numeric: false, sort: false, label: 'Книга' },
  { id: 'actions', numeric: false, sort: false, label: '' },
];

class CustomersTable extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
          </TableCell>
          {rows.map(
            row => (
              <TableCell
                key={row.id}
                align= 'left'
                padding= 'default'
                sortDirection={orderBy === row.id ? order : false}
              >
                {row.sort ?
                    <Tooltip
                        title="Sort"
                        placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                        enterDelay={300}
                    >
                      <TableSortLabel
                          active={orderBy === row.id}
                          direction={order}
                          onClick={this.createSortHandler(row.id)}
                      >
                        {row.label}
                      </TableSortLabel>
                    </Tooltip> : row.label
                }
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

CustomersTable.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class BookReviewsTableHead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'desc',
      orderBy: 'created_at',
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: PER_PAGE,
      openDelete: false,
      openUpdate: false,
      curId: '',
      curObj: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onUpdateSubmit = this.onUpdateSubmit.bind(this);
  }

  componentDidMount() {
      this.props.getReviews(this.state.page, this.state.rowsPerPage, this.state.order, this.state.orderBy);
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.props.getReviews(this.state.page, this.state.rowsPerPage, order, orderBy);
    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.props.getReviews(page, this.state.rowsPerPage, this.state.order, this.state.orderBy);
    this.setState({ page: page });
  };

  handleChangeRowsPerPage = event => {
    this.props.getReviews(this.state.page, event.target.value, this.state.order, this.state.orderBy);
    this.setState({ rowsPerPage: event.target.value });
  };

  onChange(e) {
    this.setState({
      curObj: {...this.state.curObj, [e.target.name]: e.target.value},
      errors: {...this.state.errors, [e.target.name]: false},
    });
  }

  onCancel = () => {
    this.setState({openDelete: false, openUpdate: false, selected: [], curId: ''});
  };

  handleClose = () => {
    this.setState({ openDelete: false, openUpdate: false, curId: '', selected: [] });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  deleteReview = () => {
    this.props.removeReview(this.state.curId, this.state.page, this.state.rowsPerPage, this.state.order, this.state.orderBy);
    this.setState({ openDelete: false, curId: '', selected: [] });
  };

  openDeleteDialog = (id) => {
    this.setState({ openDelete: true, curId: id });
  };

  editReview = (id, reviews) => {
    let curObj = {};
    if(reviews.length){
      curObj = reviews.filter(item => item.id === id);
    }
    this.setState({openUpdate: true, curObj: curObj[0], curId: id});
  };

  async onUpdateSubmit(e) {
    e.preventDefault();
    const {curObj} = this.state;

    const formData = {
      review: curObj.review,
      status: curObj.status
    };

    try {
      const rev = await fetchOne({
        url: `${GET_BOOK_REVIEWS}/${curObj.id}`,
        method: "PUT",
        body: JSON.stringify(formData),
        headers: headers
      });
      if(rev.ok) {
        this.props.getReviews(this.state.page, this.state.rowsPerPage, this.state.order, this.state.orderBy);
        this.setState({openUpdate: false, curId: '', curObj: {}});
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  render() {
    const { classes, reviews } = this.props;
    const { order, orderBy, selected, rowsPerPage, page, curObj } = this.state;

    if (!reviews.collection || !reviews.collection.length) return (<h3>No reviews find.</h3>);

    return (
      <Paper className={classes.root}>

        {/**Update modal**/}
        <div>
          <Dialog
            open={this.state.openUpdate}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogContent>
              <DialogContentText>
                Змінити відгук
              </DialogContentText>

                  <TextField
                      autoFocus
                      name="review"
                      id="review"
                      label="Текст"
                      type="input"
                      multiline={true}
                      rows={4}
                      rowsMax={5}
                      variant="outlined"
                      margin="normal"
                      onChange={this.onChange}
                      defaultValue={curObj.review ? curObj.review : ''}
                      fullWidth
                      helperText={BOOK_REVIEW_STATUS_IN_PROCESS === curObj.status ? '' : 'Редагування неможливе в даному статусі'}
                      InputProps={{
                        readOnly: !(BOOK_REVIEW_STATUS_IN_PROCESS === curObj.status),
                      }}
                  />

              <TextField
                  select
                  className=""
                  label="Статус"
                  name="status"
                  id="status"
                  value={undefined !== curObj.status ? curObj.status : ''}
                  onChange={this.onChange}
                  fullWidth
              >
                {bookReviewStatus.map(option => (
                    (BOOK_REVIEW_STATUS_IN_PROCESS !== curObj.status &&
                        (curObj.status === option.value || BOOK_REVIEW_STATUS_IN_PROCESS === option.value)) ||
                    (BOOK_REVIEW_STATUS_IN_PROCESS === curObj.status && BOOK_REVIEW_STATUS_NEW !== option.value) ?
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem> : ''
                ))}
              </TextField>

            </DialogContent>
            <DialogActions>
              <Button onClick={this.onUpdateSubmit} color="primary">
                Змінити
              </Button>
              <Button onClick={this.onCancel} color="primary">
                Відмінити
              </Button>
            </DialogActions>
          </Dialog>
        </div>


        {/**Delete modal**/}
        <div>
          <Dialog
            open={this.state.openDelete}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogContent>
              <DialogContentText>
                Ви впевнені, що хочете видалити цей запис?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.deleteReview} color="primary">
                Видалити
              </Button>
              <Button onClick={this.onCancel} color="primary">
                Відмінити
              </Button>
            </DialogActions>
          </Dialog>
        </div>


        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <CustomersTable
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={reviews.collection.length}
            />
            <TableBody>
              {reviews.collection
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, n.id)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell padding="checkbox">
                      </TableCell>
                      <TableCell align="left">{formatPublishDate(n.created_at)}</TableCell>
                      <TableCell align="left">{n.email}</TableCell>
                      <TableCell align="left">{n.rating}</TableCell>
                      <TableCell align="left">{n.review.substring(0, 30)}</TableCell>
                      <TableCell align="left">{undefined !== n.status ? bookReviewStatus.find(r => r.value === n.status).label : ''}</TableCell>
                      <TableCell align="left">{undefined !== n.book.book_localizations[0] ? n.book.book_localizations[0].title : ''}</TableCell>
                      <TableCell align="left">
                        <Button onClick={() => this.openDeleteDialog(n.id)} color="primary">
                          <Delete style={{ color: '#afafaf' }}/>
                        </Button>
                        <Button onClick={() => this.editReview(n.id, reviews.collection)} color="primary">
                          <Edit style={{ color: '#afafaf' }}/>
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={PER_PAGE_ARR}
          component="div"
          count={reviews.pagination.totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage='Рядків на сторінку:'
          backIconButtonProps={{
            'aria-label': 'Попередня',
          }}
          nextIconButtonProps={{
            'aria-label': 'Наступна',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          labelDisplayedRows= {({ from, to, count }) => `${from}-${to} з ${count}`}
        />
      </Paper>
    );
  }
}

const mapStateToProps = state => ({
  reviews: state.reviews
});

const mapDispatchToProps = dispatch => ({
  getReviews: (page, limit, order, orderBy) => {
    dispatch(fetchReviews(page + 1, limit, order, orderBy));
  },
  removeReview: (id, page, limit, order, orderBy) => {
    dispatch(delReview(id, page + 1, limit, order, orderBy));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BookReviewsTableHead));