import React, { Component } from 'react';

import { CREATE_ITEM, EDIT_ITEM } from '../constants/types';

import fetchOne from '../services/fetch';
import { CORE_URL } from '../constants/urls';

import AllNews from '../components/news/AllNews';
import CreateNews from '../components/news/CreateNews';
import Loader from "../components/util/Loader";
import ModalWindow from './layout/ModalWindow';

const params = [
  { name: 'Всі новини' },
  { name: 'Створити новину' },
];

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

class News extends Component {
  state = {
    type: CREATE_ITEM,
    open: false,
    section: 0,
    id: '',

    data: [],

    loading: true,

    limit: 10,
    page: 1,
    orderBy: 'desc',
    sortBy: 'createdAt',
    onlyActive: false,
  };

  setLimit = (limit) => {
    this.setState({ limit })
  };

  setPage = (page) => {
    this.setState({ page })
  };

  componentDidMount() {
    this.getData();
  }

  // обновляем данные при переключении сортировки или перелистывании страницы
  componentDidUpdate(prevProps, prevState) {
    const { limit, page, orderBy, sortBy, onlyActive } = this.state;
    if (prevState.limit !== limit
      || prevState.page !== page
      || prevState.orderBy !== orderBy
      || prevState.sortBy !== sortBy
      || prevState.onlyActive !== onlyActive
    ) {
      // this.updateData();
      this.getData();
    }
  }

  getData = async () => {
    const { limit, page, orderBy, sortBy, onlyActive } = this.state;
    const isActive = onlyActive ? '&isActive=true' : '';
    const data = await fetchOne({
      url: `${CORE_URL}/news?limit=${limit}&page=${page}&sortBy=${sortBy}&orderBy=${orderBy}${isActive}`
    });

    this.setState({
      type: CREATE_ITEM,
      data,
      id: '',
      loading: false,
    });
  };

  // updateData = () => {
  //   this.setSection(0);
  //   this.getData();
  // };

  onSuccess = () => {
    this.setSection(0);
    this.setState({ page: 1 })
  }

  setSection = section => this.setState({ type: CREATE_ITEM, section });

  setEdit = (id, type) => this.setState({ id, type, section: 1 });

  deleteItem = async () => {
    const { id } = this.state;
    try {
      const deleted = await fetchOne({
        url: `${CORE_URL}/news/${id}`,
        method: 'DELETE',
        headers,
      });

      this.setState({
        type: CREATE_ITEM,
        open: false,
        id: '',
      });
      if (deleted.status === 204) this.getData();
    } catch (err) {
      this.setState({
        type: CREATE_ITEM,
        open: false,
        id: '',
      });
    }
  };

  openModal = id => this.setState({ id: id || '', open: true });

  handleClose = () => this.setState({ open: false });

  handleRequestSort = (event, sortBy) => {
    const _sortBy = sortBy;
    let _orderBy = 'desc';

    if (this.state.sortBy === sortBy && this.state.orderBy === 'desc') {
      _orderBy = 'asc';
    }

    this.setState({ sortBy: _sortBy, orderBy: _orderBy });
  };

  toggleOnlyActive = () => {
    this.setState(state => ({ onlyActive: !state.onlyActive }));
  };

  render() {
    const {
      loading, section, data, type, open,
      id, orderBy, sortBy, onlyActive
    } = this.state;

    if (loading) return (<Loader/>);

    return (
      <React.Fragment>
        <div className="user-content">
          <div className="user-tabs-wrapper">
            <div className="tab-line">
              {params.map((item, index) => (
                <div
                  className={
                    section === index ? 'tab-item tab-active' : 'tab-item'
                  }
                  key={`nav-item-${item.name}`}
                  onClick={() => this.setSection(index)}
                >
                  <div className="tab-item-block">
                    {index && type === EDIT_ITEM
                      ? 'Редагування новини'
                      : item.name}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {section === 0 && (
            <AllNews
              pagination={data.pagination || {}}
              data={data.collection || []}
              openModal={this.openModal}
              onEdit={this.setEdit}
              setLimit={this.setLimit}
              setPage={this.setPage}
              order={orderBy}
              sortBy={sortBy}
              handleRequestSort={this.handleRequestSort}
              toggleOnlyActive={this.toggleOnlyActive}
              onlyActive={onlyActive}
            />
          )}
          {section === 1 && (
            <CreateNews
              id={id}
              type={type}
              // update={this.updateData.bind(this, this.state.limit, this.state.page)}
              onSuccess={this.onSuccess}
            />
          )}

          <ModalWindow
            open={open}
            title="Видалити новину?"
            onClose={this.handleClose}
            onConfirm={this.deleteItem}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default News;
