export const getToken = () => localStorage.getItem("jwt-token");

export const saveToken = token => localStorage.setItem("jwt-token", token);

export const removeToken = () => localStorage.removeItem("jwt-token");

export const getTokenInfo = () => {
  try {
    const token = getToken();
    if (token) return JSON.parse(token);
  } catch (error) {
    console.error(error);
  }
};

export const isAuthorized = () => {
  const token_info = getTokenInfo();
  if (token_info) {
    const { expires_in } = token_info;
    const now = Math.round((Date.now()/1000));

    if (parseInt(expires_in) > now) {
      return true;
    } else {
      removeToken();
      return false;
    }
  }
};