import React, { Component } from 'react';
import '../../assets/styles/nav_components/main.scss';
import TextField from "@material-ui/core/TextField";
import {allFalse, validateEmail} from "../../actions/common";
import Button from "@material-ui/core/Button";
import {addCustomer} from "../../actions/accounts";
import {connect} from "react-redux";

class CreateAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      username: '',
      email: '',
      full_name: '',
      password: '',
      fields: {
        username: true, email: true, full_name: true, password: true
      },
      errors: {},
      isActive: true,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    if (e.target.value.trim() !== '') {
      if (e.target.name === 'email') {
        if (validateEmail(e.target.value)) {
          this.setState({
            [e.target.name]: e.target.value,
            fields: { ...this.state.fields, [e.target.name]: false },
          });
        } else {
          this.setState({ fields: { ...this.state.fields, [e.target.name]: true } });
        }
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          fields: { ...this.state.fields, [e.target.name]: false },
        });
      }
    } else {
      this.setState({ [e.target.name]: e.target.value ,fields: { ...this.state.fields, [e.target.name]: true } });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    if (allFalse(this.state.fields)) {
      const userData = {
        username: this.state.username,
        email: this.state.email,
        full_name: this.state.full_name,
        password: this.state.password,
        is_active: true
      };
      this.props.addNewAccount(userData);
      this.setState({ open: false });
    } else {
      this.setState({ errors: this.state.fields });
      console.log(this.state.fields);
    }
  }

  render() {
    const {errors} = this.state;
    console.log(this.state);
    return (
      <React.Fragment>
        <div className="tab-block-content">

          <div className="show-block">
            <div className="show-block-form">
              <TextField
                autoFocus
                name="username"
                margin="dense"
                id="name"
                label="Імʼя"
                type="input"
                onChange={this.onChange}
                error={errors.username}
                fullWidth
              />

              <TextField
                autoFocus
                name="full_name"
                margin="dense"
                id="type"
                label="ПІБ"
                type="input"
                onChange={this.onChange}
                error={errors.full_name}
                fullWidth
              />

              <TextField
                autoFocus
                name="email"
                margin="dense"
                id="email"
                label="Email"
                type="email"
                onChange={this.onChange}
                error={errors.email}
                fullWidth
              />

              <TextField
                autoFocus
                name="password"
                margin="dense"
                id="password"
                label="Пароль"
                type="input"
                onChange={this.onChange}
                error={errors.password}
                fullWidth
              />

              <div className="account-submit-block">
                <Button variant="contained" size="small" className="" onClick={(e) => this.onSubmit(e)}>
                  Зберегти
                </Button>
              </div>

            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addNewAccount: (data) => {
    dispatch(addCustomer(data));
  }
});

export default connect(null, mapDispatchToProps)(CreateAccount);
