import React, { PureComponent } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

import { CORE_URL } from './constants/urls';

import { isAuthorized } from './services/auth';
import fetchOne from './services/fetch';

import './App.scss';

import { Header, SideBar, Main } from './views/layout';
import LogIn from './views/LogIn';

class App extends PureComponent {
  async componentDidMount() {
    const languages = await fetchOne({ url: `${CORE_URL}/languages` });
    const language = languages.find(lang => lang.priority === 1);
    localStorage.setItem('language', language.id);
  }

  render() {
    const isAuth = isAuthorized();
    return (
      <Switch>
        <Route exact path="/login" component={LogIn}/>
        {!isAuth && <Redirect to="/login"/>}
        <Route path="/" component={AppComponent}/>
      </Switch>
    );
  }
}

const AppComponent = () => (
  <div className="App">
    <Header/>
    <SideBar/>
    <Main/>
  </div>
);

export default withRouter(App);
