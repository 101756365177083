import React from 'react';
import Loader from '../../assets/svg/loader.svg';

const LoaderWrapper = () => (
  <div className="tab-block-content">
    <img src={Loader} className="" alt="" />
  </div>
);

export default LoaderWrapper;
