import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import '../../assets/styles/nav_components/main.scss';

import { EDIT_ITEM } from '../../constants/types';
import { PER_PAGE, PER_PAGE_ARR } from '../../constants/data';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button/Button';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Switch from '@material-ui/core/Switch/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';

import { Add, Cancel, Delete, Done, Edit } from '@material-ui/icons';
import {tableHeadSort} from "../../services/sort";

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  fullBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  childDepartmentsRow: {
    backgroundColor: 'rgb(247, 247, 247)',
  },
});

function createSystemContactsData(systemContact, onlyActive) {
  let data = [];
  if (systemContact.length) {
    systemContact.forEach(function(item, i, arr) {
      const title = `${
        item.department.department_localizations.length > 0
          ? item.department.department_localizations[0].title
          : ''
      }`;
      const children = onlyActive
        ? item.children.filter(child => child.is_active)
        : item.children;

      data.push({
        id: item.id,
        title: title,
        image: item.department.photo ? item.department.photo.href : '',
        priority: item.priority,
        children: children,
        is_active: item.is_active,
      });

    });
  }
  return data;
}

const desc = tableHeadSort('');

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'priority', numeric: false, disablePadding: true, label: 'Пріоритет', sort: false },
  {
    id: 'image',
    numeric: false,
    disablePadding: false,
    label: 'Фото системного контакту', sort: false
  },
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    label: 'Назва системного контакту', sort: false
  },
  { id: 'is_active', numeric: false, disablePadding: false, label: 'Статус', sort: true },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { rows, order, orderBy } = this.props;
    return (
      <TableHead>
        <TableRow>
          {rows.map(
            (row, i) => (
              <React.Fragment
                key={`sorted-${i}-${row.id}`}
              >
                {row.sort ? (
                  <TableCell
                    align={row.numeric ? 'right' : 'left'}
                    padding={row.disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === row.id ? order : false}
                  >
                    <Tooltip
                      title="Sort"
                      placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                      enterDelay={300}
                    >
                      <TableSortLabel
                        active={orderBy === row.id}
                        direction={order}
                        onClick={this.createSortHandler(row.id)}
                      >
                        {row.label}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                ) : (
                  <TableCell
                    align={row.numeric ? 'right' : 'left'}
                    padding={row.disablePadding ? 'none' : 'default'}
                    sortDirection={orderBy === row.id ? order : false}
                  >
                    {row.label}
                  </TableCell>
                )}
              </React.Fragment>
            ),
            this,
          )}
          <TableCell padding="checkbox" />
          <TableCell padding="checkbox" />
          <TableCell padding="checkbox" />
        </TableRow>
      </TableHead>
    );
  }
}

class EnhancedTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'priority',
    selected: [],

    page: 0,
    rowsPerPage: PER_PAGE,

    data: this.props.data,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data !== state.data) {
      return {
        selected: [],
        data: props.data,
      };
    }
    return null;
  }

  goToCreateChild = id => this.props.onCreateChild(id);

  goToEdit = (id, type) => this.props.onEdit(id, type);

  toggleOnlyActive = () =>
    this.setState(state => ({ onlyActive: !state.onlyActive }));

  deleteItem = id => this.props.openModal(id);

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => this.setState({ page });

  handleChangeRowsPerPage = event =>
    this.setState({ rowsPerPage: event.target.value });

  render() {
    const { classes } = this.props;
    const { data, order, orderBy, rowsPerPage, page, onlyActive } = this.state;
    let systemContactsData = onlyActive
      ? data.filter(item => item.is_active)
      : data;

    systemContactsData = createSystemContactsData(systemContactsData, onlyActive);

    let systemContactsDataLength = systemContactsData.length;

    systemContactsData.forEach(n => {
      systemContactsDataLength += n.children.length;
    });

    return (
      <div className="tab-block-content no-shadow">
        <div className={classes.fullBetween}>
          <FormControlLabel
            control={
              <Switch
                checked={onlyActive}
                onChange={this.toggleOnlyActive}
                color="primary"
              />
            }
            label={onlyActive ? 'Тільки активні' : 'Всі'}
          />
        </div>
        {!!systemContactsData.length && (
          <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
              <Table className={classes.table} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  rows={rows}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                />
                <TableBody>
                  {stableSort(systemContactsData, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(n => {
                      return (
                        <React.Fragment key={`system-contact-${n.id}`}>
                          <TableRow hover role="checkbox">
                            <TableCell>
                              <span style={{
                                fontWeight: 'bold',
                                paddingLeft: 5
                              }}>
                                {n.priority}
                              </span></TableCell>
                            <TableCell>
                              <img
                                src={n.image}
                                className="departments-table-image"
                                alt=""
                              />
                            </TableCell>
                            <TableCell>{n.title}</TableCell>
                            <TableCell padding="checkbox">
                              {n.is_active ? <Done /> : <Cancel />}
                            </TableCell>
                            <TableCell padding="checkbox">
                              <Button
                                onClick={() => this.goToCreateChild(n.id)}
                                color="primary"
                              >
                                <Add style={{ color: '#afafaf' }} />
                              </Button>
                            </TableCell>
                            <TableCell padding="checkbox">
                              <Button
                                onClick={() => this.deleteItem(n.id)}
                                color="primary"
                              >
                                <Delete style={{ color: '#afafaf' }} />
                              </Button>
                            </TableCell>
                            <TableCell padding="checkbox">
                              <Button
                                onClick={() => this.goToEdit(n.id, EDIT_ITEM)}
                                color="primary"
                              >
                                <Edit style={{ color: '#afafaf' }} />
                              </Button>
                            </TableCell>
                          </TableRow>

                          {n.children.map(child => {
                            return (
                              <TableRow
                                className={classes.childDepartmentsRow}
                                hover
                                role="checkbox"
                                key={`child-${n.id}-${child.id}`}
                              >
                                <TableCell>
                                  <span
                                    style={{
                                      borderRadius: 20,
                                      border: '1px solid #ccc',
                                      width: '18px',
                                      display: 'inline-block',
                                      height: '18px',
                                      textAlign: 'center'
                                    }}
                                  >
                                    {child.priority}
                                  </span>
                                </TableCell>
                                <TableCell>
                                  <img
                                    style={{
                                      width: '40px',
                                      height: '40px',
                                      marginLeft: '10px',
                                    }}
                                    src={
                                      child.department.photo
                                        ? child.department.photo.href
                                        : ''
                                    }
                                    className="departments-table-image"
                                    alt=""
                                  />
                                </TableCell>
                                <TableCell>
                                  {
                                    child.department.department_localizations[0]
                                      .title
                                  }
                                </TableCell>
                                <TableCell padding="checkbox">
                                  {child.is_active ? <Done /> : <Cancel />}
                                </TableCell>
                                <TableCell padding="checkbox" />
                                <TableCell padding="checkbox">
                                  <Button
                                    onClick={() => this.deleteItem(child.id)}
                                    color="primary"
                                  >
                                    <Delete style={{ color: '#afafaf' }} />
                                  </Button>
                                </TableCell>
                                <TableCell padding="checkbox">
                                  <Button
                                    onClick={() =>
                                      this.goToEdit(child.id, EDIT_ITEM)
                                    }
                                    color="primary"
                                  >
                                    <Edit style={{ color: '#afafaf' }} />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={PER_PAGE_ARR}
              component="div"
              count={systemContactsDataLength}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Попередня сторінка',
              }}
              nextIconButtonProps={{
                'aria-label': 'Наступна сторінка',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              labelRowsPerPage='Рядків на сторінку:'
              labelDisplayedRows= {({ from, to, count }) => `${from}-${to} з ${count}`}
            />
          </Paper>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(EnhancedTable);
