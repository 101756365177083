import React, { Component } from 'react';
import '../assets/styles/home.scss';

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="content-wrapper">
          <h1>Home page</h1>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
