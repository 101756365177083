export const ALL_ACCOUNTS_DOWNLOADED = 'ALL_ACCOUNTS_DOWNLOADED';
export const ADD_ACCOUNT_FAIL = 'ADD_ACCOUNT_FAIL';
export const ADD_ACCOUNT_SUCCESS = 'ADD_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAIL = 'UPDATE_ACCOUNT_FAIL';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const ALL_BANNERS_DOWNLOADED = 'ALL_BANNERS_DOWNLOADED';
export const ALL_EDITIONS_DOWNLOADED = 'ALL_EDITIONS_DOWNLOADED';
export const ALL_BOOK_REVIEWS_DOWNLOADED = 'ALL_BOOK_REVIEWS_DOWNLOADED';
export const ALL_BOOK_ORDERS_DOWNLOADED = 'ALL_BOOK_ORDERS_DOWNLOADED';
export const ALL_TAGS_DOWNLOADED = 'ALL_TAGS_DOWNLOADED';
export const ADD_TAG_FAIL = 'ADD_TAG_FAIL';
export const ADD_TAG_INIT = 'ADD_TAG_INIT';
export const ADD_TAG_SUCCESS = 'ADD_TAG_SUCCESS';
export const UPDATE_TAG_FAIL = 'UPDATE_TAG_FAIL';
export const UPDATE_TAG_INIT = 'UPDATE_TAG_INIT';
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const DELETE_TAG_FAIL = 'DELETE_TAG_FAIL';
export const DELETE_EDITION_FAIL = 'DELETE_EDITION_FAIL';
export const DELETE_EDITION_SUCCESS = 'DELETE_EDITION_SUCCESS';
export const DELETE_BOOK_REVIEW_FAIL = 'DELETE_BOOK_REVIEW_FAIL';
export const DELETE_BOOK_ORDER_FAIL = 'DELETE_BOOK_ORDER_FAIL';
export const DELETE_BOOK_REVIEW_SUCCESS = 'DELETE_BOOK_REVIEW_SUCCESS';
export const DELETE_BOOK_ORDER_SUCCESS = 'DELETE_BOOK_ORDER_SUCCESS';
export const DELETE_DEPARTMENT_FAIL = 'DELETE_DEPARTMENT_FAIL';
export const DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS';
export const ALL_DEPARTMENTS_DOWNLOADED = 'ALL_DEPARTMENTS_DOWNLOADED';
export const ADD_DEPARTMENT_FAIL = 'ADD_DEPARTMENT_FAIL';
export const ADD_DEPARTMENT_SUCCESS = 'ADD_DEPARTMENT_SUCCESS';
export const DELETE_EMPLOYEE_FAIL = 'DELETE_EMPLOYEE_FAIL';
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';
export const ALL_EMPLOYEES_DOWNLOADED = 'ALL_EMPLOYEES_DOWNLOADED';
export const ADD_EMPLOYEE_FAIL = 'ADD_EMPLOYEE_FAIL';
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS';
export const REQUEST_MESSAGE = 'REQUEST_MESSAGE';
export const CLOSE_MESSAGE = 'CLOSE_MESSAGE';
export const ORG_STRUCTURE_DOWNLOADED = 'ORG_STRUCTURE_DOWNLOADED';
export const ADD_NODE_FAIL = 'ADD_NODE_FAIL';
export const ADD_NODE_SUCCESS = 'ADD_NODE_SUCCESS';
export const DELETE_NODE_FAIL = 'DELETE_NODE_FAIL';
export const DELETE_NODE_SUCCESS = 'DELETE_NODE_SUCCESS';
export const DELETE_SYS_PAGE_FAIL = 'DELETE_SYS_PAGE_FAIL';
export const DELETE_SYS_PAGE_SUCCESS = 'DELETE_SYS_PAGE_SUCCESS';
export const SYS_PAGE_DOWNLOADED = 'SYS_PAGE_DOWNLOADED';
