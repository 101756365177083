import React from 'react';

import '../../assets/styles/nav_components/main.scss';
import {withStyles} from '@material-ui/core/styles';
import {Button, Paper, Typography} from "@material-ui/core/es/index";
import ExpandableProperty from "./ExpandableProperty";
import {EDIT_ITEM} from "../../constants/types";
import {Delete, Edit, Add} from "@material-ui/icons"

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  container: {
    minWidth: 1020,
    width: '100%',
    display: 'flex',
    overflowX: 'auto',
  },
  width50: {
    textAlign: 'left',
    padding: 10,
    width: '49%',
    height: '100%',
    margin: '20px 0.5%'
  },
  paper: {
    padding: 10,
    boxShadow: 'none'
  },
  collapseInfo: {
    padding: 10
  },
});

const locale = (localizations) => {
  const localLanguage = localStorage.getItem('language');
  const lang_system_contacts = localizations.filter(localization => localization.localization.id === localLanguage);
  return !!localizations.length ? lang_system_contacts[0] : {};
};

const RecursiveProperty = props => {

  return (
    <div style={{
      paddingLeft: 10
    }}>
      {props.property && (typeof props.property === 'object') &&
      <ExpandableProperty property={props.property}
                          openedInfo={props.openedInfo}
                          showItemInfo={props.showItemInfo}
                          expanded={!!props.rootProperty}>
        {Object.values(props.property.children).map((property, index) => (
          !!property &&
          <RecursiveProperty
            openedInfo={props.openedInfo}
            showItemInfo={props.showItemInfo}
            key={index}
            property={property}
          />
        ))}

      </ExpandableProperty>
      }
    </div>
  );
};

class AllNodes extends React.Component {
  state = {
    data: this.props.data,
    itemInfo: {},
    open: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data !== state.data) {
      return {
        data: props.data,
        itemInfo: {}
      };
    }
    return null;
  }

  goToCreate = type => this.props.onCreate(type);

  goToEdit = (id, type) => this.props.onEdit(id, type);

  goToCreateChild = id => this.props.onCreateChild(id);

  deleteItem = id => this.props.openModal(id);

  openItem = async (item) => {
    this.setState({
      itemInfo: item
    });

  };

  render() {
    const {classes} = this.props;
    const {data, itemInfo} = this.state;

      return (
      <div className={classes.container}>

        <div className={classes.width50}>

          <RecursiveProperty
            property={data}
            showItemInfo={this.openItem}
            openedInfo={itemInfo.id}
            rootProperty={true}/>

        </div>

        {itemInfo.id &&
        <React.Fragment>

        <div className={classes.width50}>

          <div style={{
            boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
          }}>
            <Button onClick={() => this.deleteItem(itemInfo.id)} color="primary">
              <Delete style={{ color: '#afafaf' }} />
            </Button>

            <Button onClick={() => this.goToEdit(itemInfo.id, EDIT_ITEM)} color="primary">
              <Edit style={{ color: '#afafaf' }} />
            </Button>

            <Button
              onClick={() => this.goToCreateChild(itemInfo.id)}
              color="primary"
            >
              <Add style={{ color: '#afafaf' }} />
            </Button>

            <Paper className={classes.paper}>
              <Typography
                variant="subheading"
                align="left"
              >
                Назва
              </Typography>

              <div>
                {locale(itemInfo.node_localizations) ? locale(itemInfo.node_localizations).title : ''}
              </div>
            </Paper>

            <Paper className={classes.paper}>
              <Typography
                variant="subheading"
                align="left"
              >
                Керівництво
              </Typography>
              <div>
                {itemInfo.government ? 'Відображати' : 'Не відображати'}
              </div>
            </Paper>

            {itemInfo.color && <Paper className={classes.paper}>
              <Typography
                variant="subheading"
                align="left"
              >
                Колір
              </Typography>

              <div className="tag-colors-item"
                   style={{backgroundColor: itemInfo.color}}>
              </div>
            </Paper>}

            {locale(itemInfo.node_localizations).page && <Paper className={classes.paper}>
              <Typography
                variant="subheading"
                align="left"
              >
                Сторінка
              </Typography>

              <div>
                {locale(locale(itemInfo.node_localizations).page.page_localizations).title}
              </div>
            </Paper>}

            {itemInfo.description && <Paper className={classes.paper}>
              <Typography
                variant="subheading"
                align="left"
              >
                Опис
              </Typography>

              <div>
                {itemInfo.description}
              </div>
            </Paper>}

            {itemInfo.employee && itemInfo.employee.employee_localizations && <Paper className={classes.paper}>
              <Typography
                variant="subheading"
                align="left"
              >
                Керівник
              </Typography>

              <div>
                <span>{locale(itemInfo.employee.employee_localizations).surname} </span>
                <span>{locale(itemInfo.employee.employee_localizations).name} </span>
                <span>{locale(itemInfo.employee.employee_localizations).middle_name} </span>
              </div>
            </Paper>}

            {itemInfo.position && <Paper className={classes.paper}>
              <Typography
                variant="subheading"
                align="left"
              >
                Посада
              </Typography>

              <div>
                {itemInfo.position}
              </div>
            </Paper>}

            {itemInfo.department && itemInfo.department.department_localizations && <Paper className={classes.paper}>
              <Typography
                variant="subheading"
                align="left"
              >
                Системний контакт
              </Typography>

              <div>
                {locale(itemInfo.department.department_localizations).title}
              </div>
            </Paper>}

            {itemInfo.priority && <Paper className={classes.paper}>
              <Typography
                variant="subheading"
                align="left"
              >
                Пріоритет
              </Typography>

              <div>
                {itemInfo.priority}
              </div>
            </Paper>}
          </div>



        </div>
        </React.Fragment>
        }

      </div>
    )

  }
}

export default withStyles(styles)(AllNodes);

