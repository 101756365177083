import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import '../../assets/styles/nav_components/main.scss';

import { EDIT_ITEM } from '../../constants/types';
import { PER_PAGE, PER_PAGE_ARR } from "../../constants/data";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Button from "@material-ui/core/Button/Button";
import Paper from '@material-ui/core/Paper';

import { Cancel, Delete, Done, Edit } from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";

import EnhancedTableHead from '../util/EnhancedTableHead';

import { formatPublishDate } from '../../services/main';
import { tableHeadSort } from '../../services/sort';
import { addLink } from '../../services/main';

const desc = tableHeadSort('advertising');


function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'type', numeric: false, disablePadding: true, label: 'Тип', sort: true },
  { id: 'published_at', numeric: false, disablePadding: true, label: 'Дата створення', sort: true },
  { id: 'is_active', numeric: false, disablePadding: true, label: 'Статус', sort: true },
  { id: 'title', numeric: false, disablePadding: true, label: 'Заголовок', sort: true },
  { id: 'alias', numeric: false, disablePadding: true, label: 'Посилання', sort: false },
  { id: 'image', numeric: false, disablePadding: false, label: 'Зображення', sort: false },
  { id: 'iframe', numeric: false, disablePadding: false, label: 'Iframe', sort: false },
  { id: 'languages', numeric: false, disablePadding: false, label: 'Мови', sort: true },
];

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  fullBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
});

const locale = ({ advertising_localizations }) => {
  return !!advertising_localizations.length ? advertising_localizations[0] : {};
};

const translateType = (type) => {
  switch (type) {
    case 1:
      return 'Посилання';
    default:
      return 'Iframe';
  }
};

class EnhancedTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'created_at',
    selected: [],

    page: 0,
    rowsPerPage: PER_PAGE,
    onlyActive: false,

    data: this.props.data,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data !== state.data) {
      return {
        selected: [],
        data: props.data,
      };
    }
    return null;
  }

  goToCreate = type => this.props.onCreate(type);

  goToEdit = (id, type) => this.props.onEdit(id, type);

  deleteItem = id => this.props.openModal(id);

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => this.setState({ page });

  handleChangeRowsPerPage = event => this.setState({ rowsPerPage: event.target.value });

  toggleOnlyActive = () => this.setState(state => ({ onlyActive: !state.onlyActive }));

  render() {
    const { classes } = this.props;
    const { data, order, orderBy, rowsPerPage, page, onlyActive } = this.state;
    const activeData = onlyActive ? data.filter(item => item.is_active) : data;

    return (
      <div className="tab-block-content no-shadow">
        <div className={classes.fullBetween}>
          <FormControlLabel
            control={
              <Switch
                checked={onlyActive}
                onChange={this.toggleOnlyActive}
                color="primary"
              />
            }
            label={onlyActive ? 'Тільки активні' : 'Всі'}
          />
        </div>
        {!!activeData.length && (
          <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
              >
                <EnhancedTableHead
                  rows={rows}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                />
                <TableBody>
                  {stableSort(activeData, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(n => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          key={n.id}
                        >
                          <TableCell>
                            {translateType(n.type)}
                          </TableCell>
                          <TableCell>
                            {formatPublishDate(n.created_at)}
                          </TableCell>
                          <TableCell>
                            {n.is_active ? (<Done />) : (<Cancel />)}
                          </TableCell>
                          <TableCell>
                            {locale(n).signature}
                          </TableCell>
                          <TableCell>
                            <a href={addLink(locale(n).link)}>
                              {locale(n).link}
                            </a>
                          </TableCell>
                          <TableCell>
                            <img
                              src={locale(n).image && (locale(n).image.resize || locale(n).image.href)}
                              style={{ height: 80 }}
                              alt=""
                            />
                          </TableCell>
                          <TableCell>
                            {locale(n).iframe && (
                              <iframe src={locale(n).iframe} />
                            )}
                          </TableCell>
                          <TableCell>
                            {n.advertising_localizations.length}
                          </TableCell>
                          <TableCell padding="checkbox">
                            <Button onClick={() => this.deleteItem(n.id)} color="primary">
                              <Delete style={{ color: '#afafaf' }} />
                            </Button>
                          </TableCell>
                          <TableCell padding="checkbox">
                            <Button onClick={() => this.goToEdit(n.id, EDIT_ITEM)} color="primary">
                              <Edit style={{ color: '#afafaf' }} />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={PER_PAGE_ARR}
              component="div"
              count={activeData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Попередня сторінка',
              }}
              nextIconButtonProps={{
                'aria-label': 'Наступна сторінка',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              labelRowsPerPage='Рядків на сторінку:'
              labelDisplayedRows= {({ from, to, count }) => `${from}-${to} з ${count}`}
            />
          </Paper>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(EnhancedTable);