import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import '../../assets/styles/categories.scss';

import fetchOne from '../../services/fetch';
import { CORE_URL, BACK_URL } from '../../constants/urls';
import { EDIT_ITEM } from '../../constants/types';

import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { Save } from '@material-ui/icons';
import Button from '@material-ui/core/Button/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Switch from '@material-ui/core/Switch/Switch';
import TextField from '@material-ui/core/TextField/TextField';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';

import DropZone from '../util/DropZone';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
  },
  dense: {
    marginTop: 20,
  },
  menu: {
    width: '100%',
  },
  formControl: {
    margin: theme.spacing.unit,
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit,
  },
  titleWrapper: {
    width: '100%',
    margin: '5px 0',
    padding: '5px 0',
    border: '1px solid',
  },
  itemIcon: {
    margin: '10px auto',
    height: 30,
    width: 30,
  },
  menuWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    listStyleType: 'none',
    margin: '15px auto',
    width: '50%',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 10,
  },
});

class CreateLanguages extends React.Component {

  state = {
    locale: '',
    title: '',
    icon: '',
    header_logo: '',
    footer_logo: '',

    is_active: true,
    priority: 1,

    openHeader: false,
    openFooter: false,

    files: [],
    menus: [],

    error: false,
  };

  componentDidMount() {
    this.getLanguages();
  }

  getLanguages = async () => {
    const { id, type } = this.props;
    const filesForLogo = await fetchOne({
      url: `${CORE_URL}/files/static/logos`,
    });
    if (type === EDIT_ITEM) {
      const {
        searchable, icon, is_active, locale,
        priority, title, header_logo, footer_logo,
      } = await fetchOne({ url: `${CORE_URL}/languages/${id}` });

      const headerIndex = header_logo && header_logo.lastIndexOf('/');
      const footerIndex = footer_logo && footer_logo.lastIndexOf('/');

      const headerFile = header_logo ? header_logo.slice(headerIndex + 1) : '';
      const footerFile = footer_logo ? footer_logo.slice(footerIndex + 1) : '';

      this.setState({
        searchable, icon, is_active,
        locale, priority, title,
        header_logo: headerFile,
        footer_logo: footerFile,
        files: filesForLogo,
      });
    } else {
      const menus = await fetchOne({ url: `${CORE_URL}/menus?parent=true` });

      this.setState({
        menus: menus.map(el => ({
          ...el,
          title: el.menu_localizations[0] ? el.menu_localizations[0].title : '',
        })),
        files: filesForLogo,
      });
    }
  };

  submitPage = () => {
    const {
      icon, locale, priority, title,
      header_logo, footer_logo,
    } = this.state;
    const inputs = [
      icon, locale, priority, title,
      header_logo, footer_logo,
    ];

    if (inputs.every(input => input)) this.createLanguage();

    this.setState({ error: inputs.some(input => !input) });
  };

  createLanguage = async () => {
    try {
      const {
        icon, is_active, locale,
        priority, title, header_logo, footer_logo,
      } = this.state;

      const isEdit = this.props.type === EDIT_ITEM;
      let method = isEdit ? 'PUT' : 'POST';
      let URL = isEdit
        ? `${CORE_URL}/languages/${this.props.id}`
        : `${CORE_URL}/languages`;

      const pageBody = JSON.stringify({
        icon: icon && (icon.id || icon),
        is_active,
        locale, priority, title,
        header_logo, footer_logo,
      });

      const createLanguage = await fetchOne({
        url: URL, method, body: pageBody,
      });

      if (createLanguage.status === 201 || createLanguage.status === 204) {
        if (this.state.type !== EDIT_ITEM) {
          const langData = await createLanguage.json();
          if (!this.menusCreate(langData)) return;
          this.props.update();
        } else {
          this.props.update();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  menusCreate = async (createdLangData) => {
    const { menus } = this.state;
    const createMenus = await Promise.all(
      menus.map(({ id, title, menu_localizations }) => {
        const method = 'POST';

        const localMenuBody = JSON.stringify({
          title: title || menu_localizations[0].title,
          localization: createdLangData.id,
        });

        return fetchOne({
          url: `${CORE_URL}/menus/${id}/localizations`,
          method,
          body: localMenuBody,
        });
      }),
    );

    return createMenus.every(item => item.status !== 201);
  };

  handleMenuChange = (index, name) => ({ target }) => {
    const { menus } = this.state;
    this.setState({
      menus: menus
        .map((el, i) => index === i
          ? ({ ...el, [name]: target.value })
          : el),
      error: false,
    });
  };

  handleChange = name => ({ target }) => {
    const value = target.type === 'number' ? parseInt(target.value) : target.value;
    this.setState({ [name]: value, error: false });
  };

  handleOpen = name => this.setState({ [name]: true });

  handleClose = () => this.setState({ openHeader: false, openFooter: false });

  handleCheck = name => event => this.setState({ [name]: event.target.checked });

  render() {
    const { type, classes } = this.props;
    const {
      error, icon,
      is_active, title, priority,
      locale, header_logo, footer_logo,
      openHeader, openFooter, files, menus,
    } = this.state;

    return (
      <div
        className="menu-create_wrapper"
        style={{
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <div
          className="submit-button_wrapper"
          style={{
            margin: 'auto',
            width: '50%',
          }}
        >
          <Button
            onClick={this.submitPage}
            variant="contained"
            color="primary"
          >
            <Save style={{ color: '#fff' }} />
          </Button>
        </div>
        <div
          className="form-wrapper"
          style={{
            paddingRight: 0,
            margin: 'auto',
            width: '50%',
          }}
        >
          <Paper style={{ padding: 10 }}>
            <Typography
              variant="subheading"
              align="left"
            >
              Властивості мови:
            </Typography>
          </Paper>
          <form className={classes.container} noValidate autoComplete="off">
            <FormControl className={classes.formControl}>
              <Typography
                variant="subheading"
                align="left"
                style={{
                  color: error && !icon ? '#f44336' : 'rgba(0,0,0,.87)',
                }}
              >
                Іконка
              </Typography>
              {type === EDIT_ITEM && icon && (
                <img
                  className={classes.itemIcon}
                  src={icon.resize || icon.href}
                  alt=""
                />
              )}

              <DropZone handler={({ id }) => this.setState({ icon: id })} />

            </FormControl>

            <TextField
              id="standard-title"
              label="Заголовок"
              className={classes.textField}
              value={title}
              error={error && !title}
              onChange={this.handleChange('title')}
              margin="normal"
            />

            <TextField
              id="standard-priority"
              label="Пріоритет"
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
              className={classes.textField}
              value={priority}
              error={error && !priority}
              onChange={this.handleChange('priority')}
              margin="normal"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={is_active}
                  onChange={this.handleCheck('is_active')}
                  color="primary"
                />
              }
              label={is_active ? 'Активна' : 'Неактивна'}
            />

            <TextField
              id="standard-locale"
              label="Підпис"
              className={classes.textField}
              value={locale}
              error={error && !locale}
              onChange={this.handleChange('locale')}
              margin="normal"
            />

            <FormControl className={classes.formControl}>
              <InputLabel
                htmlFor="language-header_logo"
                error={error && !header_logo}
              >
                Лого хедера
              </InputLabel>
              <Select
                open={openHeader}
                onClose={this.handleClose}
                onOpen={() => this.handleOpen('openHeader')}
                value={header_logo}
                onChange={this.handleChange('header_logo')}
                inputProps={{
                  name: 'header_logo',
                  id: 'language-header_logo',
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {files.map((item, index) => (
                  <MenuItem
                    key={`header-files-${index}`}
                    value={item.file}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      height: 'auto',
                    }}
                  >
                    <img src={`${BACK_URL}/${item.path}/${item.file}`} alt="" />
                    <p style={{ paddingLeft: 10 }}>{item.file}</p>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel
                htmlFor="language-footer_logo"
                error={error && !footer_logo}
              >
                Лого футера
              </InputLabel>
              <Select
                open={openFooter}
                onClose={this.handleClose}
                onOpen={() => this.handleOpen('openFooter')}
                value={footer_logo}
                onChange={this.handleChange('footer_logo')}
                inputProps={{
                  name: 'footer_logo',
                  id: 'language-footer_logo',
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {files.map((item, index) => (
                  <MenuItem
                    key={`footer-files-${index}`}
                    value={item.file}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      height: 'auto',
                    }}
                  >
                    <img src={`${BACK_URL}/${item.path}/${item.file}`} alt="" />
                    <p style={{ paddingLeft: 10 }}>{item.file}</p>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        </div>

        {type !== EDIT_ITEM && (
          <React.Fragment>
            <Typography
              style={{ margin: '20px 0 10px' }}
              variant="subheading"
              align="left"
            >
              Меню для нової мови:
            </Typography>
            <ul className={classes.menuWrapper}>
              {menus.map((item, index) => (
                <li
                  key={`language-menu-item-${item.id}`}
                  className={classes.menuItem}
                  style={{ width: '33%' }}
                >
                  <TextField
                    id="menu-title"
                    label={`Меню - ${index + 1}`}
                    defaultValue={item.title}
                    onChange={this.handleMenuChange(index, 'title')}
                    margin="normal"
                  />
                </li>
              ))}
            </ul>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default CreateLanguages = withStyles(styles)(CreateLanguages);
