import React, { Component } from 'react';
import '../../assets/styles/nav_components/users.scss';
import EditPartner from "../SubNavComponents/EditPartner";
import AllPartners from "../SubNavComponents/AllPartners";
import CreatePartner from "../SubNavComponents/CreatePartner";

import EditPartnerType from "../SubNavComponents/EditPartnerType";
import AllPartnerTypes from "../SubNavComponents/AllPartnerTypes";
import CreatePartnerType from "../SubNavComponents/CreatePartnerType";


class Partners extends Component {
  state = {
      curTabId: 0,
      editMode: false,
      editModePartnerTypes: false,
      editObject: {},
  };

  toggleCurNav(curTabId) {
    this.setState({ curTabId, editMode: false, editModePartnerTypes: false });
  }

  toEdit = (obj) => {
    this.setState({curTabId: 1, editMode: true, editObject: obj[0], editModePartnerTypes: false});
  };

  toEditPartnerTypes = (obj) => {
    this.setState({curTabId: 3, editModePartnerTypes: true, editObject: obj[0], editMode: false});
  };

  render() {
    const { curTabId, editObject } = this.state;

    const params = [
      {component: <AllPartners toEdit={this.toEdit}/>, name: "Всі партнери"},
      {component: this.state.editMode ? <EditPartner object={editObject}/> : <CreatePartner />,
        name: this.state.editMode ? "Редагувати партнера" : "Створити партнера"},

      {component: <AllPartnerTypes toEdit={this.toEditPartnerTypes}/>, name: "Всі типи партнерів"},
      {component: this.state.editModePartnerTypes ? <EditPartnerType object={editObject}/> : <CreatePartnerType />,
        name: this.state.editModePartnerTypes ? "Редагувати тип партнера" : "Створити тип партнера"}
    ];

    return (
      <React.Fragment>
        <div className="user-content">
          <div className="user-tabs-wrapper">
            <div className="tab-line">
              {
                params.map((item, index) => (
                  <div
                    className={curTabId === index ? 'tab-item tab-active' : 'tab-item'}
                    key={`nav-item-${item.name}`}
                    onClick={() => this.toggleCurNav(index)}
                  >
                    <div className="tab-item-block">
                      {item.name}
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

          {params[curTabId].component}

        </div>
      </React.Fragment>
    );
  }
}

export default Partners;
