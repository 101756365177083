import {
  CLEAR_ERROR,
  GET_ERRORS,
} from '../constants/redux';

const initialState = [];

const errors = (state = initialState, action) => {
  switch (action.type) {
    case GET_ERRORS:
      console.log(action);
      return [...state, action.payload];
    case CLEAR_ERROR:
      return initialState;
    default:
      return state;
  }
};

export default errors;
