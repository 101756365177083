import React, { Component } from 'react';
import '../../assets/styles/nav_components/main.scss';
import '../../assets/styles/show.scss';
import { allFalse, findPartnerTypesList } from '../../actions/common';
import Button from '@material-ui/core/Button';
import fetchOne from '../../services/fetch';
import { GET_LANGUAGES, PARTNER_TYPES_PATH } from '../../constants/urls';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TabContainer } from '../util/TabContainer';
import { Save } from '@material-ui/icons';
import PartnerTypesLocalization from '../Localization/PartnerTypesLocalization';
import store from "../../store";
import {REQUEST_MESSAGE} from "../../constants/redux";

class CreatePartnerType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      fields: { priority: true, title: true },
      errors: {},
      languages: [],
      selectedLanguage: 0,
      localizationsData: {},
      localizationId: '',
      partner_types: [],
      priority: 0,
    };
  }

  async componentDidMount() {
    try {
      const languages = await fetchOne({
        url: GET_LANGUAGES,
      });
      const partner_types = await findPartnerTypesList();
      this.setState({ languages, partner_types, loading: false });
    } catch (err) {
      console.log(err.message);
    }
  }

  handleImageChange = (res) => {
    const {languages, selectedLanguage} = this.state;
    this.setState({
      localizationsData: {
        ...this.state.localizationsData,
        [languages[selectedLanguage].id]: {...this.state.localizationsData[languages[selectedLanguage].id], logo: res.id, resize: res.resize }
      }
    });
  };


  handleChangeLanguage = (event, value) => {
    this.setState({ selectedLanguage: value });
  };

  changeLocalizationData = (event, id) => {
    const {
      target: { name, value },
    } = event;
    if(undefined !== this.state.fields[name]) {
      this.setState({
        fields: {...this.state.fields, [name]: (!value || value.length === 0)},
        errors: {...this.state.errors, [name]: (!value || value.length === 0)},
        localizationsData: {...this.state.localizationsData, [id]: {...this.state.localizationsData[id], [name]: value}}
      });
    } else {
      this.setState({
        localizationsData: {
          ...this.state.localizationsData,
          [id]: { ...this.state.localizationsData[id], [name]: value },
        },
      });
    }
  };

  async handleSubmit(event) {
    event.preventDefault();
    const { localizationsData, priority, fields } = this.state;

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    if(!Object.entries(localizationsData).length){
      store.dispatch({
        type: REQUEST_MESSAGE,
        data: {
          message: 'Хоча б одна мова має бути заповнена!',
          type: 'error',
        }
      });
    }
    if (allFalse(fields)) {
      const formData = {
        priority: parseInt(priority),
      };

      try {
        let partnerTypeLoc = '';
        const part = await fetchOne({
          url: PARTNER_TYPES_PATH,
          method: 'POST',
          body: JSON.stringify(formData),
          headers: headers,
        });

        const partner_type = await part.json();

        for (let [key, value] of Object.entries(localizationsData)) {
          let partnerTypeLocalizationData = {
            localization: key,
            description: value.description ? value.description : '',
            logo: value.logo && (value.logo.id || value.logo),
            title: value.title ? value.title : '',
          };

          partnerTypeLoc = await fetchOne({
            url: `${PARTNER_TYPES_PATH}/${partner_type.id}/localizations`,
            method: 'POST',
            body: JSON.stringify(partnerTypeLocalizationData),
            headers: headers,
          });
        }
      } catch (err) {
        console.log(err.message);
      }
    } else {
      this.setState({errors: fields});
    }
  }

  onChange(e) {
    const {
      target: { name, value },
    } = e;
    if(undefined !== this.state.fields[name]){
      this.setState(prevState => ({
        fields: {...prevState.fields, [name]: (!value || value.length === 0)},
        errors: {...prevState.errors, [name]: (!value || value.length === 0)},
        [name]: value
      }));
    } else {
      this.setState({ [name]: value });
    }
  }

  render() {
    const {
      errors,
      localizationsData,
      languages,
      selectedLanguage,
      priority,
    } = this.state;

    return (
      <React.Fragment>
        <div className="tab-block-content">
          <div className="submit-button_block">
            <Button
              onClick={e => this.handleSubmit(e)}
              variant="contained"
              color="primary"
            >
              <Save style={{ color: '#fff' }} />
            </Button>
          </div>

          <div className="show-block">
            <div className="partners-show-block-form">
              <TextField
                autoFocus
                name="priority"
                margin="dense"
                id="priority"
                label="Пріоритет"
                type="input"
                onChange={e => this.onChange(e)}
                value={priority}
                error={errors.priority}
                fullWidth
              />

            </div>

            <div className="show-block-additions">
              {languages.length ? (
                <div className="">
                  <AppBar position="static" color="default">
                    <Tabs
                      value={selectedLanguage}
                      onChange={this.handleChangeLanguage}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                    >
                      {languages.map((item, i) => (
                        <Tab label={item.title} key={`teb-lang-${i}`} />
                      ))}
                    </Tabs>
                  </AppBar>
                  {
                    <TabContainer>
                      <PartnerTypesLocalization
                        localizationId={languages[selectedLanguage].id}
                        changeLocalizationData={this.changeLocalizationData}
                        localizationsData={localizationsData}
                        errors={errors}
                        handleImageChange={this.handleImageChange}
                      />
                    </TabContainer>
                  }
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CreatePartnerType;
