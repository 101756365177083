import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import '../../assets/styles/categories.scss';
import '../../assets/styles/dropzone.scss';

import { toISODate } from '../../services/main';
import fetchOne from '../../services/fetch';

import { CORE_URL } from '../../constants/urls';
import { EDIT_ITEM } from '../../constants/types';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import { Save } from '@material-ui/icons';
import Button from '@material-ui/core/Button/Button';
import Switch from '@material-ui/core/Switch/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Paper from '@material-ui/core/Paper/Paper';
import DatePicker from 'react-datepicker/es';
import uk from 'date-fns/locale/uk';

import DropZone from '../util/DropZone';
import Loader from "../util/Loader";
import Tooltip from '@material-ui/core/Tooltip';
import Delete from '@material-ui/icons/Delete';
import store from "../../store";
import {REQUEST_MESSAGE} from "../../constants/redux";
import ModalWindow from '../../views/layout/ModalWindow';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    alignItems: 'center',
    width: '100%',
  },
  dense: {
    marginTop: 20,
  },
  menu: {
    width: '100%',
  },
  formControl: {
    margin: theme.spacing.unit,
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit,
  },
  datePickerWrapper: {
    marginTop: 20,
    padding: '0 5px',
    width: '100%',
  },
});

const BannerModel = {
  is_active: true,
  priority: 1,
  image: '',
  title: '',
  link: '',
  idLocal: '', // id локализации
};

class CreateBanners extends React.Component {
  state = {
    publishEndAt: null,
    publishAt: null,
    active: true,

    content: [],

    languages: [],

    activeTab: 0,

    errors: [],
    updated: [],

    loading: true,
    submited: true,

    open: false,
  };

  componentDidMount() {
    this.getLanguages();
  }

  getLanguages = async () => {
    const { id, type } = this.props;
    const languages = await fetchOne({ url: `${CORE_URL}/languages` });

    if (type === EDIT_ITEM) {
      const {
        banner_localizations,
        is_active,
        published_end_at,
        published_at,
      } = await fetchOne({ url: `${CORE_URL}/banners/${id}` });

      let content = new Array(languages.length)
        .fill(BannerModel)
        .map((item, i) => ({ ...item, localization: languages[i].id }));

      if (banner_localizations.length) {
        content = content.map(item => {
          const page = banner_localizations.find(
            el => item.localization === el.localization.id,
          );
          if (page) {
            const {
              image,
              title,
              is_active,
              link,
              priority,
            } = page;

            const idLocal = page.id; // добавляем id локализации

            return {
              ...item,
              image,
              title,
              is_active,
              link,
              priority,
              idLocal, // добавляем id локализации
            };
          }
          return item;
        });
      }

      this.setState({
        publishEndAt: published_end_at ? new Date(published_end_at) : null,
        publishAt: published_at ? new Date(published_at) : null,
        active: is_active,

        updated: banner_localizations,

        languages,
        content,

        loading: false,
      });
    } else {
      const content = new Array(languages.length)
        .fill(BannerModel)
        .map((item, i) => ({ ...item, localization: languages[i].id }));
      this.setState({ languages, content, loading: false });
    }
  };

  validateLocalization = () => {
    const { content } = this.state;
    const required = ['image'];

    const filtered = content.map(item => {
      const entries = Object.entries(item);
      return entries.reduce(
        (prev, [key, val]) =>
          typeof val === 'string' ? { ...prev, [key]: val } : prev,
        {},
      );
    });

    return filtered.map(item => {
      const entries = Object.entries(item);
      return entries.map(([key, val]) =>
        required.indexOf(key) > -1 && !val ? key : '',
      );
    });
  };

  submitPage = () => {
    const errors = this.validateLocalization();
    const isValid = errors.some(arr =>
      arr.length ? arr.every(el => !el) : arr.length,
    );

    if (isValid) this.createPage();
    this.setState({ errors, submited: true });
  };

  createPage = async () => {
    try {
      const { content, active, publishAt, publishEndAt } = this.state;

      const isEdit = this.props.type === EDIT_ITEM;
      let method = isEdit ? 'PUT' : 'POST';
      let url = isEdit
        ? `${CORE_URL}/banners/${this.props.id}`
        : `${CORE_URL}/banners`;

      const bannersBody = JSON.stringify({
        ...(publishAt && { published_at: toISODate(publishAt) }),
        ...(publishEndAt && { published_end_at: toISODate(publishEndAt) }),
        is_active: active,
      });

      const createPage = await fetchOne({ url, method, body: bannersBody });

      if (createPage.status === 201 || isEdit) {
        const valid = content.filter(el => el.image && typeof el.priority === 'number');
        const pageData = await createPage.json();

        try {
          const toCompare = ['link', 'image', 'title', 'priority'];
          const isUpdated = this.state.updated.map(item => {
            const destructed = Object.entries(item).reduce(
              (prev, [key, val]) => {
                const changed = content.find(
                  el => el.localization === item.localization.id,
                )[key];
                const condition = changed !== val && toCompare.indexOf(key) > -1;
                return condition ? { ...prev, [key]: changed } : prev;
              },
              {},
            );
            return {
              ...destructed,
              local_id: item.id,
              id: item.localization.id,
            };
          });

          const createLocalization = await Promise.all(
            valid.map(obj => {
              let localizationBody;

              const {
                priority, image, title, localization, link
              } = obj;

              const item = isUpdated.find(el => el.id === localization);

              if (item) {
                const { id, local_id, ...updatedValues } = item;
                url = `${CORE_URL}/banners/${id}/localizations/${local_id}`;
                localizationBody = JSON.stringify({ ...updatedValues });
                method = 'PUT';
              } else {
                url = `${CORE_URL}/banners/${pageData.id}/localizations`;
                localizationBody = JSON.stringify({
                  priority, image, title, localization, link
                });
                method = 'POST';
              }

              return fetchOne({ url, method, body: localizationBody });
            }),
          );

          const isSuccess = createLocalization.some(
            res => res && (res.status === 201 || res.status === 204),
          );
          if (isSuccess) this.props.update();
        } catch (error) {
          console.log(error);
          this.setState({ submited: true });
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ submited: true });
    }
  };

  changeLocalization = name => ({ target }) => {
    const { activeTab } = this.state;
    const value =
      target.type === 'number' ? parseInt(target.value) : target.value;
    this.setState(state => ({
      content: [
        ...state.content.map((item, index) =>
          index === activeTab ? { ...item, [name]: value } : item,
        ),
      ],
      submited: false,
    }));
  };

  changeLocalizationImage = (res) => {
    const { activeTab } = this.state;
    this.setState(state => ({
      content: [
        ...state.content.map((item, index) =>
          index === activeTab ? { ...item, image: res.id, href: res.href } : item,
        ),
      ],
      submited: false,
    }));
  };

  handleChange = name => ({ target }) =>
    this.setState({ [name]: target.value, submited: false });

  handleDateChange = name => date =>
    this.setState({ [name]: date, submited: false });

  handleCheck = name => event =>
    this.setState({ [name]: event.target.checked });

  changeTab = (event, value) => this.setState({ activeTab: value });

  // открытие модального окна
  openModal = () => this.setState({ open: true });
  // закрытие (отмена) модального окна по подтверждению удалиения локализации
  handleCancel = () => this.setState({ open: false });
  // DELETE, /api/v1/banners/{id}/localizations/{localizationId}
  // Удалить существующую локализацию банера
  deleteLocalizationBanner = async (id, localizationId) => {
    const { content } = this.state;
    if(content.filter(l => !!l.idLocal).length === 1){
      store.dispatch({
        type: REQUEST_MESSAGE,
        data: {
          message: 'Хоча б одна мова має бути заповнена!',
          type: 'error',
        }
      });
      return false;
    }

    const deleteLocalization = await fetchOne({
      url: `${CORE_URL}/banners/${id}/localizations/${localizationId}`,
      method: 'DELETE',
    });

    if (deleteLocalization && deleteLocalization.ok) {
      this.componentDidMount();
      this.handleCancel();
    } else {
      console.log(deleteLocalization.statusText)
      this.handleCancel();
    }
  };

  render() {
    const { classes } = this.props;
    const {
      publishAt, publishEndAt, active, open,
      languages, loading, activeTab, errors, submited, content
    } = this.state;
    const error = errors[activeTab];

    if (loading) return (<Loader/>);

    const dis = content[activeTab] && content[activeTab].idLocal === '';

    return (
      <div className="category-create_wrapper">
        <div className="submit-button_wrapper">

          {/* кнопка удалить локализацию */}
          {this.props.type === "EDIT_ITEM" &&
          <Tooltip title="Видалити локализацію" placement="left"
                   onClick={this.openModal}>
            <Button disabled={dis}
                    style={{ marginRight: '10px' }} variant="contained"
                    color="secondary">
              <Delete style={{ color: '#fff' }}/>
            </Button>
          </Tooltip>
          }
          {/* конец --- кнопка удалить локализацию */}

          <Button onClick={this.submitPage} variant="contained" color="primary">
            <Save style={{ color: '#fff' }} />
          </Button>
        </div>

        <div className="form-wrapper">
          <Paper style={{ padding: '11px 10px' }}>
            <Typography variant="subheading" align="left">
              Властивості банера:
            </Typography>
          </Paper>
          <form className={classes.container} noValidate autoComplete="off">
            <FormControlLabel
              control={
                <Switch
                  checked={active}
                  onChange={this.handleCheck('active')}
                  color="primary"
                />
              }
              label={active ? 'Активний' : 'Неактивний'}
            />

            <div className={classes.datePickerWrapper}>
              <Typography variant="subheading" align="left">
                Дата і час від:
              </Typography>
              <DatePicker
                selected={publishAt}
                showDisabledMonthNavigation
                onChange={this.handleDateChange('publishAt')}
                dateFormat="d MMMM, yyyy HH:mm"
                timeFormat="HH:mm"
                timeInputLabel="Час:"
                showTimeInput
                isClearable
                locale={uk}
              />
            </div>

            <div className={classes.datePickerWrapper}>
              <Typography variant="subheading" align="left">
                Дата і час до:
              </Typography>
              <DatePicker
                selected={publishEndAt}
                // minDate={publishAt ? (new Date(publishAt)) : ''}
                showDisabledMonthNavigation
                onChange={this.handleDateChange('publishEndAt')}
                dateFormat="d MMMM, yyyy HH:mm"
                timeFormat="HH:mm"
                timeInputLabel="Час:"
                showTimeInput
                isClearable
                locale={uk}
              />
            </div>
          </form>
        </div>

        <div className="form-wrapper form-big">
          <AppBar position="static" color="default">
            <Tabs
              value={activeTab}
              onChange={this.changeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              {languages.map(language => (
                <Tab
                  key={`language-tab-${language.id}`}
                  label={language.title}
                />
              ))}
            </Tabs>
          </AppBar>
          {this.state.content
            .filter((el, index) => index === activeTab)
            .map((item, index) => (
              <form
                key={`form-item-${index}`}
                className={classes.container}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="standard-priority"
                  label="Пріоритет"
                  className={classes.textField}
                  type="number"
                  InputProps={{ inputProps: { min: 1 } }}
                  value={item.priority}
                  onChange={this.changeLocalization('priority')}
                  margin="normal"
                  fullWidth
                />

                <TextField
                  id="standard-title"
                  label="Заголовок"
                  className={classes.textField}
                  value={item.title}
                  onChange={this.changeLocalization('title')}
                  margin="normal"
                  fullWidth
                />

                <TextField
                  id="standard-link"
                  label="Посилання"
                  className={classes.textField}
                  value={item.link}
                  onChange={this.changeLocalization('link')}
                  margin="normal"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <FormControl className={classes.formControl}>
                  <Typography
                    style={{
                      color: (error && error.some(el => el === 'image') && submited) ? '#f44336' : '#000',
                      textAlign: 'left',
                    }}
                    variant="subheading"
                  >
                    Зображення
                  </Typography>
                  {item.image && (
                    <img
                      src={item.image && (item.image.resize || item.image.href)}
                      style={{ marginBottom: 5 }}
                      alt=""
                    />
                  )}
                  {item.href && (
                    <img
                      src={item.href && (item.href || item.href)}
                      style={{ marginBottom: 5 }}
                      alt=""
                    />
                  )}
                  <DropZone handler={this.changeLocalizationImage} removeFile />
                </FormControl>
              </form>
            ))}
        </div>

        {/* Всплывающее диалоговое окно по удалению локализации */}
        <ModalWindow
          open={open}
          title="Дійсно бажаєте видалити локалізацію?"
          onClose={this.handleCancel}
          onConfirm={() => this.deleteLocalizationBanner(this.props.id, this.state.content[activeTab].idLocal)}
        />

      </div>
    );
  }
}

export default withStyles(styles)(CreateBanners);
