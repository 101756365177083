import pushStatus from "../services/pushStatus";
import { getTokenInfo } from "../services/auth";

const fetchOne = async ({
  url,
  method = 'GET',
  headers = {},
  body = {},
  ...rest
}) => {
  const auth = getTokenInfo();
  const params = {
    ...rest,
    method,
    ...(method !== 'GET' && { body }),
    headers: {
      ...(auth && {
        'Authorization': `${auth.token_type} ${auth.access_token}`,
      }),
      ...headers,
    },
  };

  const response = await fetch(url, params);
  pushStatus(method, response);

  return method === 'GET' ? response.json() : response;
};

export default fetchOne;
