import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import '../../assets/styles/categories.scss';

import fetchOne from "../../services/fetch";
import {CORE_URL} from "../../constants/urls";
import {EDIT_ITEM} from '../../constants/types';

import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import {Save} from "@material-ui/icons";
import Button from "@material-ui/core/Button/Button";

import Tooltip from '@material-ui/core/Tooltip';
import Delete from '@material-ui/icons/Delete';
import store from "../../store";
import {REQUEST_MESSAGE} from "../../constants/redux";
import {allFalse} from "../../actions/common";
import ModalWindow from '../../views/layout/ModalWindow';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: '95%',
    },
    dense: {
        marginTop: 20,
    },
    menu: {
        width: '95%',
    },
    formControl: {
        margin: theme.spacing.unit,
        alignItems: 'center',
        width: '95%',
    },
    button: {
        margin: theme.spacing.unit,
    },
});

const PageModel = {
    localization: '',
    name: '',
    link: '',
    idLocal: '', // id локализации
};

class CreateTelegramBot extends React.Component {
    state = {
        open: false,

        content: [],

        languages: [],

        activeTab: 0,

        errors: {},

        fields : {
            link: true,
            name: true
        },
        updated: [],

        loading: true,
    };

    componentDidMount() {
        this.getLanguages();
    }

    getLanguages = async () => {
        const { id, type } = this.props;
        const { activeTab } = this.state;
        const languages = await fetchOne({ url: `${CORE_URL}/languages` });

        if (type === EDIT_ITEM) {
            const { telegram_bot_localizations } = await fetchOne({ url: `${CORE_URL}/telegram-bots/${id}` });

            let content = new Array(languages.length)
                .fill(PageModel).map((item, i) => ({ ...item, localization: languages[i].id }));

            if (telegram_bot_localizations.length) {
                content = content.map(item => {
                    const page = telegram_bot_localizations.find(el => item.localization === el.localization.id);
                    if (page) {
                        const {
                                  name, link, id
                              } = page;
                        const idLocal = page.id; // добавляем id локализации

                        return {
                            ...item,
                            name, link, id,
                            idLocal,  // добавляем id локализации
                        };
                    }
                    return item;
                });
            }

            this.setState({
                updated: telegram_bot_localizations,
                fields: {
                    link: !content[activeTab].link,
                    name: !content[activeTab].name
                },
                // priority,
                // is_active,
                // zone,

                languages,
                content,

                loading: false,
            });
        } else {
            const content = new Array(languages.length)
                .fill(PageModel).map((item, i) => ({ ...item, localization: languages[i].id }));
            this.setState({ languages, content, loading: false });
        }
    };


    createPage = async () => {
        try {
            const { content, fields } = this.state;

            const isEdit = this.props.type === EDIT_ITEM;

            let createPage = {};

            if (allFalse(fields)) {

            // let url = this.props.id
            //     ? `${CORE_URL}/telegram-bots/${this.props.id}/localizations`
            //     : `${CORE_URL}/telegram-bots`;
            //
            // let method = isEdit ? 'PUT' : 'POST';

            if (!isEdit) {
                createPage = await fetchOne({ url: `${CORE_URL}/telegram-bots`, method: 'POST' });
            }

            if (createPage.status === 201 || isEdit) {
                const pageData = isEdit ? this.props : await createPage.json();
                try {
                    // const toCompare = ['name'];
                    // const isUpdated = this.state.updated.map(item => {
                    //     const destructed = Object.entries(item).reduce((prev, [key, val]) => {
                    //         const changed = content.find(el => el.localization === item.localization.id)[key];
                    //         const condition = (toCompare.indexOf(key) > -1) && changed !== val;
                    //         return condition ? ({ ...prev, [key]: changed }) : prev;
                    //     }, {});
                    //     return {
                    //         ...destructed,
                    //         name: item.name,
                    //         link: item.link,
                    //     }
                    // });
                    console.log('pageData', isEdit, pageData);
                    const createLocalization = await Promise.all(content
                      .filter(el => el.name !== '' && el.link !== '')
                      .map(obj => {
                          const {
                              name, link, localization, id
                          } = obj;

                          let localizationBody;
                          // const item = isUpdated.find(el => el.id === localization);

                          let url = null;
                          let method = null;

                          if (undefined !== id) {
                              // const { name, link } = item;
                              url = `${CORE_URL}/telegram-bots/${pageData.id}/localizations/${id}`;
                              // localizationBody = JSON.stringify({ ...updatedValues });
                              localizationBody = JSON.stringify({
                                  name,
                                  link,
                              });
                              method = 'PUT';
                          } else {

                              url = `${CORE_URL}/telegram-bots/${pageData.id}/localizations`;
                              localizationBody = JSON.stringify({
                                  localization,
                                  name,
                                  link,
                              });
                              method = 'POST';
                          }

                          return fetchOne({ url, method, body: localizationBody });
                      }));

                    const isSuccess = createLocalization.some(res => res && (res.status === 201 || res.status === 204));
                    if (isSuccess) this.props.update();
                } catch (error) {
                    console.log(error);
                }
            }

        } else {
            this.setState({errors: fields});
        }

        } catch (error) {
            console.log(error);
        }
    };




    changeLocalization = name => event => {
        const { activeTab } = this.state;
        const target = event.target
            ? event.target.value
            : event.id;

        this.setState(state => ({
            content: [
                ...state.content
                    .map((item, index) => index === activeTab
                        ? ({ ...item, [name]: target })
                        : item)
            ],
            fields: {...state.fields, [name]: (!target || target.length === 0)},
            errors: {...state.errors, [name]: (!target || target.length === 0)},
        }));
    };

    changeTab = (event, value) => this.setState({ activeTab: value });

    // открытие модального окна
    openModal = () => this.setState({ open: true });
    // закрытие (отмена) модального окна по подтверждению удалиения локализации
    handleCancel = () => this.setState({ open: false });
    // DELETE, /api/v1/telegram-bots/{id}/localizations/{localization}
    // Удалить существующую локализацию страницы
    deleteLocalizationBots = async (id, localizationId) => {
        const { content } = this.state;
        if(content.filter(l => !!l.idLocal).length === 1){
            store.dispatch({
                type: REQUEST_MESSAGE,
                data: {
                    message: 'Хоча б одна мова має бути заповнена!',
                    type: 'error',
                }
            });
            return false;
        }
        const deleteLocalization = await fetchOne({
            url: `${CORE_URL}/telegram-bots/${id}/localizations/${localizationId}`,
            method: 'DELETE',
        });

        if (deleteLocalization && deleteLocalization.ok) {
            this.getLanguages();
            this.handleCancel();
        } else {
            console.log(deleteLocalization.statusText);
            this.handleCancel();
        }
    };


    render() {
        const { classes } = this.props;
        const {
                  languages, errors,
                  loading, activeTab, content, open,
              } = this.state;

        if (loading) return (
            <div style={{ padding: 20 }}>
                <h3>Загрузка...</h3>
            </div>
        );

        const dis = content[activeTab] && content[activeTab].idLocal === '';

        return (
            <div className="system-translation-create_wrapper">

                {/* Всплывающее диалоговое окно по удалению локализации */}
                <ModalWindow
                  open={open}
                  title="Дійсно бажаєте видалити локалізацію?"
                  onClose={this.handleCancel}
                  onConfirm={() => this.deleteLocalizationBots(this.props.id, this.state.content[activeTab].idLocal)}
                />

                <div className="submit-button_wrapper">

                    {/* кнопка удалить локализацию */}
                    {this.props.type === "EDIT_ITEM" &&
                    <Tooltip title="Видалити локализацію"
                             placement="left"
                             onClick={this.openModal}>
                        <Button disabled={dis}
                                style={{ marginRight: '10px' }} variant="contained"
                                color="secondary">
                            <Delete style={{ color: '#fff' }}/>
                        </Button>
                    </Tooltip>
                    }
                    {/* конец --- кнопка удалить локализацию */}


                    <Button
                        onClick={this.createPage}
                        variant="contained"
                        color="primary"
                    >
                        <Save style={{ color: '#fff' }} />
                    </Button>
                </div>

                <div className="form-wrapper form-big">
                    <AppBar position="static" color="default">
                        <Tabs
                            value={activeTab}
                            onChange={this.changeTab}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                        >
                            {languages.map(language => (
                                <Tab
                                    key={`language-tab-${language.id}`}
                                    label={language.title}
                                />
                            ))}
                        </Tabs>
                    </AppBar>
                    {this.state.content
                        .filter((el, index) => index === activeTab)
                        .map((item, index) => (
                            <form
                                key={`form-item-${index}`}
                                className={classes.container}
                                noValidate
                                autoComplete="off"
                            >
                                <TextField
                                    id="name"
                                    label="Назва"
                                    className={classes.textField}
                                    value={item.name}
                                    error={errors.name}
                                    onChange={this.changeLocalization('name')}
                                    margin="normal"
                                />

                                <TextField
                                  id="link"
                                  label="Посилання"
                                  className={classes.textField}
                                  value={item.link}
                                  error={errors.link}
                                  onChange={this.changeLocalization('link')}
                                  margin="normal"
                                  helperText={errors.link ? 'Обов\'язкове поле' : ''}
                                />

                            </form>
                        ))}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(CreateTelegramBot);
