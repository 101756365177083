import React, { Component } from 'react';
import '../../assets/styles/nav_components/main.scss';
import '../../assets/styles/components/departments.scss';
import PartnerTypesTableHead from "../PartnerTypesTableHead";

class AllPartnerTypes extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="tab-block-content no-shadow">

          <PartnerTypesTableHead toEdit={this.props.toEdit} />
        </div>
      </React.Fragment>
    );
  }
}

export default AllPartnerTypes;
