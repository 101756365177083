import React, { Component } from 'react';
import '../../assets/styles/nav_components/main.scss';
import '../../assets/styles/show.scss';
import Button from "@material-ui/core/Button";
import fetchOne from "../../services/fetch";
import {GET_LANGUAGES, PARTNER_TYPES_PATH} from "../../constants/urls";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {TabContainer} from "../util/TabContainer";
import {Delete, Save} from "@material-ui/icons";
import PartnerTypesLocalization from "../Localization/PartnerTypesLocalization";
import Tooltip from "@material-ui/core/Tooltip";
import {allFalse} from "../../actions/common";
import ModalWindow from '../../views/layout/ModalWindow';


function createLocalizationsData(arr) {
  const localizationData = {};

  for (let i = 0; i < arr.length; i++) {
    let locId = arr[i].localization.id;

    let locData = {
      id: arr[i].id,
      description: arr[i].description ? arr[i].description : '',
      title: arr[i].title ? arr[i].title : '',
      logo: arr[i].logo ? arr[i].logo : null,
    };
    localizationData[locId] = locData;
  }
  return localizationData;
}

class EditPartnerType extends Component {
  constructor(props) {
    super(props);
    const localizationsData = createLocalizationsData(props.object.partner_type_localizations);
    this.state = {
      open: false,
      fields: { priority: !props.object.priority, title: !Object.values(localizationsData).find(l => !!l.title.length) },
      errors: {},
      languages: [],
      selectedLanguage: 0,
      localizationsData: localizationsData,
      localizationId: '',
      priority: props.object.priority
    };
  }

  async componentDidMount() {
    try {
      const languages = await fetchOne({
        url: GET_LANGUAGES,
      });
      this.setState({languages, loading: false});
    } catch (err) {
      console.log(err.message);
    }
  }

  handleImageChange = (res) => {
    const {languages, selectedLanguage} = this.state;
    this.setState({
      localizationsData: {
        ...this.state.localizationsData,
        [languages[selectedLanguage].id]: {...this.state.localizationsData[languages[selectedLanguage].id],  logo: res.id, resize: res.resize }
      }
    });
  };

  handleChangeLanguage = (event, value) => {
    this.setState({selectedLanguage: value});
  };

  changeLocalizationData = (event, id) => {
    const {target: {name, value}} = event;
    if(undefined !== this.state.fields[name]) {
      this.setState({
        fields: {...this.state.fields, [name]: (!value || value.length === 0)},
        errors: {...this.state.errors, [name]: (!value || value.length === 0)},
        localizationsData: {...this.state.localizationsData, [id]: {...this.state.localizationsData[id], [name]: value}}
      });
    } else {
      this.setState({
        localizationsData: {
          ...this.state.localizationsData,
          [id]: { ...this.state.localizationsData[id], [name]: value },
        },
      });
    }
  };

  async handleSubmit(event) {
    event.preventDefault();
    const {localizationsData,
      priority, fields} = this.state;

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };

    if (allFalse(fields)) {
      const formData = {
        priority: parseInt(priority)
      };


      try {
        let partnerLoc = '';
        const part = await fetchOne({
          url: `${PARTNER_TYPES_PATH}/${this.props.object.id}`,
          method: "PUT",
          body: JSON.stringify(formData),
          headers: headers
        });

        const partner = await part.json();

        for (let [key, value] of Object.entries(localizationsData)) {
          let partnerLocalizationData = {
            localization: key,
            description: value.description ? value.description : '',
            logo: value.logo && (value.logo.id || value.logo),
            title: value.title ? value.title : '',
          };

          if(value.id){
            partnerLoc = await fetchOne({
              url: `${PARTNER_TYPES_PATH}/${this.props.object.id}/localizations/${value.id}`,
              method: "PUT",
              body: JSON.stringify(partnerLocalizationData),
              headers: headers
            });

          } else {
            partnerLoc = await fetchOne({
              url: `${PARTNER_TYPES_PATH}/${this.props.object.id}/localizations`,
              method: "POST",
              body: JSON.stringify(partnerLocalizationData),
              headers: headers
            });
          }
        }
      } catch (err) {
        console.log(err.message);
      }
    } else {
      this.setState({errors: fields});
    }
  }

  onChange(e) {
    const {target: {name, value}} = e;
    if(undefined !== this.state.fields[name]){
      this.setState(prevState => ({
        fields: {...prevState.fields, [name]: (!value || value.length === 0)},
        errors: {...prevState.errors, [name]: (!value || value.length === 0)},
        [name]: value
      }));
    } else {
      this.setState({ [name]: value });
    }
  }

  // открытие модального окна
  openModal = () => this.setState({ open: true });
  // закрытие (отмена) модального окна по подтверждению удалиения локализации
  handleCancel = () => this.setState({ open: false });

  deleteLocalizationPartnerType = async (id, localizationId) => {

    const deleteLocalization = await fetchOne({
      url: `${PARTNER_TYPES_PATH}/${id}/localizations/${localizationId}`,
      method: 'DELETE',
    });

    if (deleteLocalization && deleteLocalization.ok) {
      const { languages, localizationsData, selectedLanguage } = this.state;
      const { [languages[selectedLanguage].id]: deleted, ...rest } = localizationsData;

      this.setState({localizationsData: { ...rest }});
      this.componentDidMount();
      this.handleCancel();
    } else {
      console.log(deleteLocalization.statusText);
      this.handleCancel();
    }
  };

  render() {
    const {errors, open,
      localizationsData,
      languages,
      selectedLanguage,
      priority} = this.state;

    const check = (!!languages[selectedLanguage]
        && !!localizationsData[languages[selectedLanguage].id]
        && !!localizationsData[languages[selectedLanguage].id].id);
    return (
      <React.Fragment>
        <div className="tab-block-content">

          {/* Всплывающее диалоговое окно по удалению локализации */}
          <ModalWindow
            open={open}
            title="Дійсно бажаєте видалити локалізацію?"
            onClose={this.handleCancel}
            onConfirm={() => this.deleteLocalizationPartnerType(this.props.object.id, localizationsData[languages[selectedLanguage].id].id)}
          />

          <div className="submit-button_block">

            {/* кнопка удалить локализацию */}
            { check &&
            <Tooltip title="Видалити локализацію" placement="left"
                     onClick={this.openModal}
            >
              <Button
                  style={{ marginRight: '10px' }} variant="contained"
                  color="secondary">
                <Delete style={{ color: '#fff' }}/>
              </Button>
            </Tooltip>
            }
            {/* конец --- кнопка удалить локализацию */}

            <Button
              onClick={(e) => this.handleSubmit(e)}
              variant="contained"
              color="primary"
            >
              <Save style={{color: '#fff'}}/>
            </Button>
          </div>

          <div className="show-block">
            <div className="partners-show-block-form">
              <TextField
                autoFocus
                name="priority"
                margin="dense"
                id="priority"
                label="Пріоритет"
                type="input"
                onChange={(e) => this.onChange(e)}
                value={priority}
                error={errors.priority}
                fullWidth
              />

            </div>

            <div className="show-block-additions">
              {
                languages.length ?
                  <div className="">
                    <AppBar position="static" color="default">
                      <Tabs
                        value={selectedLanguage}
                        onChange={this.handleChangeLanguage}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                      >
                        {
                          languages.map((item, i) =>
                            <Tab label={item.title} key={`teb-lang-${i}`}/>
                          )
                        }
                      </Tabs>
                    </AppBar>
                    {<TabContainer>
                      <PartnerTypesLocalization
                      localizationId={languages[selectedLanguage].id}
                      changeLocalizationData={this.changeLocalizationData}
                      localizationsData={localizationsData}
                      errors={errors}
                      handleImageChange={this.handleImageChange}
                      />
                    </TabContainer>}
                  </div> : ''
              }

            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EditPartnerType;
