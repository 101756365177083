import {
    ALL_BOOK_ORDERS_DOWNLOADED,
} from '../constants/redux';

const initialState = [];

const orders = (state = initialState, action) => {
    switch (action.type) {
        case ALL_BOOK_ORDERS_DOWNLOADED:
            return action.payload;
        default:
            return state;
    }
};

export default orders;
