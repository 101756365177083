import React, { Component } from 'react';
import '../../assets/styles/nav_components/main.scss';
import '../../assets/styles/components/departments.scss';
import TagsTableHead from "../TagsTable";

class AllTags extends Component {

  render() {

    return (
      <React.Fragment>
        <div className="tab-block-content no-shadow">

          <TagsTableHead toEdit={this.props.toEdit} />
        </div>
      </React.Fragment>
    );
  }
}

export default AllTags;
