import React, { Component } from 'react';
import {connect} from "react-redux";
import '../../assets/styles/nav_components/main.scss';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {updateTag} from "../../actions/tags";
import {colors} from "../../constants/main";

class EditAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      curId: props.object.id,
      curObj: {},
      title: props.object.title,
      color: props.object.color,
      fields: {title: true, color: true},
      errors: {},
      open: false,
    };
    this.onChange = this.onChange.bind(this);
  }

  chooseColor(e, color) {
    e.preventDefault();
    this.setState(prevState => ({
      curObj: {
        ...prevState.curObj,
        color: color
      },color: color,
      fields: { ...this.state.fields, color: false }
    }));
  }

  onChange(e) {
    const { target: { name, value } } = e;
    if (value.trim() !== '') {
      this.setState(prevState => ({
        curObj: {...prevState.curObj, [name]: value},
        [name]: value,
        fields: { ...this.state.fields, [name]: false }
      }));
    } else {
      this.setState({ fields: { ...this.state.fields, [name]: true } });
    }
  }

  onUpdateSubmit(e) {
    e.preventDefault();
    this.props.updateTagData(this.state.curObj, this.state.curId);
    this.setState({curId: ''});
  }

  render() {
    const { curObj, errors, color, title } = this.state;

    console.log(this.state);
    return (
      <React.Fragment>
        <div className="tab-block-content">

          <div className="show-block">
            <div className="show-block-form">
              <TextField
                autoFocus
                name="title"
                margin="dense"
                id="title"
                label="Назва"
                type="input"
                onChange={this.onChange}
                value={title}
                error={errors.title}
                fullWidth
              />

              <div className={errors.color ? "tag-color-block-error" : "tag-color-block"}>
                <p>Колір</p>

                {
                  color ?
                    <div className="tag-colors-row">
                      <div className="tag-colors-item"
                           style={{backgroundColor: color}}>
                      </div>
                    </div>
                    : ''
                }
              </div>

              <div className="tag-colors-row">
                {
                  colors.map((item, i) =>
                    <div className="tag-colors-item"
                         key={`tag-colors-item-${i}`}
                         style={{backgroundColor: item.color}}
                         onClick={(e) => this.chooseColor(e, item.color)}
                    >
                    </div>
                  )
                }
              </div>

              <div className="account-submit-block">
                <Button variant="contained" size="small" className="" onClick={(e) => this.onUpdateSubmit(e)}>
                  Зберегти
                </Button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateTagData: (data, id) => {
    dispatch(updateTag(data, id))
  },
});

export default connect(null, mapDispatchToProps)(EditAccount);
