import {
    ALL_BOOK_REVIEWS_DOWNLOADED,
} from '../constants/redux';

const initialState = [];

const reviews = (state = initialState, action) => {
    switch (action.type) {
        case ALL_BOOK_REVIEWS_DOWNLOADED:
            return action.payload;
        default:
            return state;
    }
};

export default reviews;
