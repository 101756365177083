import React, { Component } from 'react';
import '../../assets/styles/nav_components/main.scss';
import BookOrdersTableHead from "../BookOrdersTable";

class BookOrders extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="tab-block-content no-shadow">
          <div className="customers-header-block">
            <span>Список замовлень книг</span>
          </div>
          <BookOrdersTableHead />
        </div>
      </React.Fragment>
    );
  }
}

export default BookOrders;
