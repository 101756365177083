import { ALL_TAGS_DOWNLOADED } from '../constants/redux';

const tags = (state = [], action) => {
  switch (action.type) {
    case ALL_TAGS_DOWNLOADED:
      return action.tags;
    default:
      return state;
  }
};

export default tags;
