import React, { Component } from 'react';
import {CREATE_ITEM, EDIT_ITEM} from '../constants/types';

import AllContacts from "../components/contacts/AllContacts";
import CreateContacts from "../components/contacts/CreateContacts";
import fetchOne from "../services/fetch";
import { CORE_URL } from "../constants/urls";
import ModalWindow from './layout/ModalWindow';

const params = [
  { name: "Всі контакти" },
  { name: "Створити контакт" },
];

const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
};

class Contacts extends Component {
  state = {
      type: CREATE_ITEM,
      open: false,
      section: 0,
      id: '',

      data: [],
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const data = await fetchOne({ url: `${CORE_URL}/system-contacts?limit=100` });
    this.setState({ data, id: '' });
  };

  updateData = () => {
    this.setSection(0);
    this.getData();
  };

  setSection = section => this.setState({ type: CREATE_ITEM, parent_id: '', section });

  setChildSection = (id) => this.setState({ type: CREATE_ITEM, parent_id: id, section: 1 });

  setEdit = (id, type) => this.setState({ id, type, parent_id: '', section: 1 });

  deleteItem = async () => {
    const { id } = this.state;
    try {
      const deleted = await fetchOne({
        url: `${CORE_URL}/system-contacts/${id}`,
        method: 'DELETE',
        headers,
      });

      this.setState({ open: false, id: '' });
      if (deleted.status === 204) this.getData();
    } catch (err) {
      this.setState({ open: false, id: '' });
    }
  };

  openModal = id => this.setState({ id, open: true });

  handleClose = () => this.setState({ open: false });

  render() {
    const {
      section, data, type, parent_id, open, id,
    } = this.state;

    return (
      <div className="user-content">
        <div className="user-tabs-wrapper">
          <div className="tab-line">
            {params.map((item, index) => (
              <div
                className={section === index ? 'tab-item tab-active' : 'tab-item'}
                key={`nav-item-${item.name}`}
                onClick={() => this.setSection(index)}
              >
                <div className="tab-item-block">
                    {(index && type === EDIT_ITEM) ? 'Редагування контакту' : item.name}
                </div>
              </div>
            ))}
          </div>
        </div>

        {section === 0 && (
          <AllContacts
            data={data}
            openModal={this.openModal}
            onEdit={this.setEdit}
            onCreateChild={this.setChildSection}
          />
        )}
        {section === 1 && (
          <CreateContacts
            id={id}
            type={type}
            parent_id={parent_id}
            update={this.updateData}
          />
        )}

        <ModalWindow
          open={open}
          title="Видалити контакт?"
          onClose={this.handleClose}
          onConfirm={this.deleteItem}
        />
      </div>
    );
  }
}

export default Contacts;
