import React, {Component} from 'react';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';

export class MapContainer extends Component {

  state = {
    show: this.props.show
  };

  // mapClicked(mapProps, map, clickEvent) {
  //   const latitude = clickEvent.latLng.lat();
  //   const longitude = clickEvent.latLng.lng();
  //   console.log( latitude + ', ' + longitude );
  // }

  render() {
    return (
      <Map initialCenter={this.props.initialCenter}
           google={this.props.google} zoom={11}
           onClick={(mapProps, map, clickEvent) => {
             this.props.mapClicked(mapProps, map, clickEvent);
             this.setState({show: true});
      }}
      >
        {this.state.show && <Marker
          title={'Офис'}
          position={this.props.initialCenter}
        />}
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: ('')
})(MapContainer)
