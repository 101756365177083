import React, { Component } from 'react';

import { CREATE_ITEM, EDIT_ITEM } from '../constants/types';
import { CORE_URL } from "../constants/urls";

import fetchOne from "../services/fetch";

import AllPages from "../components/pages/AllPages";
import CreatePages from "../components/pages/CreatePages";
import ModalWindow from './layout/ModalWindow';


const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
};


class Pages extends Component {

  state = {
    type: CREATE_ITEM,
    open: false,
    section: 0,
    id: '',
    data: [],
  };

  componentDidMount() {
    this.getData();
  }

  // начальная инициализация "Сторінки", GET, /pages
  getData = async () => {
    const data = await fetchOne({ url: `${CORE_URL}/pages?limit=100` });
    this.setState({
      type: CREATE_ITEM,
      data,
      id: '',
    });
  };

  updateData = () => {
    this.setSection(0);
    this.getData();
  };

  setSection = section => this.setState({ type: CREATE_ITEM, section });

  setEdit = (id, type) => this.setState({ id, type, section: 1 });

  deleteItem = async () => {
    const { id } = this.state;
    try {
      const deleted = await fetchOne({
        url: `${CORE_URL}/pages/${id}`,
        method: 'DELETE',
        headers,
      });

      this.setState({
        type: CREATE_ITEM,
        open: false,
        id: '',
      });
      if (deleted.status === 204) this.getData();
    } catch (err) {
      this.setState({
        type: CREATE_ITEM,
        open: false,
        id: '',
      });
    }
  };

  openModal = id => this.setState({ id: id || '', open: true });

  handleCancel = () => this.setState({ open: false });

  render() {
    const { section, data, type, open, id } = this.state;

    // названия секций для подзголовка
    const params = [
      { name: "Всі сторінки" },
      { name: "Створити сторінку" }
    ];

    return (
      <div className="user-content">

        {/* подзаголовок с названиями секций, всі сторінкі, створити, редагувати.... */}
        <div className="user-tabs-wrapper">
          <div className="tab-line">

            {params.map((item, index) => (
              <div
                className={section === index ? 'tab-item tab-active' : 'tab-item'}
                key={`nav-item-${item.name}`}
                onClick={() => this.setSection(index)}
              >
                <div className="tab-item-block">
                  {(index && type === EDIT_ITEM) ? 'Редагування сторінки' : item.name}
                </div>
              </div>
            ))}

          </div>
        </div>
        {/* Конец подзаголовка */}

        {/* Основная секция, либо список страниц, либо создание/редактирование */}
        {section === 0 && (
          <AllPages
            data={data.collection || []}
            openModal={this.openModal}
            onEdit={this.setEdit}
          />
        )}

        {section === 1 && (
          <CreatePages
            id={id}
            type={type}
            update={this.updateData}
          />
        )}
        {/* Конец - основной секции */}

        {/* Всплывающее диалоговое окно по удалению страниц */}
        <ModalWindow
          open={open}
          title="Видалити сторінку?"
          onClose={this.handleCancel}
          onConfirm={this.deleteItem}
        />
      </div>
    );
  }
}

export default Pages;
