import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import '../../assets/styles/categories.scss';

import fetchOne from '../../services/fetch';
import { CORE_URL } from '../../constants/urls';
import { EDIT_ITEM } from '../../constants/types';

import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { Save } from '@material-ui/icons';
import Button from '@material-ui/core/Button/Button';

import Switch from '@material-ui/core/Switch/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import FormControl from '@material-ui/core/FormControl/FormControl';
import { getTokenInfo } from '../../services/auth';

import Tooltip from '@material-ui/core/Tooltip';
import Delete from '@material-ui/icons/Delete';
import store from "../../store";
import {REQUEST_MESSAGE} from "../../constants/redux";
import DropZone from '../util/DropZone';
import ModalWindow from '../../views/layout/ModalWindow';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '95%',
  },
  dense: {
    marginTop: 20,
  },
  menu: {
    width: '95%',
  },
  formControl: {
    margin: theme.spacing.unit,
    alignItems: 'center',
    width: '95%',
  },
  button: {
    margin: theme.spacing.unit,
  },
});

const PageModel = {
  localization: '',
  signature: '',
  image: '',
  link: '',
  iframe: '',
  idLocal: '', // id локализации
};

class CreateAds extends React.Component {
  state = {
    open: false,

    priority: 1,
    type: 1,
    is_active: true,

    content: [],

    languages: [],

    activeTab: 0,

    errors: [],
    updated: [],

    loading: true,
    submited: false,
  };

  componentDidMount() {
    this.getLanguages();
  }

  getLanguages = async () => {
    const { id, type } = this.props;
    const languages = await fetchOne({ url: `${CORE_URL}/languages` });

    if (type === EDIT_ITEM) {
      const {
        type, priority,
        is_active, advertising_localizations,
      } = await fetchOne({
        url: `${CORE_URL}/advertising/${id}`,
      });

      let content = new Array(languages.length)
        .fill(PageModel)
        .map((item, i) => ({ ...item, localization: languages[i].id }));

      if (advertising_localizations.length) {
        content = content.map(item => {
          const page = advertising_localizations.find(
            el => item.localization === el.localization.id,
          );
          if (page) {
            const { signature, iframe, image, link } = page;
            const idLocal = page.id; // добавляем id локализации

            return {
              ...item,
              signature,
              iframe,
              image,
              link,
              idLocal
            };
          }
          return item;
        });
      }

      this.setState({
        updated: advertising_localizations,

        priority,
        type,
        is_active,

        languages,
        content,

        loading: false,
      });
    } else {
      const content = new Array(languages.length)
        .fill(PageModel)
        .map((item, i) => ({ ...item, localization: languages[i].id }));
      this.setState({ languages, content, loading: false });
    }
  };

  validateLocalization = () => {
    const { content, type } = this.state;
    let required = null;

    if (type === 1) {
      required = ['is_active', 'image'];
    } else {
      required = ['is_active', 'iframe'];
    }

    const filtered = content.map(item => {
      const entries = Object.entries(item);
      return entries.reduce(
        (prev, [key, val]) =>
          typeof val === 'string' ? { ...prev, [key]: val } : prev,
        {},
      );
    });

    return filtered.map(item => {
      const entries = Object.entries(item);
      return entries.map(([key, val]) =>
        required.indexOf(key) > -1 && !val ? key : '',
      );
    });
  };

  submitPage = () => {
    const { type, priority } = this.state;
    const errors = this.validateLocalization();
    const isValid = errors.some(arr => arr.length ? arr.every(el => !el) : !arr.length);

    if (type && isValid && priority) this.createPage();

    this.setState({ errors, submited: true });
  };

  createPage = async () => {
    try {
      const {
        content, type,
        priority, is_active,
      } = this.state;

      const isEdit = this.props.type === EDIT_ITEM;

      let url = this.props.id
        ? `${CORE_URL}/advertising/${this.props.id}`
        : `${CORE_URL}/advertising`;
      console.log(url);
      let method = isEdit ? 'PUT' : 'POST';
      const advBody = JSON.stringify({
        type, is_active, priority: parseInt(priority),
      });

      const createPage = await fetchOne({ url, method, body: advBody });
      console.log(createPage);
      if (createPage.status === 201 || isEdit) {
        const valid = content.filter(el => el.image || el.iframe);
        const pageData = createPage.status ? await createPage.json() : this.props.id;

        try {
          const toCompare = ['signature', 'image', 'iframe', 'link'];
          const isUpdated = this.state.updated.map(item => {
            const destructed = Object.entries(item).reduce(
              (prev, [key, val]) => {
                const changed = content.find(
                  el => el.localization === item.localization.id,
                )[key];
                const condition =
                  toCompare.indexOf(key) > -1 && changed !== val;
                return condition ? { ...prev, [key]: changed } : prev;
              },
              {},
            );
            return {
              ...destructed,
              local_id: item.id,
              id: item.localization.id,
            };
          });

          const createLocalization = await Promise.all(
            valid.map(obj => {
              const { link, iframe, localization, signature, image } = obj;

              let localizationBody;
              const item = isUpdated.find(el => el.id === localization);

              if (item) {
                const { id, local_id, ...updatedValues } = item;
                url = `${CORE_URL}/advertising/${id}/localizations/${local_id}`;
                localizationBody = JSON.stringify({ ...updatedValues });
                method = 'PUT';
              } else {
                url = `${CORE_URL}/advertising/${pageData.id}/localizations`;
                localizationBody = JSON.stringify({
                  localization,
                  signature,
                  iframe,
                  image,
                  link,
                });
                method = 'POST';
              }

              return fetchOne({ url, method, body: localizationBody });
            }),
          );

          const isSuccess = createLocalization.some(
            res => res && (res.status === 201 || res.status === 204),
          );
          if (isSuccess) this.props.update();
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  changeLocalization = name => event => {
    const { activeTab } = this.state;
    const target = event.target ? event.target.value : event.id;

    this.setState(state => ({
      content: [
        ...state.content.map((item, index) =>
          index === activeTab ? { ...item, [name]: target } : item,
        ),
      ],
      submited: false,
    }));
  };

  changeLocalizationImage = event => {
    const { activeTab } = this.state;
    const target = event.target ? event.target.value : event.id;
    const href = event.target ? event.target.value : event.href;

    this.setState(state => ({
      content: [
        ...state.content.map((item, index) =>
          index === activeTab ? { ...item, image: target, href } : item,
        ),
      ],
      submited: false,
    }));
  };

  changeLocalizationCheck = name => event => {
    const { activeTab } = this.state;
    this.setState(state => ({
      content: [
        ...state.content.map((item, index) =>
          index === activeTab
            ? { ...item, [name]: event.target.checked }
            : item,
        ),
      ],
      submited: false,
    }));
  };

  handleCheck = name => ({ target }) => this.setState({ [name]: target.checked, submited: false });

  handleChange = name => ({ target }) => this.setState({ [name]: target.value, submited: false });

  changeTab = (event, value) => this.setState({ activeTab: value });

  // открытие модального окна
  openModal = () => this.setState({ open: true });
  // закрытие (отмена) модального окна по подтверждению удалиения локализации
  handleCancel = () => this.setState({ open: false });
  // DELETE, /api/v1/advertising/{id}/localizations/{localizationId}
  // Удалить существующую локализацию страницы
  deleteLocalizationAds = async (id, localizationId) => {
    const { content } = this.state;
    if(content.filter(l => !!l.idLocal).length === 1){
      store.dispatch({
        type: REQUEST_MESSAGE,
        data: {
          message: 'Хоча б одна мова має бути заповнена!',
          type: 'error',
        }
      });
      return false;
    }
    const deleteLocalization = await fetchOne({
      url: `${CORE_URL}/advertising/${id}/localizations/${localizationId}`,
      method: 'DELETE',
    });

    if (deleteLocalization && deleteLocalization.ok) {
      this.getLanguages();
      this.handleCancel();
    } else {
      console.log(deleteLocalization.statusText);
      this.handleCancel();
    }
  };

  render() {
    const { classes } = this.props;
    const {
      languages, loading, type, priority, open,
      activeTab, errors, is_active, submited,
      content
    } = this.state;
    const error = errors[activeTab];

    if (loading)
      return (
        <div style={{ padding: 20 }}>
          <h3>Загрузка...</h3>
        </div>
      );

    const dis = content[activeTab] && content[activeTab].idLocal === '';

    return (
      <div className="category-create_wrapper">

        {/* Всплывающее диалоговое окно по удалению локализации */}
        <ModalWindow
          open={open}
          title="Дійсно бажаєте видалити локалізацію?"
          onClose={this.handleCancel}
          onConfirm={() => this.deleteLocalizationAds(this.props.id, this.state.content[activeTab].idLocal)}
        />

        <div className="submit-button_wrapper">

          {/* кнопка удалить локализацию */}
          {this.props.type === "EDIT_ITEM" &&
          <Tooltip title="Видалити локализацію"
                   placement="left"
                   onClick={this.openModal}>
            <Button disabled={dis}
                    style={{ marginRight: '10px' }} variant="contained"
                    color="secondary">
              <Delete style={{ color: '#fff' }}/>
            </Button>
          </Tooltip>
          }
          {/* конец --- кнопка удалить локализацию */}

          <Button onClick={this.submitPage} variant="contained" color="primary">
            <Save style={{ color: '#fff' }} />
          </Button>
        </div>
        <div className="form-wrapper">
          <Paper style={{ padding: 10 }}>
            <Typography variant="subheading" align="left">
              Властивості реклами:
            </Typography>
          </Paper>
          <form className={classes.container} noValidate autoComplete="off">
            <TextField
              id="standard-priority"
              label="Priority"
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
              error={submited && !priority}
              className={classes.textField}
              value={priority}
              onChange={this.handleChange('priority')}
              margin="normal"
            />

            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="standard-type" error={!type}>
                Тип
              </InputLabel>
              <Select
                value={type}
                onChange={this.handleChange('type')}
                inputProps={{
                  name: 'type',
                  id: 'standard-type',
                }}
                style={{
                  textAlign: 'left',
                  width: '100%',
                }}
              >
                <MenuItem value={1}>Посилання</MenuItem>
                <MenuItem value={2}>Iframe</MenuItem>
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Switch
                  checked={is_active}
                  onChange={this.handleCheck('is_active')}
                  color="primary"
                />
              }
              label={is_active ? 'Активна' : 'Неактивна'}
            />
          </form>
        </div>

        <div className="form-wrapper form-big">
          <AppBar position="static" color="default">
            <Tabs
              value={activeTab}
              onChange={this.changeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              {languages.map(language => (
                <Tab
                  key={`language-tab-${language.id}`}
                  label={language.title}
                />
              ))}
            </Tabs>
          </AppBar>
          {this.state.content
            .filter((el, index) => index === activeTab)
            .map((item, index) => (
              <form
                key={`form-item-${index}`}
                className={classes.container}
                noValidate
                autoComplete="off"
              >
                {type === 1 && (
                  <TextField
                    id="standard-signature"
                    label="Підпис"
                    className={classes.textField}
                    value={item.signature}
                    error={error && error.some(el => el === 'signature')}
                    onChange={this.changeLocalization('signature')}
                    margin="normal"
                  />
                )}

                {type === 1 && (
                  <TextField
                    id="standard-link"
                    label="Посилання"
                    className={classes.textField}
                    value={item.link}
                    error={error && error.some(el => el === 'link')}
                    onChange={this.changeLocalization('link')}
                    margin="normal"
                  />
                )}

                {type === 2 && (
                  <TextField
                    id="standard-iframe"
                    label="Iframe"
                    className={classes.textField}
                    value={item.iframe}
                    error={error && error.some(el => el === 'iframe')}
                    onChange={this.changeLocalization('iframe')}
                    margin="normal"
                  />
                )}

                {type === 1 && <FormControl className={classes.formControl}>
                  <Typography
                    style={{
                      color: (!item.image && submited) ? '#f44336' : '#000',
                      width: '100%',
                    }}
                    variant="subheading"
                    align="left"
                  >
                    Зображення
                  </Typography>
                  {item.image && (
                    <img
                      src={
                        item.image && (item.image.resize || item.image.href)
                      }
                      style={{ marginBottom: 5 }}
                      alt=""
                    />
                  )}
                  {item.href && (
                    <img
                      src={item.href && (item.href || item.href)}
                      style={{ marginBottom: 5 }}
                      alt=""
                    />
                  )}
                  <DropZone handler={this.changeLocalizationImage} removeFile />
                </FormControl>}
              </form>
            ))}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CreateAds);
