import React from 'react';

import '../../assets/styles/nav_components/main.scss';

import { EDIT_ITEM } from '../../constants/types';
import { PER_PAGE, PER_PAGE_ARR } from "../../constants/data";
import { GET_ALL_NEWS } from "../../constants/urls";

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Button from "@material-ui/core/Button/Button";

import { Delete, Edit, Done, Cancel } from "@material-ui/icons";

import Paper from '@material-ui/core/Paper';
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";

import EnhancedTableHead from '../util/EnhancedTableHead';

import { formatPublishDate } from '../../services/main';
import { tableHeadSort } from '../../services/sort';

const desc = tableHeadSort('news');


function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'createdAt', numeric: false, disablePadding: true, label: 'Дата створення', sort: true },
  // { id: 'is_active', numeric: false, disablePadding: true, label: 'Статус', sort: true },
  { id: 'is_active', numeric: false, disablePadding: true, label: 'Статус', sort: false },
  { id: 'title', numeric: false, disablePadding: true, label: 'Заголовок', sort: false },
  { id: 'image', numeric: false, disablePadding: true, label: 'Зображення', sort: false },
  { id: 'alias', numeric: false, disablePadding: true, label: 'Alias', sort: false },
  // { id: 'tags', numeric: false, disablePadding: false, label: 'Теги', sort: true },
  { id: 'tags', numeric: false, disablePadding: false, label: 'Теги', sort: false },
  { id: 'languages', numeric: false, disablePadding: false, label: 'Мови', sort: false },
  { id: 'publishedAt', numeric: false, disablePadding: false, label: 'Дата і час від', sort: true },
  { id: 'published_end_at', numeric: false, disablePadding: false, label: 'Дата і час до', sort: true },
];

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  fullBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  ulWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    listStyleType: 'none',
    padding: '3px 0',
  },
  liItem: {
    border: '1px solid',
    marginBottom: 3,
    borderRadius: 3,
    marginRight: 3,
    padding: 3,
  },
});

class EnhancedTable extends React.Component {
  state = {
    // order: 'desc',
    // orderBy: 'createdAt',
    // selected: [],

    page: 0,
    rowsPerPage: PER_PAGE,

    // onlyActive: false,
    // data: this.props.data,
  };

  // static getDerivedStateFromProps(props, state) {
  //   if (props.data !== state.data) {
  //     return {
  //       selected: [],
  //       data: props.data,
  //     };
  //   }
  //   return null;
  // }
  //
  // goToCreate = type => this.props.onCreate(type);

  goToEdit = (id, type) => this.props.onEdit(id, type);

  deleteItem = id => this.props.openModal(id);

  // handleRequestSort = (event, property) => {
  //   const orderBy = property;
  //   let order = 'desc';
  //
  //   if (this.state.orderBy === property && this.state.order === 'desc') {
  //     order = 'asc';
  //   }
  //
  //   this.setState({ order, orderBy });
  // };

  handleChangePage = (event, page) => {
    this.setState({ page });
    this.props.setPage(page + 1);
  };


  handleChangeRowsPerPage = event => this.setState(
    { rowsPerPage: event.target.value },
    this.props.setLimit(event.target.value)
  );

  // toggleOnlyActive = () => this.setState(state => ({ onlyActive: !state.onlyActive }));

  render() {
    const { classes, pagination, data, order, sortBy, onlyActive } = this.props;
    const { rowsPerPage, page } = this.state;
    // const activeData = onlyActive ? data.filter(item => item.is_active) : data;

    return (
      <div className="tab-block-content no-shadow">
        <div className={classes.fullBetween}>
          <FormControlLabel
            control={
              <Switch
                checked={onlyActive}
                onChange={this.props.toggleOnlyActive}
                color="primary"
              />
            }
            label={onlyActive ? 'Тільки активні' : 'Всі'}
          />
        </div>
        {!!data.length && (
          <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
              >
                <EnhancedTableHead
                  rows={rows}
                  order={order}
                  orderBy={sortBy}
                  onRequestSort={this.props.handleRequestSort}
                />
                <TableBody>
                  {/*{stableSort(activeData, getSorting(order, orderBy))*/}
                  {data
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(n => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          key={n.id}
                        >
                          <TableCell>
                            {formatPublishDate(n.created_at)}
                          </TableCell>
                          <TableCell>
                            {n.is_active ? (<Done />) : (<Cancel />)}
                          </TableCell>
                          <TableCell>
                            {!!n.news_localizations.length
                              && n.news_localizations[0].title}
                          </TableCell>
                          <TableCell>
                            {n.photo && (
                              <img
                                src={n.photo.resize || n.photo.href}
                                style={{ height: 80 }}
                                alt=""
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            <a href={`${GET_ALL_NEWS}/${n.alias}`}>{n.alias}</a>
                          </TableCell>
                          <TableCell>
                            <ul className={classes.ulWrapper}>
                              {!!n.news_localizations.length
                                && n.news_localizations[0].tags.map(item => (
                                  <li
                                    key={`menu-children-item-${n.news_localizations[0].id}-${item.id}`}
                                    className={classes.liItem}
                                  >
                                    {item.title}
                                  </li>
                                ))}
                            </ul>
                          </TableCell>
                          <TableCell>{n.news_localizations.length}</TableCell>
                          <TableCell>{n.published_at ? formatPublishDate(n.published_at) : ''}</TableCell>
                          <TableCell>{n.published_end_at ? formatPublishDate(n.published_end_at) : ''}</TableCell>
                          <TableCell padding="checkbox">
                            <Button onClick={() => this.deleteItem(n.id)} color="primary">
                              <Delete style={{ color: '#afafaf' }} />
                            </Button>
                          </TableCell>

                          <TableCell padding="checkbox">
                            <Button onClick={() => this.goToEdit(n.id, EDIT_ITEM)} color="primary">
                              <Edit style={{ color: '#afafaf' }} />
                            </Button>
                          </TableCell>

                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={PER_PAGE_ARR}
              component="div"
              count={pagination.totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Попередня сторінка',
              }}
              nextIconButtonProps={{
                'aria-label': 'Наступна сторінка',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              labelRowsPerPage='Рядків на сторінку:'
              labelDisplayedRows= {
                ({ from, to, count }) => `${pagination.firstItemNumber}-${pagination.lastItemNumber} з ${count}`
              }
            />
          </Paper>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(EnhancedTable);