import {
  ALL_ACCOUNTS_DOWNLOADED,
  ADD_ACCOUNT_FAIL,
  ADD_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAIL
} from '../constants/redux';
import {GET_ALL_ACCOUNTS} from '../constants/urls';
import { getErrors } from './errors';
import fetchOne from "../services/fetch";

export function customersDownloaded(customers) {
  return { type: ALL_ACCOUNTS_DOWNLOADED, customers };
}

export const customerAddFail = () => ({
  type: ADD_ACCOUNT_FAIL
});

export const customerAddSuccess = () => ({
  type: ADD_ACCOUNT_SUCCESS
});

export const customerUpdateFail = () => ({
  type: UPDATE_ACCOUNT_FAIL
});

export const customerUpdateSuccess = () => ({
  type: UPDATE_ACCOUNT_SUCCESS
});

const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
};

export function fetchCustomers() {
  return async function (dispatch) {
    try {
      const customers = await fetchOne({
        url: GET_ALL_ACCOUNTS,
      });
      dispatch(customersDownloaded(customers));
    } catch (err) {
      console.log(err.message);
      dispatch(getErrors('Some error: ' + err.message));
    }
  };
}

export function addCustomer(data) {
  return async function (dispatch) {
    try {
      const customer = await fetchOne({
        url: GET_ALL_ACCOUNTS,
        method: 'POST',
        body: JSON.stringify(data),
        headers: headers
      });
      dispatch(customerAddSuccess());
      dispatch(fetchCustomers());
      console.log(customer);
    } catch (err) {
      console.log(err.message);
      dispatch(getErrors('Some error: ' + err.message));
      dispatch(customerAddFail());
    }
  };
}

export function updateCustomer(data, id) {
  return async function (dispatch) {
    try {
      const customer = await fetchOne({
        url: `${GET_ALL_ACCOUNTS}/${id}`,
        method: 'PUT',
        body: JSON.stringify(data),
        headers: headers
      });
      dispatch(customerUpdateSuccess());
      dispatch(fetchCustomers());
      console.log(customer);
    } catch (err) {
      console.log(err.message);
      dispatch(getErrors('Some error: ' + err.message));
      dispatch(customerUpdateFail());
    }
  };
}