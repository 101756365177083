import React from 'react';
import { connect } from "react-redux";

import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';

import { closeMessage } from "../../actions/information";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function CustomSnackBar(props) {
  const {
    classes, className,
    message, onClose, variant, ...other
  } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

const SnackBarWrapper = withStyles(styles)(CustomSnackBar);

class StateWrapper extends React.PureComponent {
  handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    this.props.closeMessage();
  };

  render() {
    const { information, children } = this.props;
    return (
      <React.Fragment>
        {children}
        {information.type && (
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={information.open}
            autoHideDuration={5000}
            onClose={this.handleClose}
          >
            <SnackBarWrapper
              onClose={this.handleClose}
              variant={information.type}
              message={information.message}
            />
          </Snackbar>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ information }) => ({ information });

const mapDispatchToProps = dispatch => ({
  closeMessage: () => dispatch(closeMessage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StateWrapper);
