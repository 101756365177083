import store from "../store";
import { REQUEST_MESSAGE } from "../constants/redux";

const pushStatus = async (method, response) => {
  if (method !== 'GET') {
    if (response.status >= 400) {
      const { error } = await response.json();
      store.dispatch({
        type: REQUEST_MESSAGE,
        data: {
          message: error,
          type: 'error',
        }
      });
    } else if ([200, 201, 204].indexOf(response.status) > -1) {
      const messages = {
        'DELETE': 'Видалено',
        'POST': 'Успішно',
        'PUT': 'Відредаговано',
      };

      store.dispatch({
        type: REQUEST_MESSAGE,
        data: {
          message: messages[method],
          type: 'success',
        }
      });
    }
  }
};

export default pushStatus;
