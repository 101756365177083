import React, { Component } from 'react';
import '../../assets/styles/nav_components/main.scss';
import '../../assets/styles/components/departments.scss';
import PartnersTableHead from "../PartnersTable";

class AllPartners extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="tab-block-content no-shadow">

          <PartnersTableHead toEdit={this.props.toEdit} />
        </div>
      </React.Fragment>
    );
  }
}

export default AllPartners;
