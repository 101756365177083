import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { withStyles } from '@material-ui/core/styles';

import '../../assets/styles/nav_components/main.scss';

import { EDIT_ITEM } from '../../constants/types';
import { PER_PAGE, PER_PAGE_ARR } from "../../constants/data";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Button from "@material-ui/core/Button/Button";
import Paper from '@material-ui/core/Paper';

import { Edit } from "@material-ui/icons";

import EnhancedTableHead from '../util/EnhancedTableHead';

import { tableHeadSort } from '../../services/sort';

const desc = tableHeadSort('');

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] > b[1] ? 1 : -1;
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
// function getLocaleSorting(translations) {
//     let arr = [];
//     const ukr = translations.find(localization => localization.localization.locale === 'uk');
//     const eng = translations.find(localization => localization.localization.locale === 'en');
//     const ru = translations.find(localization => localization.localization.locale === 'ru');
//
//     if (ukr) arr.push(ukr);
//     if (eng) arr.push(eng);
//     if (ru) arr.push(ru);
//
//     return arr;
// }

const rows = [
    { id: 'system_name', numeric: false, disablePadding: true, label: 'Системна назва', sort: false },
    { id: 'translation', numeric: false, disablePadding: true, label: 'Переклади', sort: false },
];

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    fullBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    ulWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        listStyleType: 'none',
        padding: '3px 0',
    },
    liItem: {
        border: '1px solid',
        marginBottom: 3,
        borderRadius: 3,
        marginRight: 3,
        padding: 3,
    },
});

class AllSystemTranslations extends React.Component {
    state = {
        order: 'asc',
        orderBy: 'system_name',
        selected: [],

        page: 0,
        rowsPerPage: PER_PAGE,
        onlyActive: false,

        data: this.props.data,
    };

    static getDerivedStateFromProps(props, state) {
        if (props.data !== state.data) {
            return {
                selected: [],
                data: props.data,
            };
        }
        return null;
    }

    goToCreate = type => this.props.onCreate(type);

    goToEdit = (id, type) => this.props.onEdit(id, type);

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => this.setState({ page });

    handleChangeRowsPerPage = event => this.setState({ rowsPerPage: event.target.value });

    render() {
        const { classes } = this.props;
        const { data, order, orderBy, rowsPerPage, page, onlyActive } = this.state;
        const activeData = onlyActive ? data.filter(item => item.is_active) : data;

        return (
            <div className="tab-block-content no-shadow">
                    <Paper className={classes.root}>
                        <div className={classes.tableWrapper}>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                            >
                                <EnhancedTableHead
                                    rows={rows}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={this.handleRequestSort}
                                />
                                <TableBody>
                                    {stableSort(activeData, getSorting(order, orderBy))
                                        .sort((a, b) => a.system_name > b.system_name ? 1 : -1)
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map(n => (
                                            <TableRow key={n.id} hover role="checkbox">
                                                <TableCell>
                                                    {n.system_name}
                                                </TableCell>
                                                <TableCell>
                                                    <ul className={classes.ulWrapper}>
                                                            <li
                                                              key={`menu-children-item-${n.id}`}
                                                              className={classes.liItem}
                                                            >
                                                                {`${n.translations[0].localization.locale}:`}
                                                                {ReactHtmlParser(n.translations[0].translation)}
                                                            </li>

                                                    </ul>
                                                  {/*<ul className={classes.ulWrapper}>*/}
                                                  {/*  {getLocaleSorting(n.translations)*/}
                                                  {/*      .map(item => (*/}
                                                  {/*    <li*/}
                                                  {/*      key={`menu-children-item-${n.id}-${item.localization.id}`}*/}
                                                  {/*      className={classes.liItem}*/}
                                                  {/*    >*/}
                                                  {/*      {`${item.localization.locale}:`}*/}
                                                  {/*      {ReactHtmlParser(item.translation)}*/}
                                                  {/*    </li>*/}
                                                  {/*  ))}*/}
                                                  {/*</ul>*/}
                                                </TableCell>
                                                <TableCell padding="checkbox" />
                                                <TableCell padding="checkbox">
                                                    <Button onClick={() => this.goToEdit(n.id, EDIT_ITEM)} color="primary">
                                                        <Edit style={{ color: '#afafaf' }} />
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={PER_PAGE_ARR}
                            component="div"
                            count={activeData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'Попередня сторінка',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Наступна сторінка',
                            }}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            labelRowsPerPage='Рядків на сторінку:'
                            labelDisplayedRows= {({ from, to, count }) => `${from}-${to} з ${count}`} />
                    </Paper>
            </div>
        );
    }
}

export default withStyles(styles)(AllSystemTranslations);
