import React, { Component } from 'react';
import '../../assets/styles/nav_components/main.scss';
import EditionsTableHead from "../EditionsTable";

class AllEditions extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="tab-block-content no-shadow">

          <EditionsTableHead toEdit={this.props.toEdit} />
        </div>
      </React.Fragment>
    );
  }
}

export default AllEditions;
