import React, { Component } from 'react';
import '../../assets/styles/nav_components/users.scss';
import EditTag from "../SubNavComponents/EditTag";
import AllTags from "../SubNavComponents/AllTags";
import CreateTag from "../SubNavComponents/CreateTag";


class Employees extends Component {
  constructor(props) {
    super(props);

    this.state = {
      curTabId: 0,
      editMode: false,
      editObject: {},
    };
  }

  toggleCurNav(curTabId) {
    this.setState({ curTabId, editMode: false });
  }

  toEdit = (obj) => {
    console.log(obj);
    this.setState({curTabId: 1, editMode: true, editObject: obj[0]});
  };

  render() {
    const { curTabId, editObject } = this.state;

    const params = [
      {component: <AllTags toEdit={this.toEdit}/>, name: "Всі теги"},
      {component: this.state.editMode ? <EditTag object={editObject}/> : <CreateTag />,
        name: this.state.editMode ? "Редагувати тег" : "Створити тег"}
    ];

    return (
      <React.Fragment>
        <div className="user-content">
          <div className="user-tabs-wrapper">
            <div className="tab-line">
              {
                params.map((item, index) => (
                  <div
                    className={curTabId === index ? 'tab-item tab-active' : 'tab-item'}
                    key={`nav-item-${item.name}`}
                    onClick={() => this.toggleCurNav(index)}
                  >
                    <div className="tab-item-block">
                      {item.name}
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

          {params[curTabId].component}

        </div>
      </React.Fragment>
    );
  }
}

export default Employees;
