import React, {Component} from 'react';
import '../../assets/styles/nav_components/main.scss';
import '../../assets/styles/date_picker_block.scss';
import '../../assets/styles/show.scss';
import TextField from "@material-ui/core/TextField";
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import MenuItem from '@material-ui/core/MenuItem';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import {toISODate} from '../../services/main';
import fetchOne from "../../services/fetch";
import {GET_LANGUAGES, GET_ALL_EDITIONS, GET_BOOK_SERIES} from "../../constants/urls";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import BookLocalization from "../Localization/BookLocalization";
import {connect} from "react-redux";
import {getErrors} from "../../actions/errors";
import {validatePrice, allFalse} from "../../actions/common";
import {ranges} from "../../constants/main";
import {Save} from "@material-ui/icons";
import { TabContainer } from "../util/TabContainer"
import Typography from "@material-ui/core/Typography";
import uk from 'date-fns/locale/uk';
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import DropZone from "../util/DropZone";
import store from "../../store";
import {REQUEST_MESSAGE} from "../../constants/redux";

const styles = ({
  datePickerWrapper: {
    marginTop: 20,
    padding: '0 5px',
    width: '100%',
  },
  formControl: {
    width: '95%',
    zIndex: 1,
  },
});

const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
};

class CreateEdition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emptyTag: false,
      newTag: '',
      is_active: true,
      start: null,
      end: null,
      type: '',
      time: null,
      is_sale: false,
      languages: [],
      isLoading: true,
      error: null,
      selectedLanguage: 0,
      localizationsData: {},
      localizationId: '',
      price: '',
      alias: '',
      currency: '',
      localizationsTagData: {},
      file: null,
      photo: '',
      series: '',
      seriesData: [],
      errors: {},
      fields: {type: true, photo: true, alias: true, title: true}
    };
    this.handleAddTag = this.handleAddTag.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onStartTypeTag = this.onStartTypeTag.bind(this);
    this.blurHandler = this.blurHandler.bind(this);
    this.handleRemoveTag = this.handleRemoveTag.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  async componentDidMount() {
    try {
      const languages = await fetchOne({
        url: GET_LANGUAGES,
      });

      let seriesData = [];
      const resp = await fetchOne({url: GET_BOOK_SERIES});
      resp.forEach(function (item, i, arr) {
        seriesData.push({
          value: item.id,
          label: (item.book_series_localizations && item.book_series_localizations.length) ? item.book_series_localizations[0].title : ''
        })
      });

      this.setState({languages, isLoading: false, seriesData});
    } catch (err) {
      this.setState({error: err, isLoading: false});
      console.log(err.message);
    }
  }

  onStartTypeTag(event) {
    if (event.target.value.trim() !== "") {
      this.setState({emptyTag: false, newTag: event.target.value});
    } else {
      this.setState({emptyTag: true});
    }
  }

  blurHandler() {
    this.setState({emptyTag: false});
  }

  handleAddTag(id, tagArr, str) {
    this.setState({
      localizationsTagData: {
        ...this.state.localizationsTagData, [id]: this.state.localizationsTagData[id]
          ? [...this.state.localizationsTagData[id], {
            title: str,
            id: tagArr.filter((ch) => ch.title === str)[0].id
          }]
          : [{title: str, id: tagArr.filter((ch) => ch.title === str)[0].id}]
      }, newTag: ''
    });
  }

  async handleRemoveTag(localizationId, localizationsData, index) {
    this.setState({
      localizationsTagData: {
        ...this.state.localizationsTagData,
        [localizationId]: [...this.state.localizationsTagData[localizationId].slice(0, index), ...this.state.localizationsTagData[localizationId].slice(index + 1)]
      }
    });
  }

  toggleActive = () => this.setState(state => ({is_active: !state.is_active}));

  changeImage = ({ id }) => {
    this.setState(prevState => ({
      fields: {...prevState.fields, photo: false},
      errors: {...prevState.errors, photo: false},
      photo: id
    }));
  };

  handleSale = name => event => {
    this.setState({[name]: event.target.checked});
  };

  handleChangeLanguage = (event, value) => {
    this.setState({selectedLanguage: value});
  };

  changeLocalizationData = (event, id) => {
    const { target: { name, value } } = event;
    if(undefined !== this.state.fields[name]) {
      this.setState((prevState) => ({
        fields: {...prevState.fields, [name]: (!value || value.length === 0)},
        errors: {...prevState.errors, [name]: (!value || value.length === 0)},
        localizationsData: {...prevState.localizationsData, [id]: {...prevState.localizationsData[id], [name]: value}}
      }));
    } else {
      this.setState((prevState) => ({
        localizationsData: {...prevState.localizationsData, [id]: {...prevState.localizationsData[id], [name]: value}}
      }));
    }
  };

  setDescription = (value, id) => {
    this.setState({
      localizationsData: {
        ...this.state.localizationsData,
        [id]: {...this.state.localizationsData[id], description: value}
      }
    });
  };


  onChange(e) {
    const { target: { name, value } } = e;
    if((name === 'price' && validatePrice(value)) || name !== 'price') {
      if(undefined !== this.state.fields[name]){
        this.setState(prevState => ({
          fields: {...prevState.fields, [name]: (!value || value.length === 0)},
          errors: {...prevState.errors, [name]: (!value || value.length === 0)},
          [name]: value
        }));
      } else {
        this.setState({ [name]: value });
      }
    }
  }

  handleDateChange = name => date => this.setState({ [name]: date });

  async handleSubmit(event) {
    event.preventDefault();
    const {
      start,
      end,
      is_sale,
      localizationsData,
      price,
      alias,
      currency,
      type,
      is_active,
      localizationsTagData,
      series,
      photo,
      fields
    } = this.state;

    if(!photo){
      store.dispatch({
        type: REQUEST_MESSAGE,
        data: {
          message: 'Завантажте фото!',
          type: 'error',
        }
      });
    }

    if(!Object.entries(localizationsData).length){
      store.dispatch({
        type: REQUEST_MESSAGE,
        data: {
          message: 'Хоча б одна мова має бути заповнена!',
          type: 'error',
        }
      });
    }

    if (allFalse(fields)) {
      const formData = {
        is_active: is_active,
        type: type,
        published_at: start ? toISODate(start) : null,
        published_end_at: end ? toISODate(end) : null,
        is_sale: is_sale,
        photo: photo ? photo : null,
        price: price ? price : 0,
        alias: alias,
        currency: currency,
        series: series ? series : null,
      };

      try {
        let bookLoc = '';

        // create book
        let book = await fetchOne({
          url: `${GET_ALL_EDITIONS}`,
          method: "POST",
          body: JSON.stringify(formData),
          headers: headers
        });

        book = await book.json();

        // create localizations
        for (let [key, value] of Object.entries(localizationsData)) {
          let bookLocalizationData = {
            localization: key,
            page_amount: value.page_amount ? parseInt(value.page_amount) : null,
            language: value.language ? value.language : '',
            isbn: value.isbn ? value.isbn : '',
            year: value.year ? parseInt(value.year) : null,
            cover: value.cover ? value.cover.join(',') : '',
            title: value.title ? value.title : '',
            description: value.description ? value.description : '',
            additional_information: value.additional_information ? value.additional_information : ''
          };

          bookLoc = await fetchOne({
            url: `${GET_ALL_EDITIONS}/${book.id}/localizations`,
            method: "POST",
            body: JSON.stringify(bookLocalizationData),
            headers: headers
          });

          bookLoc = await bookLoc.json();
          // create tags for localizations
          if (localizationsTagData[key]) {
            let bookLocTag = '';
            for (let i = 0; i < localizationsTagData[key].length; i++) {
              let bookLocalizationTagData = {id: localizationsTagData[key][i].id};
              bookLocTag = await fetchOne({
                url: `${GET_ALL_EDITIONS}/${book.id}/localizations/${bookLoc.id}/tags`,
                method: "POST",
                body: JSON.stringify(bookLocalizationTagData),
                headers: headers
              });
            }
          }
        }
      } catch (err) {
        this.props.isErrors(err);
        console.log(err.message);
      }
    } else {
      this.setState({errors: fields});
    }
  }

  render() {
    const { classes } = this.props;
    const {
      start,
      end,
      is_sale,
      languages,
      selectedLanguage,
      localizationsData,
      price,
      alias,
      currency,
      localizationsTagData,
      newTag,
      seriesData,
      series,
      type,
      errors
    } = this.state;

    return (
      <React.Fragment>
        <div className="tab-block-content">

          <div className="submit-button_block">
            <Button
              onClick={(e) => this.handleSubmit(e)}
              variant="contained"
              color="primary"
            >
              <Save style={{ color: '#fff' }} />
            </Button>
          </div>

          <div className="show-block">
            <div className="show-block-form">

              <Paper style={{ padding: '11px 10px' }}>
                <Typography variant="subheading" align="left">
                  Властивості книги:
                </Typography>
              </Paper>

              <FormControl className={classes.formControl}>
                <Typography variant="subheading" align="left">
                  Превʼю
                </Typography>
                <DropZone handler={this.changeImage} />
              </FormControl>

              <TextField
                  autoFocus
                  name="alias"
                  margin="dense"
                  id="alias"
                  label="Alias"
                  type="input"
                  onChange={this.onChange}
                  value={alias}
                  error={errors.alias}
                  fullWidth
              />

              <TextField
                select
                className=""
                label="Тип"
                name="type"
                id="type"
                value={type}
                onChange={this.onChange}
                fullWidth
                error={errors.type}
              >
                {ranges.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <div className={classes.datePickerWrapper}>
                <Typography variant="subheading" align="left">
                  Дата і час від:
                </Typography>
                <DatePicker
                    selected={start ? new Date(start) : null}
                    showDisabledMonthNavigation
                    onChange={this.handleDateChange('start')}
                    dateFormat="d MMMM, yyyy HH:mm"
                    timeFormat="HH:mm"
                    timeInputLabel="Час:"
                    showTimeInput
                    isClearable
                    locale={uk}
                />
              </div>

              <div className={classes.datePickerWrapper}>
                <Typography variant="subheading" align="left">
                  Дата і час до:
                </Typography>
                <DatePicker
                    selected={end ? new Date(end) : null}
                    minDate={start ? (new Date(start)) : ''}
                    showDisabledMonthNavigation
                    onChange={this.handleDateChange('end')}
                    dateFormat="d MMMM, yyyy HH:mm"
                    timeFormat="HH:mm"
                    timeInputLabel="Час:"
                    showTimeInput
                    isClearable
                    locale={uk}
                />
              </div>


              <div className="checkbox-is-sale-block">
                <Switch
                  disabled={(!price || !currency)}
                  checked={is_sale && price && currency}
                  onChange={this.handleSale('is_sale')}
                  value="is_sale"
                  color="primary"
                />
                <span>Продаж</span>
              </div>


              <TextField
                autoFocus
                name="price"
                margin="dense"
                id="price"
                label="Ціна"
                type="input"
                onChange={this.onChange}
                value={price}
                error={false}
                fullWidth
              />

              <TextField
                autoFocus
                name="currency"
                margin="dense"
                id="currency"
                label="Назва валюти"
                type="input"
                onChange={this.onChange}
                value={currency}
                error={false}
                fullWidth
              />

              <TextField
                select
                label="Серія"
                name="series"
                id="series"
                value={series}
                onChange={this.onChange}
                fullWidth
              >
                <MenuItem value={null}>
                  Оберіть серію
                </MenuItem>
                {seriesData.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <div className="bottom-submit-form">
                <div>
                  <Switch
                    checked={this.state.is_active}
                    onChange={this.toggleActive}
                    color="primary"
                  />
                  <span>{this.state.is_active ? "Активний" : "Heактивний"}</span>
                </div>
              </div>
            </div>

            <div className="show-block-additions">
              {
                languages.length ?
                  <div className="">
                    <AppBar position="static" color="default">
                      <Tabs
                        value={selectedLanguage}
                        onChange={this.handleChangeLanguage}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                      >
                        {
                          languages.map((item, i) =>
                            <Tab label={item.title} key={`teb-lang-${i}`}/>
                          )
                        }
                      </Tabs>
                    </AppBar>
                    {<TabContainer>
                      <BookLocalization
                        localizationId={languages[selectedLanguage].id}
                        changeLocalizationData={this.changeLocalizationData}
                        setDescription={this.setDescription}
                        localizationsData={localizationsData}
                        onStartTypeTag={this.onStartTypeTag}
                        handleAddTag={this.handleAddTag}
                        localizationsTagData={localizationsTagData}
                        newTag={newTag}
                        errors={errors}
                        handleRemoveTag={this.handleRemoveTag}
                      />
                    </TabContainer>}
                  </div> : ''
              }

            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  isErrors: (err) => {
    dispatch(getErrors(err));
  },
});

export default withStyles(styles)(connect(null, mapDispatchToProps)(CreateEdition));
