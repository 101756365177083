import React, { Component } from 'react';

import { CREATE_ITEM, EDIT_ITEM } from '../constants/types';
import { CORE_URL } from "../constants/urls";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import fetchOne from "../services/fetch";
import AllTelegramBots from '../components/telegramBots/AllTelegramBots';
import CreateTelegramBot from '../components/telegramBots/CreateTelegramBot';
import ModalWindow from './layout/ModalWindow';


const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
};


class TelegramBots extends Component {

  state = {
    type: CREATE_ITEM,
    open: false,
    section: 0,
    id: '',
    data: [],
    selected: [],
  };

  componentDidMount() {
    this.getData();
  }

  // начальная инициализация "Телеграм-боти", GET, /telegram-bots
  getData = async () => {
    const data = await fetchOne({ url: `${CORE_URL}/telegram-bots` });
    this.setState({
      type: CREATE_ITEM,
      selected: [],
      data,
      id: '',
    });
  };

  updateData = () => {
    this.setSection(0);
    this.getData();
  };

  setSection = section => this.setState({ type: CREATE_ITEM, section });

  setEdit = (id, type) => this.setState({ id, type, section: 1 });

  deleteItem = async () => {
    const { id } = this.state;
    try {
      const deleted = await fetchOne({
        url: `${CORE_URL}/telegram-bots/${id}`,
        method: 'DELETE',
        headers,
      });

      this.setState({
        type: CREATE_ITEM,
        open: false,
        selected: [],
        id: '',
      });
      if (deleted.status === 204) this.getData();
    } catch (err) {
      this.setState({
        type: CREATE_ITEM,
        open: false,
        selected: [],
        id: '',
      });
    }
  };

  deleteMultiple = async () => {
    const { selected } = this.state;

    try {
      const deleteMultiple = await Promise.all(selected
        .map(itemID => {
          return fetchOne({
            url: `${CORE_URL}/telegram-bots/${itemID}`,
            method: 'DELETE',
            headers,
          });
        }));

      if (deleteMultiple.every(deleted => deleted.status === 204)) {
        this.getData();
      }

      this.setState({
        type: CREATE_ITEM,
        open: false,
        selected: [],
        id: '',
      });
    } catch (err) {
      console.log(err);
    }
  };

  openModal = (id, arr) => {
    this.setState({
      selected: arr || [],
      id: id || '',
      open: true,
    });
  };

  handleCancel = () => this.setState({ open: false });


  render() {
    const { section, data, type, open, id } = this.state;

    // названия секций для подзголовка
    const params = [
      { name: "Всі телеграм-боти" },
      { name: "Створити телеграм-бота" }
    ];

    return (
      <div className="user-content">

        {/* подзаголовок с названиями секций, всі сторінкі, створити, редагувати.... */}
        <div className="user-tabs-wrapper">
          <div className="tab-line">

            {params.map((item, index) => (
              <div
                className={section === index ? 'tab-item tab-active' : 'tab-item'}
                key={`nav-item-${item.name}`}
                onClick={() => this.setSection(index)}
              >
                <div className="tab-item-block">
                  {(index && type === EDIT_ITEM) ? 'Редагування телеграм-бота' : item.name}
                </div>
              </div>
            ))}

          </div>
        </div>
        {/* Конец подзаголовка */}

        {/* Основная секция, либо список страниц, либо создание/редактирование */}
        {section === 0 && (
          <AllTelegramBots
            data={data || []}
            openModal={this.openModal}
            onEdit={this.setEdit}
          />
        )}

        {section === 1 && (
          <CreateTelegramBot
            id={id}
            type={type}
            update={this.updateData}
          />
        )}
        {/* Конец - основной секции */}

        <ModalWindow
          open={open}
          title="Видалити телеграм бота?"
          onClose={this.handleCancel}
          onConfirm={() => {
            if (id) {
              this.deleteItem();
            } else {
              this.deleteMultiple();
            }
          }}
        />
      </div>
    );
  }
}

export default TelegramBots;
