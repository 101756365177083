import { combineReducers } from 'redux';
import errors from './errors';
import accounts from './accounts';
import banners from './banners';
import editions from './editions';
import reviews from './book_reviews';
import orders from './book_orders';
import tags from './tags';
import employees from './employees';
import information from './information';
import systemPages from './system_pages';

export default combineReducers({
  errors,
  accounts,
  banners,
  editions,
  reviews,
  orders,
  tags,
  employees,
  information,
  systemPages,
});
