import {DEPARTMENTS_PATH, EMPLOYEES_PATH, PARTNER_TYPES_PATH} from "../constants/urls";
import fetchOne from "../services/fetch";


export function createLocalizationsContactData(arr) {
  const localizationContactData = {};

  for (let i = 0; i < arr.length; i++) {
    let locId = arr[i].localization.id;
    const locContactDataArr = [];

    if(arr[i].contact) {
      if(arr[i].contact.contact_entries.length) {
        for (let j = 0; j < arr[i].contact.contact_entries.length; j++) {
          let locContactData = {
            type: arr[i].contact.contact_entries[j].type,
            entry: arr[i].contact.contact_entries[j].value,
            entry_id: arr[i].contact.contact_entries[j].id,
            contactId: arr[i].contact.id
          };
          locContactDataArr.push(locContactData);
        }
      }
    }
    localizationContactData[locId] = locContactDataArr;
  }
  return localizationContactData;
}

export async function findDepartmentsList() {
  let data = [];
  try {
    const resp = await fetchOne({url: DEPARTMENTS_PATH});
      resp.forEach(function (item, i, arr) {
        data.push({
          value: item.id,
          label: item.department_localizations[0].title
        })
      })

  } catch (err) {
    console.log(err);
  }
  return data;
}

export async function findEmployeesList() {
  let data = [];
  try {
    const resp = await fetchOne({url: EMPLOYEES_PATH});
      resp.forEach(function (item, i, arr) {
        data.push({
          value: item.id,
          label: `${item.employee_localizations[0].name} ${item.employee_localizations[0].surname}`
        })
      })
  } catch (err) {
    console.log(err);
  }
  return data;
}

export async function findPartnerTypesList() {
  let data = [];
  try {
    const resp = await fetchOne({ url: `${PARTNER_TYPES_PATH}?limit=1000` });
    resp.forEach(function (item, i, arr) {
      data.push({
        value: item.id,
        label: item.partner_type_localizations[0].title
      })
    })
  } catch (err) {
    console.log(err);
  }
  return data;
}

export const funcParse = {
  func: function parse(arr) {
    try {
      return [].concat(...arr.map(item => (item.children.length
        ? [].concat(...[item, [].concat(...funcParse.func(item.children))]) : item)))
    } catch (e) {
      console.log(e);
      return [];
    }
  }
};

export function fillValue(localizationId, localizationsData, key) {
  let response = '';
  if(localizationsData[localizationId]) {
    response = localizationsData[localizationId][key] ? localizationsData[localizationId][key] : ''
  }
  return response;
}

export function allFalse(obj) {
  for (const i in obj) {
    if (obj[i] === true) return false;
  }
  return true;
}

export function validateEmail(email) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePrice(str) {
  // eslint-disable-next-line no-useless-escape
  const format = /[a-zA-Zа-яА-Я\\!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/;
  return !format.test(str);
}

export function titleToAlias(str) {
  let ru = {
    'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
    'е': 'e', 'ё': 'e', 'ж': 'j', 'з': 'z', 'и': 'i',
    'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
    'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
    'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh',
    'щ': 'shch', 'ы': 'y', 'э': 'e', 'ю': 'u', 'я': 'ya'
  }, n_str = [];

  str = str.replace(/[ъь]+/g, '').replace(/й/g, 'i');
  str = str.replace(/\s+/g, '-');

  while(str.charAt(0) === '/')
  {
    str = str.substr(1);
  }

  for ( var i = 0; i < str.length; ++i ) {
    n_str.push(
      ru[ str[i] ]
      // eslint-disable-next-line no-mixed-operators
      || ru[ str[i].toLowerCase() ] === undefined && str[i]
      || ru[ str[i].toLowerCase() ].replace(/^(.)/, function ( match ) { return match.toUpperCase() })
    );
  }
  return '/' + n_str.join('');
}
