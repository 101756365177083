import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import StateWrapper from '../../components/util/StateWrapper';

import Home from '../Home';
import Banners from "../Banners";
import Admins from "../../components/NavComponents/Admins";
import Editions from "../../components/NavComponents/Editions";
import Tags from "../../components/NavComponents/Tags";
import Employees from "../Employees";
import Advertising from "../Advertising";
import Contacts from "../Contacts";
import InformationBlocks from "../InformationBlocks";
import Menus from "../Menus";
import Pages from "../Pages";
import Languages from "../Languages";
import User from "../User";
import LogIn from "../LogIn";
import HeaderSettings from "../HeaderSettings";
import FooterSettings from "../FooterSettings";
import Partners from "../../components/NavComponents/Partners";
import Feedbacks from "../Feedbacks";
import TelegramBots from '../TelegramBots';
import Settings from "../Settings";
import SystemTranslations from "../SystemTranslations";
import News from "../News";
import SystemContacts from "../SystemContacts";
import Nodes from "../Nodes";

import NotFound from '../NotFound';

class Main extends React.PureComponent {
  render() {
    return (
      <main className="App-main">
        <StateWrapper>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={LogIn} />
            <Route exact path="/users" component={Admins} />
            <Route exact path="/banners" component={Banners} />
            <Route exact path="/editions" component={Editions} />
            <Route exact path="/tags" component={Tags} />
            <Route exact path="/news" component={News} />
            <Route exact path="/system-contacts" component={SystemContacts} />
            <Route exact path="/employees" component={Employees} />
            <Route exact path="/advertising" component={Advertising} />
            <Route exact path="/contacts" component={Contacts} />
            <Route exact path="/information-blocks" component={InformationBlocks} />
            <Route exact path="/menus" component={Menus} />
            <Route exact path="/pages" component={Pages} />
            <Route exact path="/languages" component={Languages} />
            <Route exact path="/user" component={User} />
            <Route exact path="/nodes" component={Nodes} />
            <Route exact path="/header" component={HeaderSettings} />
            <Route exact path="/footer" component={FooterSettings} />
            <Route exact path="/partners" component={Partners} />
            <Route exact path="/feedbacks" component={Feedbacks} />
            <Route exact path="/telegram-bots" component={TelegramBots} />
            <Route exact path="/settings" component={Settings} />
            <Route exact path="/system-translations" component={SystemTranslations} />
            <Route exact path="/404" component={NotFound} />
            <Redirect to="/404" />
          </Switch>
        </StateWrapper>
      </main>
    );
  }
}

export default Main;
