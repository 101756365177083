import React from "react";
import UploadAdapter from '../../services/CKUploadAdapter';

import Typography from "@material-ui/core/Typography/Typography";
import CKEditor from "@ckeditor/ckeditor5-react";

import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";

import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";

import Code from "@ckeditor/ckeditor5-basic-styles/src/code";
import Subscript from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import Superscript from "@ckeditor/ckeditor5-basic-styles/src/superscript";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";

import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import Undo from "@ckeditor/ckeditor5-undo/src/undo";
import Widget from "@ckeditor/ckeditor5-widget/src/widget";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";

import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";

import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";

const headingConfig = {
  options: [
    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
    { model: 'heading1', view: 'h2', title: 'Heading 1', class: 'ck-heading_heading1' },
    { model: 'heading2', view: 'h3', title: 'Heading 2', class: 'ck-heading_heading2' },
    { model: 'heading3', view: 'h4', title: 'Heading 3', class: 'ck-heading_heading3' }
  ]
};

const editorConfiguration = {
  heading: headingConfig,
  plugins: [
    Essentials, Bold, Italic, Paragraph,
    Alignment, Underline, Strikethrough, Code,
    Subscript, Superscript, BlockQuote, Heading,
    MediaEmbed, Undo, Widget, Link, List, Table, TableToolbar,
    Image, ImageToolbar, ImageCaption, ImageStyle, ImageUpload,
  ],
  alignment: {
    options: [
      'left', 'right', 'center', 'justify',
    ],
  },
  image: {
    toolbar: [
      'imageTextAlternative', '|',
      'imageStyle:full',
      'imageStyle:side',
      'imageStyle:alignLeft',
      'imageStyle:alignCenter',
      'imageStyle:alignRight',
    ],
    styles: [
      'full',
      'side',
      'alignLeft',
      'alignCenter',
      'alignRight',
    ],
  },
  table: {
    contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
  },
  toolbar: [
    'heading', '|',
    'bold', 'italic', 'underline', 'strikethrough', '|',
    'alignment', '|',
    'numberedList', 'bulletedList', 'insertTable', '|',
    'undo', 'redo', '|',
    'code', 'subscript', 'superscript', 'blockQuote', '|',
    'link', 'ImageUpload', 'MediaEmbed', '|',
  ],
};

const CKComponent = ({ setDescription, description, error }) => (
  <div className="editor">
    <Typography
      style={{
        textDecoration: error ? 'underline' : 'none',
        color: error ? '#f44336' : '#000',
        padding: '10px 18px',
      }}
      variant="subheading"
      align="left"
    >
      Опис:
    </Typography>
    <CKEditor
      editor={ClassicEditor}
      config={editorConfiguration}
      data={description}
      onInit={editor => {
        editor.plugins
          .get('FileRepository')
          .createUploadAdapter = function(loader) {
          return new UploadAdapter(loader);
        };
      }}
      onChange={(event, editor) => {
        const data = editor.getData();
        setDescription(data);
      }}
    />
  </div>
);

export default CKComponent;