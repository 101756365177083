import React, { Component } from 'react';
import '../../assets/styles/nav_components/main.scss';
import '../../assets/styles/autocomplete.scss';
import TextField from "@material-ui/core/TextField";
import {fillValue} from "../../actions/common";


class SeriesLocalization extends Component {

  render() {

    const { localizationId, localizationsData } = this.props;

    return (
      <div className="">

        <TextField
          name='title'
          margin="dense"
          id='title'
          label="Назва"
          type="input"
          onChange={(e) => this.props.changeLocalizationData(e, localizationId)}
          value={fillValue(localizationId, localizationsData, 'title')}
          error={false}
          fullWidth
        />

      </div>
    );
  }
}

export default SeriesLocalization;
