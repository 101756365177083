import React, { Component } from 'react';
import '../../assets/styles/nav_components/main.scss';
import '../../assets/styles/nav_components/users.scss';
import TextField from "@material-ui/core/TextField";
import {allFalse} from "../../actions/common";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import {colors} from "../../constants/main";
import {addTag} from "../../actions/tags";

class CreateTag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      page: 0,
      curId: '',
      curObj: {},
      title: '',
      color: '',
      fields: {title: true, color: true},
      errors: {},
      open: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  chooseColor(e, color) {
    e.preventDefault();
    this.setState(prevState => ({
      curObj: {
        ...prevState.curObj,
        color: color
      },color: color,
      fields: { ...this.state.fields, color: false }
    }));
    // при вводе в поле убираем ошибки из стэйта
    if (this.state.errors.color) {
      const { color, ...rest } = this.state.errors;
      this.setState({ errors: rest });
    }
  }

  onChange(e) {
    const { target: { name, value } } = e;
    if (value.trim() !== '') {
      this.setState(prevState => ({
        curObj: {...prevState.curObj, [name]: value},
        [name]: value,
        fields: { ...this.state.fields, [name]: false }
      }));
    } else {
      this.setState({ fields: { ...this.state.fields, [name]: true } });
    }
    // при вводе в поле убираем ошибки из стэйта
    if (this.state.errors.title) {
      const { title, ...rest } = this.state.errors;
      this.setState({ errors: rest });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    if (allFalse(this.state.fields)) {
      const tagData = {
        title: this.state.title,
        color: this.state.color,
      };
      this.props.addNewTag(tagData);
      this.setState({ open: false });
    } else {
      this.setState({ errors: this.state.fields });
      console.log(this.state.fields);
    }
  }

  render() {
    const { errors, color } = this.state;
    console.log(this.state);

    return (
      <React.Fragment>
        <div className="tab-block-content">

          <div className="show-block">
            <div className="show-block-form">
              <TextField
                autoFocus
                name="title"
                margin="dense"
                id="title"
                label="Назва"
                type="input"
                onChange={this.onChange}
                error={errors.title}
                fullWidth
              />

              <div className={errors.color ? "tag-color-block-error" : "tag-color-block"}>
                <p>Колір</p>

                {color ?
                  <div className="tag-colors-row">
                    <div className="tag-colors-item"
                         style={{backgroundColor: color}}>
                    </div>
                  </div>
                  : ''
                }
              </div>

              <div className="tag-colors-row">
                {
                  colors.map((item, i) =>
                    <div className="tag-colors-item"
                         key={`tag-colors-item-${i}`}
                         style={{backgroundColor: item.color}}
                         onClick={(e) => this.chooseColor(e, item.color)}
                    >
                    </div>
                  )
                }
              </div>

              <div className="account-submit-block">
                <Button variant="contained" size="small" className="" onClick={(e) => this.onSubmit(e)}>
                  Зберегти
                </Button>
              </div>

            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addNewTag: (data) => {
    dispatch(addTag(data));
  },
});

export default connect(null, mapDispatchToProps)(CreateTag);
