export const AUTH_BASIC = 'Basic YWM1NWYxNWEtOGM4NC00YmM1LWI2NzgtMDY5YjU2ZDM2NjNkOnRlc3RhcHA=';
export const PER_PAGE = 10;
export const PER_PAGE_ARR = [10, 20, 50];

export const BOOK_REVIEW_STATUS_NEW = 0;
export const BOOK_REVIEW_STATUS_IN_PROCESS = 1;
export const BOOK_REVIEW_STATUS_PUBLISHED = 2;
export const BOOK_REVIEW_STATUS_CANCELED = 3;

export const BOOK_ORDER_STATUS_NEW = 0;
export const BOOK_ORDER_STATUS_IN_PROCESS = 1;
export const BOOK_ORDER_STATUS_DONE = 2;
export const BOOK_ORDER_STATUS_CANCELED = 3;