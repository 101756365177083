import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import '../../assets/styles/categories.scss';

import fetchOne from '../../services/fetch';
import { CORE_URL, DEPARTMENTS_PATH } from '../../constants/urls';
import { EDIT_ITEM } from '../../constants/types';

import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { Save } from '@material-ui/icons';
import Button from '@material-ui/core/Button/Button';
import TextField from '@material-ui/core/TextField/TextField';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Switch from '@material-ui/core/Switch/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '100%',
  },
  dense: {
    marginTop: 20,
  },
  menu: {
    width: '100%',
  },
  formControl: {
    margin: theme.spacing.unit,
    width: '100%',
  },
  button: {
    margin: theme.spacing.unit,
  },
  titleWrapper: {
    width: '100%',
    margin: '5px 0',
    padding: '5px 0',
    border: '1px solid',
  },
  menuWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    listStyleType: 'none',
    margin: '15px auto',
    width: '50%',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 10,
  },
});

class CreateContacts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      department: '',
      parent_id: '',
      priority: 1,
      is_active: true,

      openDepartment: false,
      openParentDepartment: false,

      error: false,

      departments: [],
      parents: [],
    };
  }

  componentDidMount() {
    this.getSystemContacts();
  }

  getSystemContacts = async () => {
    const { id, type, parent_id } = this.props;
    const systemContacts = await fetchOne({ url: DEPARTMENTS_PATH });
    const parents = await fetchOne({ url: `${CORE_URL}/system-contacts` });

    if (type === EDIT_ITEM) {
      const { department, priority, parent, is_active } = await fetchOne({
        url: `${CORE_URL}/system-contacts/${id}`,
      });

      this.setState({
        department: department.id,
        priority,
        parent_id: parent ? parent.id : '',
        is_active,
        departments: systemContacts,
        parents: parents,
      });
    } else if (parent_id) {
      const parent = await fetchOne({
        url: `${CORE_URL}/system-contacts/${parent_id}`,
      });

      this.setState({
        parent_id: parent ? parent.id : '',
        departments: systemContacts,
        parents: parents,
      });
    } else {
      this.setState({
        departments: systemContacts,
        parents: parents,
      });
    }
  };

  submitContact = () => {
    const { department, priority, parent_id, is_active } = this.state;
    const inputs = [department, priority, parent_id, is_active];

    this.createContact();

    this.setState({ error: inputs.some(input => !input) });
  };

  createContact = async () => {
    try {
      const isEdit = this.props.type === EDIT_ITEM;
      let method = isEdit ? 'PUT' : 'POST';
      let URL = isEdit
        ? `${CORE_URL}/system-contacts/${this.props.id}`
        : `${CORE_URL}/system-contacts`;

      let pageBody = {
          department: this.state.department,
          priority: +this.state.priority,
          is_active: this.state.is_active
      };

      if (this.state.parent_id !== '' || isEdit) {
        pageBody = JSON.stringify({
            ...pageBody,
            parent_id: this.state.parent_id,
        });
      } else {
        pageBody = JSON.stringify({...pageBody});
      }

      const createContact = await fetchOne({
        url: URL,
        method,
        body: pageBody,
      });

      if (createContact.status === 201 || createContact.status === 204) {
        this.props.update();
      } else {
        this.setState({ failed: true });
      }
    } catch (err) {
      this.setState({ failed: true });
    }
  };

  handleChange = name => event => {
    const { target } = event;
    this.setState({
      [name]: target.value,
    });
  };

  handleOpen = name => this.setState({ [name]: true });

  handleClose = () => {
    this.setState({
      openDepartment: false,
      openParentDepartment: false,
    });
  };

  handleCheck = name => event =>
    this.setState({ [name]: event.target.checked });

  render() {
    const { classes } = this.props;
    const {
      error,
      department,
      priority,
      parent,
      is_active,
      openDepartment,
      openParentDepartment,
      departments,
      parents,
    } = this.state;

    return (
      <div
        className="menu-create_wrapper"
        style={{
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <div
          className="submit-button_wrapper"
          style={{
            width: '50%',
            margin: 'auto',
          }}
        >
          <Button
            onClick={() => this.submitContact()}
            variant="contained"
            color="primary"
          >
            <Save style={{ color: '#fff' }} />
          </Button>
        </div>
        <div
          className="form-wrapper"
          style={{
            paddingRight: 0,
            width: '50%',
            margin: 'auto',
          }}
        >
          <Paper style={{ padding: 10 }}>
            <Typography variant="subheading" align="left">
              Властивості контакту:
            </Typography>
          </Paper>
          <form className={classes.container} noValidate autoComplete="off">
            <FormControlLabel
              style={{ paddingTop: 10 }}
              control={
                <Switch
                  checked={is_active}
                  onChange={this.handleCheck('is_active')}
                  color="primary"
                />
              }
              label={is_active ? 'Активний' : 'Неактивний'}
            />
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="department" error={error && !department}>
                Системний контакт
              </InputLabel>
              <Select
                open={openDepartment}
                onClose={this.handleClose}
                onOpen={() => this.handleOpen('openDepartment')}
                value={this.state.department}
                onChange={this.handleChange('department')}
                inputProps={{
                  name: 'department',
                  id: 'department',
                }}
              >
                {departments.map((item, index) => (
                  <MenuItem
                    key={`department-${index}`}
                    value={item.id}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      height: 'auto',
                    }}
                  >
                    <p style={{ paddingLeft: 10 }}>
                      {item.department_localizations[0].title}
                    </p>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              id="standard-priority"
              label="Пріоритет"
              error={error && !priority}
              className={classes.textField}
              value={this.state.priority}
              onChange={this.handleChange('priority')}
              margin="normal"
            />
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="parent_id">Головний системний контакт</InputLabel>
              <Select
                open={openParentDepartment}
                onClose={this.handleClose}
                onOpen={() => this.handleOpen('openParentDepartment')}
                value={this.state.parent_id}
                onChange={this.handleChange('parent_id')}
                inputProps={{
                  name: 'parent_id',
                  id: 'parent',
                }}
              >
                <MenuItem value="">
                  <p>-</p>
                </MenuItem>
                {parents.map((item, index) => (
                  <MenuItem
                    key={`parent_id-${index}`}
                    value={item.id}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      height: 'auto',
                    }}
                  >
                    <p style={{ paddingLeft: 10 }}>
                      {item.department.department_localizations[0].title}
                    </p>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CreateContacts);
